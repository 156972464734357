/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Button from "../../Components/Dbutton";
import DModel from "../../Components/Dmodel";
import TextInput from "../../Components/Dinput";
import { toast } from "react-toastify";
import { truncateURL } from "../../utils/common";
import { useSelector } from "react-redux";
// const CopyIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/copy-icon.svg`;

const ReferralLinkModal = ({ isActive, onClose, isLinkGenerated }) => {
  const [referralLink, setReferralLink] = useState("");
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setReferralLink(
      `${process.env.REACT_APP_SIGNUP_URL}?ref=${user?.playFabId}&username=${user.userName}`
    );
  }, [isActive, user]);

  const copyToClipboard = () => {
    if (referralLink) {
      navigator.clipboard
        .writeText(referralLink)
        .then(() => {
          // toast.success("Link copied to clipboard!");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to copy text: ");
        });
    } else {
      toast.error("No referral link to copy!");
    }
  };

  return (
    <>
      <DModel
        onClose={() => {
          onClose();
        }}
        isActive={isActive}
        extraClasses="p-0"
      >
        <div className="d-model-text">
          <h3>Your Referral Link</h3>
          <p>A personal referral link has been generated for you </p>
          <TextInput
            type="text"
            value={truncateURL(referralLink, 45)}
            extraClasses="wallet-input"
            // rightNode={
            //   <img
            //     src={CopyIcon}
            //     alt="copy-icon"
            //     onClick={() => {
            //       copyToClipboard();
            //     }}
            //     className="input-node-icon"
            //   />
            // }
          />
          <Button
            variant="primary-contained"
            text="Copy"
            extraClasses="w-full"
            onClick={() => {
              copyToClipboard();
              isLinkGenerated(user?.playFabId);
              onClose();
            }}
          />
        </div>
      </DModel>
    </>
  );
};

export default ReferralLinkModal;
