import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Divider } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../store/slices/authSlice";
import { useDispatch } from "react-redux";

const ProfileBtn = ({ handleClick, username }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async (e) => {
    e.preventDefault();
    handleClick();
    dispatch(logout());
    navigate("/", { replace: true });
  };
  const items = [
    {
      key: "1",
      label: (
        <Link
          to="/download"
          style={{ color: "#fff", textTransform: "uppercase" }}
          onClick={handleClick}
        >
          game download
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          to="/wallet"
          style={{ color: "#fff", textTransform: "uppercase" }}
          onClick={handleClick}
        >
          wallet
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link
          to="/settings"
          style={{ color: "#fff", textTransform: "uppercase" }}
          onClick={handleClick}
        >
          settings
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link
          to="#"
          onClick={handleLogout}
          style={{ color: "#fff", textTransform: "uppercase" }}
        >
          logout
        </Link>
      ),
    },
  ];

  const contentStyle = {
    backgroundColor: "#000",
    borderRadius: 20,
    zIndex: 10000,
  };
  const menuStyle = {
    boxShadow: "none",
    background: "#191C1F",
  };

  return (
    <Dropdown
      style={{ margin: "0 10px 0 0" }}
      menu={{
        items,
      }}
      trigger={["click"]}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          {React.cloneElement(menu, {
            style: menuStyle,
            className: "dropdown-menu",
          })}
          <Divider
            style={{
              margin: 0,
            }}
          />
        </div>
      )}
    >
      <Space className="acc-btn">
        {username}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};
export default ProfileBtn;
