const logo_horizontal_dark = `${process.env.REACT_APP_S3_BUCKET}/Images/logo_horizontal_dark.svg`;
const VerticalLogoDarkVersion = `${process.env.REACT_APP_S3_BUCKET}/Images/VerticalLogo-DarkVersion.svg`;
const Horizontallightversion = `${process.env.REACT_APP_S3_BUCKET}/Images/Horizontallightversion.svg`;
const VerticalLogoLightVersion = `${process.env.REACT_APP_S3_BUCKET}/Images/VerticalLogo-LightVersion.svg`;
const iconlogoDark = `${process.env.REACT_APP_S3_BUCKET}/Images/iconlogo-Dark.svg`;
const iconlogoLight = `${process.env.REACT_APP_S3_BUCKET}/Images/iconlogo-Light.svg`;
const DarkversionHorizontal = `${process.env.REACT_APP_S3_BUCKET}/Images/DarkversionHorizontal.zip`;
const Horizontallightv = `${process.env.REACT_APP_S3_BUCKET}/Images/Horizontallightversion.zip`;
const VerticalDarkVersion = `${process.env.REACT_APP_S3_BUCKET}/Images/VerticalDarkVersion.zip`;
const VerticalLightVersion = `${process.env.REACT_APP_S3_BUCKET}/Images/VerticalLightVersion.zip`;
const IconDarkVersion = `${process.env.REACT_APP_S3_BUCKET}/Images/IconDarkVersion.zip`;
const IconLightVersion = `${process.env.REACT_APP_S3_BUCKET}/Images/IconLightVersion.zip`;

export const logoList = [
  {
    title: "Horizontal Logo - Dark Version",
    image: logo_horizontal_dark,
    file: DarkversionHorizontal,
  },
  {
    title: "Horizontal Logo - Light Version",
    image: Horizontallightversion,
    file: Horizontallightv,
  },
  {
    title: "Vertical Logo - Dark Version",
    image: VerticalLogoDarkVersion,
    file: VerticalDarkVersion,
  },
  {
    title: "Vertical Logo - Light Version",
    image: VerticalLogoLightVersion,
    file: VerticalLightVersion,
  },
  {
    title: "Icon Logo - Dark Version",
    image: iconlogoDark,
    file: IconDarkVersion,
  },
  {
    title: "Icon Logo - Light Version",
    image: iconlogoLight,
    file: IconLightVersion,
  },
];
