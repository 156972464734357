const TaurosiusblogImg = `${process.env.REACT_APP_S3_BUCKET}/Images/tauronius-content-img.webp`;
const LuminablogImg = `${process.env.REACT_APP_S3_BUCKET}/Images/lumina-content-img.webp`;
const KaliblogImg = `${process.env.REACT_APP_S3_BUCKET}/Images/kali-content-img.webp`;
const SeraphinablogImg = `${process.env.REACT_APP_S3_BUCKET}/Images/seraphina-content-img.webp`;
const FlorablogImg = `${process.env.REACT_APP_S3_BUCKET}/Images/flora-conent-img.webp`;
const FelsightblogImg = `${process.env.REACT_APP_S3_BUCKET}/Images/felsight-content-img.webp`;
const RevosblogImg = `${process.env.REACT_APP_S3_BUCKET}/Images/ravos-content-img.webp`;
const FernoblogImg1 = `${process.env.REACT_APP_S3_BUCKET}/Images/ferno-content-img1.webp`;
const FernoblogImg2 = `${process.env.REACT_APP_S3_BUCKET}/Images/ferno-content-img2.webp`;
const SilverfangblogImg1 = `${process.env.REACT_APP_S3_BUCKET}/Images/silverfang-content-img1.webp`;
const SilverfangblogImg2 = `${process.env.REACT_APP_S3_BUCKET}/Images/silverfang-content-img2.webp`;
const ZephyrionblogImg1 = `${process.env.REACT_APP_S3_BUCKET}/Images/Zephyrion-content-img1.webp`;
const ZephyrionblogImg2 = `${process.env.REACT_APP_S3_BUCKET}/Images/Zephyrion-content-img2.webp`;
const MarketplaceblogImg = `${process.env.REACT_APP_S3_BUCKET}/Images/Marketplace-content-img.webp`;
const NobleblogImg = `${process.env.REACT_APP_S3_BUCKET}/Images/Noble-content-img.webp`;
const DrysiablogImg = `${process.env.REACT_APP_S3_BUCKET}/Images/Drysia-content-img.webp`;
const MechanicsblogImg = `${process.env.REACT_APP_S3_BUCKET}/Images/Mechanics-content-img.webp`;
const FernixblogImg = `${process.env.REACT_APP_S3_BUCKET}/Images/Fernix-content-img.webp`;
const LaunchingImg = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/blog-first-img.png`;
const LaunchingImgTwo = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/blog-second-img.png`;
const BetaPlayersOne = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/BetaPlayersOne.jpeg`;
const BetaPlayersTwo = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/BetaPlayersTwo.jpeg`;
const BetaPlayersThree = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/BetaPlayersThree.svg  `;
const hamstarIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/twitterx.svg`;
const hamstarImage = `${process.env.REACT_APP_S3_BUCKET}/Images/harambe.jpg`;
const HeroV1 = `${process.env.REACT_APP_S3_BUCKET}/Images/heroesv1.png`;
const HeroV1Two = `${process.env.REACT_APP_S3_BUCKET}/Images/herov1two.png`;

export const HeroV1Content = `
<div>
    <p>We’re excited to bring you the latest updates on Guild of Heroes as we continue making strides toward our public beta release. Our development team has been hard at work, and we want to share some of the progress we've made in this update.</p>
    <div class="post-img">
        <img src=${HeroV1} alt="blog-img" />
    </div>
    <h2>Core Game Development Highlights</h2>
    <ul>
        <li><strong>Hero Base System:</strong> We've completed work on the core system that will power the heroes you play in Guild of Heroes, laying a strong foundation for gameplay mechanics.</li>
        <li><strong>Ability System:</strong> All core abilities for our initial heroes have been successfully implemented. Players will be able to experience unique abilities and combinations that bring strategic depth to every battle.</li>
        <li><strong>Basic Map Layout:</strong> The layout for our first competitive map is finalized. It's designed to encourage strategic movement, team play, and rewarding those who master the terrain.</li>
        <li><strong>Refactoring Movement and Collision System:</strong> We’ve refined the movement and collision system to improve game performance and create smoother, more responsive gameplay.</li>
        <li><strong>New Heroes:</strong> We’ve introduced new heroes, each with their own strengths, weaknesses, and abilities that will make gameplay diverse and exciting.</li>
        <li><strong>Hero Leveling System:</strong> Our max level is currently set at 18, and we’ve polished the leveling system to provide a balanced yet rewarding progression curve.</li>
        <li><strong>Experience Gain Mechanics:</strong> Gaining experience and leveling up will be a crucial part of every match. We’ve made several improvements to ensure that these mechanics are rewarding and intuitive.</li>
        <li><strong>Combat System Refinement:</strong> Combat mechanics have been refactored for more dynamic, fast-paced battles, ensuring a high-skill ceiling and tactical variety.</li>
    </ul>
    <h2>Items and Objectives Implementation</h2>
    <p>The upcoming features of Guild of Heroes focus on enhancing the overall gameplay experience with strategic elements. Here's a brief overview of the forthcoming developments:</p>

    <ul>
        <li><strong>Summoner Spells:</strong> These will add a strategic dimension to the game, allowing players to use powerful abilities to aid their heroes during matches.</li>
        <li><strong>Item Database:</strong> A collection of over 300+ items is being curated to allow players to strengthen their heroes based on the team’s strategy and match dynamics.</li>
        <li><strong>Shop Interface:</strong> Soon, players will have access to an in-game shop to purchase items that improve their heroes during matches.</li>
        <li><strong>Turrets and Inhibitors:</strong> These key map objectives will play a vital role in dictating the pace of the game, creating focal points for strategic battles.</li>
        <li><strong>Minion AI and Wave Management:</strong> Minion systems are being refined to maintain fairness and balanced gameplay, ensuring that the flow of minions impacts strategy effectively.</li>
    </ul>

    <div class="post-img">
        <img src=${HeroV1Two} alt="blog-img" />
    </div>
    <ul>
        <li><strong>Gold and Experience Distribution:</strong> Systems are being put in place to ensure players are rewarded for skilled gameplay through gold and XP based on their actions, including hero and minion kills, as well as structural damage.</li>
    </ul>
    <p>This version, <strong>v1.31</strong>, is shaping up to be an exciting step toward our public beta launch. While Guild of Heroes is still in closed beta for our partners, we’re working hard to deliver an immersive experience for all players when the game opens to the public in the coming weeks. Keep your eyes on this space for more updates as we get closer to the release!</p>
    </div>`;

export const LaunchingSoonContent = `
<div>
    <p>Exciting news for all Guild of Heroes fans! In just a few weeks, we are thrilled to announce the launch of our highly anticipated Tap to Earn game. This innovative addition will allow players to earn GOH coins by performing various tasks directly within our Telegram mini-app.</p>
    <div class="post-img">
        <img src=${LaunchingImg} alt="blog-img" />
    </div>
    <p>To maximize your earnings, you’ll need to be active on a daily basis. For example, by logging in every day, you can claim daily rewards and complete an array of new tasks. The more tasks you complete, the more GOH coins you will accumulate, pushing you higher up the game’s leaderboards.</p>
    <p>But that’s not all! The GOH coins you earn can later be redeemed for GOH tokens, which we plan to launch later this year. This means the more effort you put into the game, the greater your rewards will be, making your journey in Guild of Heroes even more rewarding.</p>

    <h2>Tips to Maximize Your Earnings:</h2>
    <ul>
        <li><strong>Invite Friends:</strong> Gain 10% of the earnings from your direct referrals and an additional 8% from their referrals.</li>
        <li><strong>Complete Daily Tasks:</strong> Log in every day to claim daily rewards and complete tasks to increase your coin earnings.</li>
        <li><strong>Stay Active:</strong> The more you play, the more tasks you complete, and the more rewards you can earn.</li>
        <li><strong>Climb the Ladder:</strong> Consistently complete tasks to move up the leaderboards and stand out in the community.</li>
        <li><strong>Redeem Early:</strong> Be sure to redeem your GOH coins for tokens as soon as they become available to maximize your potential gains.</li>
    </ul>
       <div class="post-img">
        <img src=${LaunchingImgTwo} alt="blog-img" />
    </div>
    <p>Stay tuned as we roll out more details in the coming weeks. Get ready to dive into the Tap to Earn game, and start climbing the ranks to become a true Guild of Heroes champion!</p>
    </div>`;

export const BetaPlayersContent = `
<div>
    <p>We’re excited to bring you <b>Patch v1.21</b> for <b>Guild of Heroes,</b> packed with new content and improvements as we prepare for the next phase of our game’s development. This patch is available exclusively for our closed beta players, with a public beta launch just around the corner!</p>
    <div class="post-img">
        <img src=${BetaPlayersOne} alt="blog-img" />
    </div>
    <h2>New Champions</h2>
    <p>We’ve expanded our roster of champions! Alongside the 13 existing heroes, we’re introducing <b>17 new Heroes</b> for you to master. While their special attacks will remain locked for now, you’ll be able to get a feel for these powerful new additions ahead of the full release on September 6th.</p>
  <div class="post-img">
        <img src=${BetaPlayersTwo} alt="blog-img" />
    </div>   
    <h2>Game Stats Update</h2>
    <p>In <b>Patch v1.21</b>, we’ve enhanced the in-game experience with real-time <b>Game Stats</b> tracking, including metrics like <b>player kills, NPC kills, victories, losses, and tower kills.</b> These stats will sync seamlessly with your <b>Guild of Heroes</b> dashboard, allowing you to see your progress in real-time. Whether you’re on your computer, phone, or using the Telegram mini-app, your stats are always up to date, showing how much you’ve earned based on your in-game performance.</p>
    <div class="post-img">
    <img src=${BetaPlayersThree} alt="blog-img" />
    </div>  
    <h2>In-Game Match Summary & Chat</h2>
    <p>This patch also introduces an <b>in-game match summary</b> feature, offering detailed post-match stats for each player. Additionally, our new <b>in-game chat</b> feature enables better communication and teamwork, ensuring you can strategize and socialize with your fellow heroes.</p>
    <h2>What’s Next?</h2>
    <p>Patch v1.21 marks a significant step forward as we move closer to our public beta launch. Our closed beta players will have the opportunity to explore these new features and provide valuable feedback before we roll them out to a wider audience. Remember, the new heroes will be fully playable with their special attacks starting from <b>September 6th</b>.</p>
    <p>Thank you for your continued support, and stay tuned for more exciting updates as we approach the public beta launch!</p>
    </div>`;

export const HamstarContent = `
<div>
      <div class="post-img">
        <img src=${hamstarImage} alt="blog-img" />
    </div>
    <p>We’re excited to announce a major collaboration with Harambe, the #1 ranked worldwide Wukong player in League of Legends. With a massive audience of dedicated fans and a proven track record of exceptional gameplay, Harambe brings unparalleled skill and influence to the Guild of Heroes community.</p>
 
    <h2>Expanding Guild of Heroes’ Reach</h2>
    <p>Harambe’s involvement will greatly boost Guild of Heroes' visibility, thanks to his extensive streaming audience and loyal fanbase. His streams will showcase the unique features and strategies of Guild of Heroes, introducing the game to a wider audience just in time for our public beta launch.</p>

    <h2>What This Partnership Means</h2>
    <p>Exclusive Streams: Harambe will be showcasing Guild of Heroes, diving deep into the gameplay and mechanics that make it stand out in the MOBA genre.</p>
    <p>Content Creation: Expect to see exciting tutorials, gameplay highlights, and in-depth strategy guides created by Harambe.</p>
    <p>Growing the Guild: Harambe’s massive audience will play a key role in expanding the Guild of Heroes player base, creating an even more vibrant and competitive community.</p>
    <p>This is a key moment for Guild of Heroes as we prepare to launch publicly, and we’re thrilled to have Harambe on board to help bring our game to the masses. Stay tuned for more exciting updates!</p>
    <p class="flex-p">Follow us for more news: <a href="http://x.com/GuildOfHeroes_" traget="_blank">
        <img src=${hamstarIcon} alt="blog-img" />
    </a></p>
 
    <p>More to come soon!</p>
    </div>`;

export const TauroniusContent = `
<div>
    <h1>Taurosius: The Horned Vanguard</h1>
    <p>In the wild expanses of Arcanterra where nature’s call is law, Taurosius, with his twin enchanted axes, reigns supreme. His presence is a force of nature, a testament to the untamed spirit that thrives within the heart of the wilderness. Taurosius wields the power of the earth and wind, a vanguard whose every move resonates with Satyric Harmony.</p>
    <div class="post-img">
        <img src=${TaurosiusblogImg} alt="blog-img" />
    </div>
    <h2>Abilities at a Glance:</h2>
    <ul>
        <li><strong>Satyric Harmony:</strong> With a bellow that vibrates through the underbrush and trees, Taurosius summons the harmonious energies of the wild, bolstering his allies with strengthening buffs and confounding his enemies with the cacophony of the natural world.</li>
        <li><strong>Hoofed Agility:</strong> His movements are as swift as the coursing river, his hooves carrying him across the battlefield with an agility that belies his formidable stature, dodging blows with strategic finesse.</li>
        <li><strong>Nature’s Resonance:</strong> The aura that surrounds Taurosius is one of vitality and vigor; it enhances the life force of those who fight alongside him and saps the strength of those who stand against.</li>
        <li><strong>Axe Cyclone:</strong> He spins into battle with the wrath of a tempest, his Axe Cyclone a maelstrom of enchanted strikes that leave his foes disoriented amidst the whirlwind of sharp edges and primal fury.</li>
        <li><strong>Melodic Gale (Ultimate Ability):</strong> Summoning the ancient songs of the winds, Taurosius calls forth the Melodic Gale, a powerful gust that weaves a destructive path through the enemy lines, their advance slowed as the winds howl with the melodies of old.</li>
    </ul>
    
    <h2>The Beast of the Battleground</h2>
    <p>To stand with Taurosius is to embrace the call of the wild, to fight with the strength of the forests and the resilience of the mountain stone. He is not just a warrior; he is the embodiment of nature’s untamed power, the spirit of the beast and the whisper of the leaves.</p>
    
    <p><strong>Charge into battle with Taurosius, the Horned Vanguard, and let the ancient rhythms of nature guide your hand and heart.</strong></p>
</div>`;

export const LuminaContent = `
<div>
    <h1>Lumina: The Celestial Marksman</h1>
    <p>In the quiet before dawn and the hush of dusk, Lumina, the Luminous Archer, takes her stance. She is the embodiment of the sky’s dual nature, where the sun’s clarity meets the moon’s mystery. With a bow that sings the song of the heavens, Lumina strikes with a grace that is as radiant as it is deadly.</p>
     <div class="post-img">
        <img src=${LuminablogImg} alt="blog-img" />
     </div> 
    <h2>Abilities at a Glance:</h2>
    <ul>
        <li><strong>Solar Precision:</strong> Lumina’s aim is guided by the unwavering light of the sun, each arrow loosed with a brilliance that ensures unerring impact upon her chosen targets.</li>
        <li><strong>Lunar Evasion:</strong> As elusive as the shifting phases of the moon, Lumina dances across the battlefield with a poise that makes her presence as intangible as moonbeams, a specter of light in the dance of combat.</li>
        <li><strong>Aurora’s Blessing:</strong> Her quiver is blessed by the aurora’s ethereal glow, granting her arrows the ability to rain down a volley of celestial light upon her adversaries, illuminating the battlefield with their resplendent glow.</li>
        <li><strong>Starfall Cascade (Ultimate Ability):</strong> When the stars align, Lumina calls upon the Starfall Cascade, a torrent of cosmic energy that streams down like a meteor shower, each arrow a falling star that pierces through the hearts of her enemies with the cold precision of the night sky.</li>
    </ul>
    
    <h2>Bearer of Day and Night</h2>
    <p>To choose Lumina is to wield the duality of day and night, to embrace the balance of the cosmos with every notch of an arrow. She is a guardian of balance, her presence a comforting constant like the celestial bodies that guide travelers and dreamers alike. With Lumina, the archer of the heavens, the battlefield becomes a canvas for her astral artistry.</p>
    
    <p><strong>Step into the light of Lumina, and let the celestial tides flow through your arrows as you claim victory in the name of stars and sky.</strong></p>
</div>`;

export const KalistaContent = `
<div>
    <h1>Kalista: The Sanguimancer Sovereign</h1>
    <p>In the hidden chambers of Arcanterra’s forgotten crypts, Kalista, the Enthroned Sanguimancer, reigns with an aura of dread and dominion. She is the arbiter of life and death, weaving the essence of vitality into a tapestry of control and command. Her mastery over the sanguine arts is unrivaled, as she bends the will of friends and foes alike with a mere gesture of her enchanted wand.</p>
     <div class="post-img">
        <img src=${KaliblogImg} alt="blog-img" />
     </div>
    <h2>Abilities at a Glance:</h2>
    <ul>
        <li><strong>Hemomancy Control:</strong> Kalista exerts her will upon the battlefield by manipulating the very lifeblood of her enemies, strengthening her allies while sapping the strength of her opponents.</li>
        <li><strong>Nocturnal Veil:</strong> With a whisper of ancient incantations, she cloaks herself in shadows, becoming as elusive as the night, her form but a wraith in the midst of combat.</li>
        <li><strong>Life Drain Strike:</strong> Her wand is not just a tool of magic but a conduit for life force extraction. With each touch, she leeches vitality from her enemies, leaving them as husks devoid of vigor.</li>
        <li><strong>Majesty of the Night (Ultimate Ability):</strong> When the moment is ripe, Kalista unveils her true power with the Majesty of the Night, a devastating display of her sanguimancy that overwhelms the battlefield, chaining her enemies in a paralyzing grip of fear and awe.</li>
    </ul>
    
    <h2>The Vampiric Regent</h2>
    <p>To ally with Kalista is to know the cold embrace of eternity. Her presence on the battlefield is both a boon and a bane — her regal composure belies a merciless nature that strikes terror into the hearts of those who oppose her. As a master of the dark arts and the sovereign of the bloodline, Kalista offers her followers the promise of immortality and the threat of oblivion.</p>
    
    <p><strong>Embrace the dark sovereignty of Kalista and let the ancient rites of hemomancy guide your path to dominion in the world of “Guild of Heroes.”</strong></p>
</div>`;

export const SeraphinaContent = `
<div>
    <h1>Seraphina: The Shadow’s Sting</h1>
    <p>In the quietest whispers of twilight, Seraphina, the Serpent Assassin of “Guild of Heroes,” emerges. She is the unseen danger, the silent blade in the dark, whose very presence is as enigmatic as it is lethal. With movements that mirror the serpent’s sinuous grace, she strikes with a cold precision known only to the shadows.</p>
    <div class="post-img">
        <img src=${SeraphinablogImg} alt="blog-img" />
    </div>
    <h2>Abilities at a Glance:</h2>
    <ul>
        <li><strong>Venomous Strike:</strong> Seraphina’s attacks carry the dual threat of blade and venom, each hit weakening her victims with a concoction of pain and poison.</li>
        <li><strong>Shadow Cloak:</strong> She weaves through combat with the subtlety of night itself, her Shadow Cloak rendering her nearly invisible and her steps ghostly whispers against the stone.</li>
        <li><strong>Constricting Coil:</strong> Like a serpent coiling its prey, Seraphina entraps her targets, her speed a blur as she delivers immobilizing strikes that continue to afflict her enemies over time.</li>
        <li><strong>Viper’s Bite:</strong> With the precision of a viper, she delivers rapid strikes that leave her adversaries reeling from venomous wounds.</li>
        <li><strong>Serpentine Slash (Ultimate Ability):</strong> Her deadliest technique, the Serpentine Slash, is a flurry of cuts so swift, so precise, that foes are left with bleeding wounds that drain their life force well after the blade has passed.</li>
    </ul>
    
    <h2>Mistress of Poisons and Shadows</h2>
    <p>To choose Seraphina is to embrace the path of the silent assassin, where the line between ally and adversary is as thin as the edge of a dagger. Her domain is the darkness, and her gift is the deadly dance of an assassin’s grace. In her hands, the battlefield becomes a place where the balance of life and death is held on the tip of a blade.</p>
    
    <p><strong>Become one with the shadows and let the venomous might of Seraphina guide you to silent, swift victory.</strong></p>
</div>`;

export const FloraContent = `
<div>
    <h1>Flora: The Sylvan Enchantress</h1>
    <p>In every whisper of the wind and rustle of leaves, Flora, the enchanting dryad of “Guild of Heroes,” moves with the grace of the living forest. She is the guardian of growth, the weaver of woodlands, channeling the ancient power of nature to both nurture and neutralize.</p>
    <div class="post-img">
        <img src=${FlorablogImg} alt="blog-img" />
    </div>
    
    <h2>Abilities at a Glance:</h2>
    <ul>
        <li><strong>Nature’s Embrace:</strong> With a gentle touch, Flora calls upon the healing energies of the earth, mending the wounds of her allies with the soothing balm of Verdant Whispers.</li>
        <li><strong>Leafstorm Barrage:</strong> Summoning a whirlwind of razor-sharp leaves, Flora sends them dancing through the ranks of her foes, disorienting and slicing with every turn.</li>
        <li><strong>Sylvan Bolt:</strong> Precision is the hallmark of the wilds, and Flora’s Sylvan Bolt is no exception — each shot is a dart of natural retribution, finding its mark with lethal accuracy.</li>
        <li><strong>Ethereal Roots Swipe (Ultimate Ability):</strong> At the climax of the battle, Flora invokes the Ethereal Roots Swipe, unleashing the full might of the forest. Mighty roots erupt from the ground, ensnaring and crushing those who threaten the balance she fiercely protects.</li>
    </ul>
    
    <h2>Guardian of the Glade</h2>
    <p>Flora’s presence on the battlefield is as serene as it is commanding, a testament to the enduring strength of nature. Her connection to the living world around her makes her a formidable ally and a mysterious foe. Those who choose Flora will harness the very essence of the wild, blending magic and might to ensure that peace and prosperity reign supreme in Arcanterra.</p>
    
    <p><strong>Embrace the natural order with Flora, and let the symphony of the forest guide you to victory.</strong></p>
</div>`;

export const FelsightContent = `
<div>
    <h1>Felsight: The Mountain’s Gaze</h1>
    <p>From the secluded peaks where ancient echoes resonate, Felsight the Unblinking takes his solemn stand in “Guild of Heroes.” This solitary cyclops, hewn from the very heart of the ancient mountains, is a sentinel of stone and strength. With an eye that pierces through chaos and a focus sharp as the edge of dawn, he surveys the battlefield with an anticipation that belies his imposing form.</p>
    <div class="post-img">
        <img src=${FelsightblogImg} alt="blog-img" />
    </div>
    
    <h2>Abilities at a Glance:</h2>
    <ul>
        <li><strong>Monolithic Gaze:</strong> Felsight’s singular eye is more than a window to his soul — it’s a lens of anticipation, predicting and countering the movements of those who dare challenge his watch.</li>
        <li><strong>Hammer of the Ancients:</strong> In his grip, the colossal war hammer is not a tool but an extension of his will. Each swing is a testament to his might, sending bone-crushing shockwaves across the battlefield that fracture both earth and armor.</li>
        <li><strong>Stonewarden Endurance:</strong> Born of the mountain’s indomitable spirit, Felsight’s cyclopean lineage affords him a resilience as enduring as the peaks from whence he came. He stands unyielding, a stoic force amidst the fray.</li>
        <li><strong>Echoes of the Summit (Ultimate Ability):</strong> When the call of battle reaches its zenith, Felsight unleashes the Echoes of the Summit, channeling the ancient power of his homeland to cause a cataclysmic rupture, reshaping the battleground with seismic fury.</li>
    </ul>
    
    <h2>The Watcher of Peaks and Valleys</h2>
    <p>Felsight is more than a hero; he is a legend carved from the legacy of the ancients. Players who choose to walk the path of Felsight will command the relentless power of the mountains, embodying the unbreakable nature of the earth itself. His presence on the battlefield is a saga of the mountain — unmoved, untamed, and unbroken.</p>
    
    <p><strong>Embrace the might of Felsight and become the unblinking force that stands eternal over the lands of Arcanterra.</strong></p>
</div>`;

export const RavosContent = `
<div>
    <h1>Ravos: The Ethereal Warrior</h1>
    <p>From the shadowed realms of Arcanterra, emerges Ravos, a warrior bound to the spirits of the air. With wings as dark as the raven and eyes glowing with the foresight of the crow, he stands as a sentinel between the worlds. Ravos wields his spectral axe with otherworldly precision, his strikes a blend of the ethereal and the lethal, as he dominates the skies and battlefields alike.</p>
    <div class="post-img">
        <img src=${RevosblogImg} alt="blog-img" />
    </div> 

    <h2>Abilities at a Glance:</h2>
    <ul>
        <li><strong>Spectral Strike:</strong> Ravos axe is an extension of his spirit, each swing releasing arcs of ghostly energy that cleave through armor and morale alike.</li>
        <li><strong>Wingstorm Descent:</strong> In a majestic dive, Ravos descends upon his foes with the full might of his wings, his axe unleashing shockwaves that ripple across the battlefield upon impact.</li>
        <li><strong>Unleashing a haunting barrage of captured souls, Ravos attacks do more than just harm; they sap the will of his enemies, leaving them weakened and vulnerable to follow-up assaults.</li>
        <li><strong>Veil of the Phantom (Ultimate Ability):</strong> Calling upon the veil between realms, Ravos wraps himself in an ephemeral cloak that bends light and perception, granting him near-invisibility and untouchable agility for fleeting moments</li>
    </ul>
    
    <h2>Master of Skies and Souls</h2>
    <p>Ravos is not merely a combatant but a force that commands the attention of allies and adversaries alike. His presence on the battlefield is a dance of devastation and grace, a testament to his dual nature as both guardian and executioner. Those who align with Ravos will command the ethereal powers of the spirit realm, turning the tides of combat with every swoop and strike.</p>
    
    <p>Harness the spectral might of Ravos and let the skies of Arcanterra echo with the victories of your conquest.</p>
</div>`;

export const FernoContent = `
<div>
    <h1>Elemental Might Unleashed</h1>
    <p>From the molten depths of Guild of Heroes comes Ferno, a titanic force of nature whose fiery heart burns with the intensity of a thousand suns. This colossal elemental entity wields the raw power of fire and earth, scorching enemies with his fiery roar and leaving devastation in his wake with every thunderous step.</p>
    <div class="post-img">
        <img src=${FernoblogImg1} alt="blog-img" />
    </div>
    <h2>Abilities at a Glance:</h2>
    <ul>
        <li><strong>Fiery Roar:</strong> Ferno’s voice is a conduit for his inner inferno. His roar ignites the air around him, scorching enemies and searing their defenses, leaving a trail of lingering embers that remind foes of their fiery encounter.</li>
        <li><strong>Molten Impact:</strong> With his seismic hammer, Ferno strikes the ground with such force that he creates a molten expanse, dealing damage and repelling enemies with the ferocity of a volcanic eruption.</li>
        <li><strong>Elemental Resilience:</strong> As an embodiment of fire and stone, Ferno boasts a natural resistance to fire-based attacks. His rugged exterior isn’t just for show — it renders him near impervious to the scorching assaults of his adversaries.</li>
        <li><strong>Lava Surge (Ultimate Ability):</strong> When the battle reaches a fever pitch, Ferno can call upon the Lava Surge. Unleashing the full might of his volcanic heritage, he superheats the earth itself, turning the battlefield into a deadly lava field that overwhelms foes and reshapes the terrain to his advantage.</li>
    </ul>
    <div class="post-img">
        <img src=${FernoblogImg2} alt="blog-img" />
    </div> 
    <h2>A Juggernaut of Fire and Stone</h2>
    <p>Ferno stands as a paragon of strength and endurance, a hero whose imposing figure is matched only by the might of his elemental power. Players who choose Ferno will find themselves at the helm of a force that is both indomitable and relentless. His abilities are a testament to his role as a frontline combatant, capable of sustaining considerable damage while dishing out unrelenting heat.</p>
    
    <p>In the hands of a strategic player, Ferno is not just a warrior; he is an unstoppable force of nature that can dictate the flow of combat and alter the landscape of the game. Embrace the power of Ferno and turn the tides of battle into a wildfire of victory.</p>
</div>
`;

export const SilverfangContent = `
<div>
    <h1>Predator of the Shadows</h1>
    <p>Silverfang strikes not just with his limbs but with fear itself. As a beast woven from the nightmares of his enemies, his presence on the battlefield is as chilling as it is deadly. His fearsome howl is the prelude to a symphony of destruction, as his silver-coated claws catch the moonlight before they strike.</p>
    <div class="post-img">
        <img src=${SilverfangblogImg1} alt="blog-img" />
    </div> 
    <h2>Abilities at a Glance:</h2>
    <ul>
        <li><strong>Haunting Howl:</strong> Before Silverfang is seen, he is heard. His howl seeps into the hearts of his enemies, disorienting them and leaving them prey to his impending assault.</li>
        <li><strong>Lunar Claws:</strong> With strikes as swift as they are savage, Silverfang’s claws are not merely weapons; they are extensions of the night itself, each swipe leaving nothing but scars in their wake.</li>
        <li><strong>Shroud of Night:</strong> He moves with the subtlety of dusk, his form blending into the shadows. This ability allows him to navigate the battlefield undetected, positioning himself to unleash his fury.</li>
        <li><strong>Silverstrike (Ultimate Ability):</strong> When the hunt calls for the full measure of his power, Silverfang can invoke Silverstrike. This ultimate ability coats his claws with a deadly veneer of silver energy, his strikes capable of piercing through the strongest armors and the bravest souls.</li>
    </ul>
    <div class="post-img">
        <img src=${SilverfangblogImg2} alt="blog-img" />
    </div> 
    <h2>A Force to be Reckoned With</h2>
    <p>Silverfang embodies the primal fear that lingers in the dark corners of the world. His kit rewards cunning and strategic positioning, appealing to players who relish the role of the ambush predator. On the battlefield, Silverfang is not just another combatant; he is the unseen terror, the whisper in the dark that spells doom for the unwary.</p>
</div>
`;

export const ZephyrionContent = `
<div>
    <h1>Amidst the heroes of Guild of Heroes</h1>
    <p>Zephyrion stands as a beacon of raw power and electrifying presence. His tragic past fuels his relentless pursuit for justice, channeling the very force of electricity to stun and subdue his adversaries.</p>
    <div class="post-img">
        <img src=${ZephyrionblogImg1} alt="blog-img" />
    </div>
    <h2>Abilities Unleashed:</h2>
    <ul>
        <li><strong>Electric Charge:</strong> Zephyrion harnesses the ambient static to power the spikes on his forearms, releasing charged strikes that ripple through the battlefield, stunning enemies caught in the volatile discharge.</li>
        <li><strong>Deflector Shield:</strong> In the heat of combat, Zephyrion manifests an arc of electrical energy around him, creating an electric shield that repels and deflects incoming projectiles, safeguarding him and his allies.</li>
        <li><strong>EMP Burst:</strong> With precise control over the voltage he wields, Zephyrion can emit a devastating electromagnetic pulse, disrupting enemy systems and rendering technology-based adversaries defenseless.</li>
        <li><strong>Thunderous Roar:</strong> Zephyrion’s mastery over electric currents allows him to unleash a resonating roar, a shockwave of sound and energy that overwhelms opponents, dominating close-quarters encounters.</li>
        <li><strong>Overcharge (Ultimate):</strong> When the tide of battle calls for it, Zephyrion can initiate Overcharge, amplifying his electric powers to their zenith. This surge of energy not only enhances his abilities but also imbues him with a formidable presence, making him an unstoppable force during crucial combat moments.</li>
    </ul>
    <div class="post-img">
        <img src=${ZephyrionblogImg2} alt="blog-img" />
    </div>
    <h2>Charge into battle with the storm at your back and lightning at your command.</h2>
    <p>Zephyrion is not merely a hero; he is a statement of strength for those who prefer to lead with action rather than words. His skill set is designed for those who thrive in the heart of battle, dictating its rhythm with unrelenting aggression. Playing Zephyrion is about controlling the chaos, harnessing the raw energy of the storm, and channeling it into a display of electric dominance on the battlefield.</p>
</div>
`;

export const MarketplaceContent = `
<div>
    <h1>Introduction</h1>
    <p>The in-game marketplace of Guild of Heroes offers a dynamic and enriching aspect to the gaming experience. Serving as a central feature, it provides players an avenue to augment their gameplay through a diverse range of upgrades and customization options.</p>
    <div class="post-img">
        <img src=${MarketplaceblogImg} alt="blog-img" />
    </div>
    <h2>Marketplace Dynamics</h2>
    <p>At the heart of Guild of Heroes, the marketplace emerges as a bustling epicenter, pivotal to the game’s ecosystem. Here, players encounter a wide assortment of items — from essential hero upgrades that enhance abilities to special items and distinctive cosmetic options for personalization. Each item is crafted to offer not just a competitive edge but also to inject additional layers of strategy and enjoyment into the game.</p>
    <p>The marketplace’s distinctive appeal lies in its sophisticated system, which guarantees secure and transparent transactions. This fosters a trustful environment where players feel confident in their marketplace activities, whether it involves trading or purchasing. The introduction of rare and valuable items adds a collectible aspect, making every transaction an exciting venture.</p>
    
    <h2>Player Experience</h2>
    <p>Navigating through the marketplace is an intuitive and streamlined experience, designed with all players in mind. Whether one is a veteran of Guild of Heroes or a new adventurer, the marketplace interface is straightforward, focusing on ease of use. This user-friendly approach ensures that players can quickly familiarize themselves with the system, allowing more time to enjoy the strategic benefits of their acquisitions.</p>
    <p>The marketplace caters to a variety of player preferences. Whether aiming to optimize heroes for competitive play or seeking unique skins and cosmetics to express personal style, the marketplace accommodates all. It maintains a delicate balance, offering items that provide gameplay advantages while preserving the competitive fairness of the game.</p>
    
    <h2>Seamlessly upgrade and personalize your heroes right here — your gateway to in-game enhancements and unique styles.</h2>
    
    <h2>Economic and Strategic Depth</h2>
    <p>More than just a transaction hub, the marketplace in Guild of Heroes is a vibrant economic subsystem within the game. The economy here is shaped by players’ actions, including the demand and supply of certain items, trading behaviors, and the introduction of new and unique items. This economic layer adds realism and depth to the game, encouraging players to think strategically not only in combat but in their economic decisions as well.</p>
    
    <h2>Looking Ahead</h2>
    <p>The marketplace is poised to evolve alongside Guild of Heroes. Anticipated updates promise to introduce new items and features, ensuring the marketplace remains a dynamic and engaging aspect of the game. These updates aim to enrich the overall ecosystem of the game, with the marketplace continuing as a hub for player interaction, strategic economic activity, and personal expression.</p>
    
    <p>In summary, the marketplace in Guild of Heroes is a fundamental component of the game’s universe. It offers a unique blend of strategic engagement, economic interaction, and creative expression. It’s a space where players make impactful decisions, engage with the community, and shape their journey within the vast and adventurous world of Guild of Heroes.</p>
</div>
`;

export const NobleContent = `
<div>
    <h1>Noble: The Time-Honored Sentinel</h1>
    <p>In the verdant fields and over the storied cobblestones of Arcanterra strides Noble, the weathered knight whose very name evokes tales of valor and perseverance. With the wisdom of countless battles etched into his being, Noble wields his axe not merely as a weapon, but as an extension of a lifetime dedicated to the mastery of martial prowess.</p>
    <div class="post-img">
        <img src=${NobleblogImg} alt="blog-img" />
    </div>
    <h2>Abilities at a Glance:</h2>
    <ul>
        <li><strong>Seasoned Strike:</strong> Each swing of Noble’s axe is a masterclass in the art of war, his attacks executed with a precision that belies the depth of his experience.</li>
        <li><strong>Guardian’s Vigil:</strong> Noble stands as a bulwark on the battlefield, his presence alone a bastion against the onslaught of time and enemies alike.</li>
        <li><strong>Aegis of the Ancients:</strong> Calling upon the ancient arts of knighthood, Noble conjures an ethereal shield that absorbs the strikes of foes, a testament to his enduring spirit.</li>
        <li><strong>Healing Halo (Ultimate Ability):</strong> In the heat of battle, Noble can summon the Healing Halo, a radiant aura of restoration that mends wounds and bolsters the fighting spirit of allies within its sanctified radius.</li>
    </ul>
    
    <h2>The Embodiment of Chivalry</h2>
    <p>Noble is a figure carved from the legends of old, a knight whose story is interwoven with the history of Arcanterra itself. To stand alongside Noble is to stand with a chapter of history — a chapter that speaks of honor, sacrifice, and undying dedication to the cause of right.</p>
    
    <p>Choosing Noble as your champion is to take up the mantle of legacy, to fight with the heart of a warrior who has seen empires rise and fall and yet stands unwavering. Take your place in the annals of Arcanterra with Noble, the Time-Honored Sentinel, and let your deeds echo with the weight of his timeless legacy.</p>
</div>
`;

export const DrysiaContent = `
<div>
    <h1>Drysia: The Eclipse Warlord</h1>
    <p>In the heart of the shadowed groves of Arcanterra, Drysia stands as the chieftain of whispers and dusk. Clad in the might of the night and wielding twin obsidian axes, she commands the battlefield with the authority of darkness itself. Her presence is a foreboding omen, her power an echo of the void that swallows light.</p>
    <div class="post-img">
        <img src=${DrysiablogImg} alt="blog-img" />
    </div>
    
    <h2>Abilities at a Glance:</h2>
    <ul>
        <li><strong>Umbral Fury:</strong> Drysia channels the obscure energies of darkness to enhance her strikes, each blow infused with the abyss’s depth, rending through defenses with a force that is as inevitable as the night.</li>
        <li><strong>Shadow Surge:</strong> With agility granted by the penumbral mists, she orchestrates attacks with a precision that seems almost supernatural, her movements a symphony of synchronized devastation.</li>
        <li><strong>Eclipse’s Embrace:</strong> She weaves the protective embrace of the eclipse around her tribe, fortifying their resolve and turning their will into an indomitable bulwark against all who dare oppose.</li>
        <li><strong>Dual Eclipse Cleave (Ultimate Ability):</strong> Unleashing the wrath of the concealed sun and moon, Drysia performs the Dual Eclipse Cleave, a maelstrom of steel that disrupts and disorients, as though night and day were clashing over her enemies.</li>
        <li><strong>Shadefall Assault:</strong> Leading her warriors in the Shadefall Assault, she embodies the tempest of the night, overwhelming adversaries with a synchronized barrage that leaves no room for retreat or mercy.</li>
    </ul>
    
    <h2>Matriarch of Shadows</h2>
    <p>To align with Drysia is to command the shadows, to become one with the subtleties of darkness that others fear to tread. She is not just a warrior; she is an eclipse in human form, a blend of light and shadow, strength and subtlety. In her wake, foes find only ruin, and allies find unwavering support.</p>
    
    <p>Rise with Drysia, the Eclipse Warlord, and cast the might of shadows over the fields of Arcanterra.</p>
</div>
`;

export const BurnMechanismContent = `
<div>
    <h1>Guild of Heroes: Burn Mechanism</h1>
    <p>In Guild of Heroes, the burn mechanism operates through a dual-pathed approach that ensures both the game’s economy and its players benefit from the process.</p>
    <div class="post-img">
        <img src=${MechanicsblogImg} alt="blog-img" />
    </div>
    
    <h2>Path One: Traditional Purchase</h2>
    <p>When a player opts to buy a new hero skin worth $100 using a conventional payment method, like a credit card (Visa is shown in the graphic), the transaction impacts the game’s economy in the following way:</p>
    <ul>
        <li><strong>Half of the Payment:</strong> $50 is directly transferred into the Guild of Heroes (GOH) Treasury.</li>
        <li><strong>The Other Half:</strong> The remaining $50 is used to buy back tokens from the market. This buyback serves two purposes: it provides liquidity and supports the token’s market value.</li>
    </ul>
    
    <h2>Path Two: Token-Based Purchase</h2>
    <p>Alternatively, a player can use the in-game currency, GOH tokens, to purchase the new hero skin. In this scenario:</p>
    <ul>
        <li><strong>Token Spend:</strong> The player spends 10,000 GOH tokens to acquire the skin.</li>
        <li><strong>Token Allocation:</strong> Another set of 10,000 GOH tokens, equivalent to $100, is allocated from the player’s purchase to the GOH Treasury.</li>
    </ul>
    
    <p>Both methods funnel resources back into the game’s treasury, ensuring a continuous flow of funds and tokens that can be used for further development, marketing, and player rewards. The buyback and burn mechanism also help reduce the overall token supply, which can create scarcity and potentially drive up the token’s value over time.</p>
    
    <p>This strategy showcases Guild of Heroes’ commitment to creating a sustainable and player-focused economy, where the in-game currency has real-world value and utility, and players are incentivized to engage with the game’s ecosystem.</p>
</div>
`;

export const FernixContent = `
<div>
    <h1>Fernix: The Arcane Protector</h1>
    <p>From the ancient roosts where legends take flight, Fernix emerges, his very essence a testament to the might and majesty of dragons of yore. Clad in scales that shimmer with the wisdom of aeons, he stands as a guardian, a mage whose power resonates with the heartbeats of dragons long passed.</p>
    <div class="post-img">
        <img src=${FernixblogImg} alt="blog-img" />
    </div>
    
    <h2>Abilities at a Glance:</h2>
    <ul>
        <li><strong>Fernix Ward:</strong> Fernix conjures a barrier as resilient as dragonhide, a magical shield that thwarts the onslaught of enemies and turns their aggression into futile gestures.</li>
        <li><strong>Elemental Infusion:</strong> His spells roar with the fury of dragonfire; each incantation is charged with elemental wrath, searing the air and earth with the legacy of Fernix's tempests.</li>
        <li><strong>Dragonfire Cascade:</strong> With a command that echoes through the arcane realms, he calls forth a deluge of dragonflame, raining searing destruction upon his adversaries and weakening their resolve.</li>
        <li><strong>Arcane Torrent:</strong> Fernix unleashes the focused essence of his power in a beam of pure arcane energy, piercing through the ranks of foes with unrelenting force.</li>
        <li><strong>Mystic Tempest (Ultimate Ability):</strong> The skies answer his call, as Fernix conjures a Mystic Tempest, a swirling vortex that envelops the battlefield, disorienting foes with the chaotic dance of magical gales.</li>
    </ul>
    
    <h2>The Dragon’s Heir</h2>
    <p>To walk alongside Fernix is to walk with the echo of ancient power. His command of magic is an ode to the dragons’ timeless strength, their elemental dominance, and their inscrutable wisdom. He is not merely a participant in battle but a force that shapes the very fabric of conflict.</p>
    
    <p>Embrace the legacy of the dragon with Fernix, and let the arcane winds carry your triumphs across the lands of Arcanterra.</p>
</div>
`;
