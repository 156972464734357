import React, { useState } from "react";
import "./style.css";
import { logoList } from "../../../Constants/media";
import SideMenu from "../../../Components/SideMenu";
import Tbbox from "../../../Components/TbBox";
const dimg = `${process.env.REACT_APP_S3_BUCKET}/Images/dimg.svg`;

const menuList = [
  {
    name: "logos",
    icon: dimg,
    iconLight: dimg,
  },
];

const Media = () => {
  const [activeMenu, setActiveMenu] = useState(menuList[0].name);
  return (
    <div className="media settingsmain">
      <div className="row">
        <div className="container">
          <div className="col2">
            <SideMenu
              heading="MEDIA"
              menuList={menuList}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
            />
          </div>
          <div className="col4">
            {/* TB Items are mapped here  */}
            {logoList.map((item, index) => {
              return (
                <Tbbox title={item.title} img={item.image} file={item.file} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Media;
