import React from "react";
import { useNavigate } from "react-router-dom";

function IconTabsList({ active, onClick, Data, isLastLink = false }) {
  const navigate = useNavigate();
  return (
    <ul>
      {Data?.map((item, index) => {
        return (
          <li className={`${active === index ? "active" : ""}`} key={index}>
            <a
              href={`# ${index}`}
              onClick={(e) => {
                e.preventDefault();
                if (index === Data.length - 1 && isLastLink) {
                  navigate("/heroes");
                } else {
                  onClick(index);
                }
              }}
            >
              <img src={item?.image} alt="Game" />
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export default IconTabsList;
