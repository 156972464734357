import React, { useState } from "react";
// import { BiSearch } from "react-icons/bi";
const SearchIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/searchicon.svg`;

function Search({ onSearch }) {
  const [search, setSearch] = useState("");

  return (
    <div className="search-bar">
      <img src={SearchIcon} alt="Search" />
      <input
        type="text"
        placeholder="Search"
        value={search}
        onChange={(e) => {
          const searchString = e.target.value;
          setSearch(searchString);
          onSearch(searchString);
        }}
      />
    </div>
  );
}

export default Search;
