/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { General } from "../../../Constants";
import "./style.css";
import LatestPost from "../../../Components/LatestPost";

const ArrowLeft = `${process.env.REACT_APP_S3_BUCKET}/Images/arrowLeft.svg`;

const dataList = [General];

function SinglePost() {
  const { pathname } = useLocation();
  const [post, setPost] = useState({});
  const [filteredData, setFilteredData] = useState(dataList[0]);
  const navigate = useNavigate();
  const id = pathname.split("/").pop();

  useEffect(() => {
    const findPostByTitle = General.find((post) => post.id === id);
    setPost(findPostByTitle);

    const filtered = General.filter((post) => post.id !== id);
    setFilteredData(filtered);
  }, [id]);
  return (
    <div id="mainwrapper" className="Post-single bg-news">
      <section className="single-post">
        <div className="container">
          <div className="backbtn" onClick={() => navigate(-1)}>
            <img src={ArrowLeft} alt="Back" />
          </div>
          <h3 className="tabhead singlehead">{post?.title}</h3>
          <p className="date">{post?.createdAt}</p>
          <div className="single-post-wrapper">
            <div className="post-details">
              <div className="single-post-content">
                {/* Uncomment the image if needed */}
                {/* <div className="post-img">
                  <img src={post.PostImg} alt="Single Post" />
                </div> */}
                <div
                  className="content-wrapper"
                  dangerouslySetInnerHTML={{ __html: post.content }}
                />
              </div>
            </div>
            <div className="popular-posts">
              <h4 className="popoular-post-title">Most Popular Updates:</h4>
              <LatestPost data={filteredData.slice(0, 3)} isClickable />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SinglePost;
