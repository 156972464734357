import React from "react";
import "./style.css";
const bannerlogo = `${process.env.REACT_APP_S3_BUCKET}/Images/bannerlogostoke.png`;

const windowIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/windows-icon.svg`;

const ClosedBetaDownload = () => {
  return (
    <div id="mainwrapper" className="download-page">
      <img src={bannerlogo} alt="logo" />
      <h2 className="h2head page-heading">closed beta download portal</h2>
      <p style={{ margin: 0 }}>
        Be among the first to forge your legacy. Exclusive access to the closed
        beta of the realm's greatest adventure.
      </p>
      <div className="btn-cont">
        <a
          href={"https://d2j04pqii01bv9.cloudfront.net/Guild_Of_Heroes.zip"}
          className="btn"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="windows-icon" src={windowIcon} />
          Download for Windows
        </a>
      </div>
    </div>
  );
};

export default ClosedBetaDownload;
