import React from "react";
import ScreenVideo from "../../../Components/video";
const Video = `${process.env.REACT_APP_S3_BUCKET}/videos/about-sub-video.mp4`;
const poster = `${process.env.REACT_APP_S3_BUCKET}/Images/about-video-poster.png`;

const AvoutVideo = () => {
  return (
    <ScreenVideo autoPlay={true} height="auto" scr={Video} poster={poster} />
  );
};

export default AvoutVideo;
