const metamask = `${process.env.REACT_APP_S3_BUCKET}/Images/wallet-icon.svg`;
const walletConnect = `${process.env.REACT_APP_S3_BUCKET}/Images/walletConnect-icon.svg`;
const trustWallet = `${process.env.REACT_APP_S3_BUCKET}/Images/trustWallet-icon.svg`;
const coinbaseWallet = `${process.env.REACT_APP_S3_BUCKET}/Images/coinbaseWallet-icon.svg`;
const phantom = `${process.env.REACT_APP_S3_BUCKET}/Images/phantom-icon.svg`;

export const walletList = [
  {
    id: 1,
    name: "Metamask",
    image: metamask,
  },
  {
    id: 2,
    name: "WalletConnect",
    image: walletConnect,
  },
  {
    id: 3,
    name: "Trust Wallet",
    image: trustWallet,
  },
  {
    id: 4,
    name: "Coinbase Wallet",
    image: coinbaseWallet,
  },
  {
    id: 5,
    name: "Phantom",
    image: phantom,
  },
];
