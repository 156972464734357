import React, { useEffect, useState } from "react";

const VideoFrame = ({ src, isloader }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isloader) {
      if (src) {
        setIsLoading(true);
        const videoElement = document.createElement("video");
        videoElement.src = src;
        videoElement.oncanplaythrough = () => setIsLoading(false);
        videoElement.onerror = () => setIsLoading(false);
      }
    }
  }, [src]);

  return (
    <div className="play-video">
      {isloader ? (
        isLoading ? (
          <div className="loader"></div>
        ) : (
          <video
            autoPlay
            loop
            muted
            controls={false}
            playsInline
            preload="auto"
          >
            <source src={src} type="video/mp4" />
          </video>
        )
      ) : (
        <video autoPlay loop muted controls={false} playsInline preload="auto">
          <source src={src} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default VideoFrame;
