import React, { useMemo } from "react";
import "./style.css";
import Dpagination from "../Dpagination";
import NavLink from "../Dlinks";

const DynamicTable = ({
  data,
  columns,
  pagination,
  currentPage,
  setCurrentPage,
  isBackLink,
  searchQuery,
  totalCount,
  isLoading,
  onClick,
  noData = "",
}) => {
  const filteredData = useMemo(() => {
    if (!searchQuery) return data;
    return data.filter((item) =>
      columns.some((column) =>
        item[column.accessor]
          ?.toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, data, columns]);

  const handleShowMore = () => {
    setCurrentPage((prev) => prev.pageSize + 10);
  };

  return (
    <div className="custom-table">
      <div className="table-bg">
        <table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.accessor} className="sortable">
                  {column.Header}
                </th>
              ))}
            </tr>
          </thead>
          {isLoading ? (
            <div className="load-full">
              <div className="loader"></div>
            </div>
          ) : (
            <tbody>
              {filteredData?.length > 0 ? (
                filteredData?.map((row, index) => {
                  return (
                    <tr key={index} onClick={() => onClick && onClick(row)}>
                      {columns.map((column) => (
                        <td key={column.accessor}>
                          {!column.cell && row[column.accessor]}
                          {column.cell && column.cell({ row })}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr className="no-data">
                  <td colSpan={columns?.length}>
                    {noData}
                    {isBackLink && (
                      <NavLink text="Go Back " link={isBackLink} />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      {pagination ? (
        <Dpagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={totalCount}
          backText="Back"
          nextText="Next"
        />
      ) : (
        currentPage?.pageSize < filteredData?.length && (
          <button
            onClick={handleShowMore}
            className="d-gradient-text d-nav-link load-more-btn"
          >
            Show More
          </button>
        )
      )}
    </div>
  );
};

export default DynamicTable;
