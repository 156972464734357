import React, { useEffect, useState } from "react";
import "./style.css";
import { airdropData } from "../../../Constants/list";
import DtaskCard from "../../../Components/DtaskCard";
import DairdropModel from "./airdropModel";
import DloginModal from "../../../Components/DloginModal";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/logoIconNew.svg`;

const DairDrop = () => {
  const [isDataId, setIsDataId] = useState();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isWelcomeModal, setIsWelcomeModal] = useState(false);
  const pathname = useLocation();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (pathname.search) {
      const isOpen = pathname.search.split("=")[1];
      setIsModelOpen(isOpen);
    }
  }, [pathname]);

  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <div className="d-top-left">
            <h2>Airdrop</h2>
          </div>
        </div>
        <div className="airdrop-banner">
          <div>
            <h2>participate in the GOH token airdrop</h2>
            <p>
              Join the exciting GOH token airdrop by collecting GOH COINS. Earn
              GOH COINS by engaging with our platform and completing tasks. The
              more active you are, the more GOH COINS you can collect
            </p>
          </div>
          <div>
            <img src={LogoIcon} alt="Logo-icon" />
          </div>
        </div>

        <div className="d-task-list">
          {airdropData.map((item, index) => {
            return (
              <DtaskCard
                data={item}
                key={index}
                onClick={() => {
                  setIsDataId(item.id);
                  user ? setIsModelOpen(true) : setIsWelcomeModal(true);
                }}
              />
            );
          })}
        </div>
      </div>
      <DairdropModel
        id={isDataId}
        onClose={() => {
          setIsModelOpen(false);
          setIsDataId();
        }}
        isActive={isModelOpen}
      />
      <DloginModal
        onClose={() => {
          setIsWelcomeModal(false);
        }}
        isActive={isWelcomeModal}
      />
    </>
  );
};

export default DairDrop;
