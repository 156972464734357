import React, { useEffect, useState } from "react";
import "./style.css";

const BatteryCell = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/battery-cell.svg`;

const DpointBar = ({ value, total }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const calculatedPercentage = (value / total) * 100;
    setPercentage(calculatedPercentage);
  }, [value, total]);

  return (
    <div className="d-point-bar">
      <div>
        <div className="battery">
          <>
            {percentage >= 100 && <img src={BatteryCell} alt="battery-cell" />}
            {percentage >= 50 && <img src={BatteryCell} alt="battery-cell" />}
            {percentage >= 25 && <img src={BatteryCell} alt="battery-cell" />}
          </>
        </div>
        <p>
          {value}
          <span>/{total}</span>
        </p>
      </div>
      <div className="points-progress-bar">
        <div
          className={`progress ${percentage < 100 ? "isbar-loading" : ""}`}
          data-progress={total}
          style={{
            width: `${percentage}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default DpointBar;
