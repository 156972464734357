import React from "react";

const Tbbox = ({ title, img, file }) => {
  return (
    <div className="tbbox">
      <h5>{title}</h5>
      <div className="logtad">
        <img src={img} alt="logo_horizontal_dark" />
        <a
          href={file}
          rel="noreferrer"
          download
          target="_blank"
          className="btn"
        >
          DOWNLOAD
        </a>
      </div>
    </div>
  );
};

export default Tbbox;
