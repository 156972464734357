import React from "react";
import { Link } from "react-router-dom";
const Logo = `${process.env.REACT_APP_S3_BUCKET}/Images/bannerlogostoke.png`;

const Banner = () => {
  return (
    <section className="heros-banner">
      <div className="container">
        <img src={Logo} alt="Logo" />
        <h2 className="h2head mt-4">
          BECOME THE GREATEST HERO IN GUILD OF HEROES
        </h2>
        <p className="paraStyle ">
          Play Guild of Heroes, destroy towers, defeat players and NPCs, and
          lead your team to victory.
        </p>
        <div className="mt-4">
          {/* {!user ? ( */}
          <Link to={"/download"} className="btn">
            Play Now
          </Link>
          {/* // ) : (
          //   <Link to={"/secure-your-spot"} className="btn">
          //     Secure Your Spot
          //   </Link>
          // )} */}
        </div>

        {/* <Link to="#" className="log-btn">
          JOIN THE WAITING LIST
        </Link> */}
      </div>
      <video autoPlay loop muted width="100%" playsInline>
        <source
          src={`${process.env.REACT_APP_S3_BUCKET}/videos/beta-bg-video.mp4`}
          type="video/mp4"
        />
      </video>
    </section>
  );
};

export default Banner;
