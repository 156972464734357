import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../Components/Loader";

const ProtectedRoutes = () => {
  const { user, isLoading } = useSelector((state) => state.auth);

  if (isLoading) return <Loader />;
  return user ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
