/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LatestPost from "../../../Components/LatestPost";
import { General, NewPost } from "../../../Constants/index";

function PostList() {
  const [active] = useState(0);
  // const [setIsActive] = useState(false);
  const [search] = useState("");
  const dataList = [
    General,
    NewPost,
    General,
    NewPost,
    NewPost,
    General,
    NewPost,
    NewPost,
  ];
  const [filteredData, setFilteredData] = useState(dataList[0]);

  useEffect(() => {
    if (search) {
      const filteredData = dataList[active].filter(({ title }) => {
        if (title.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
          return true;
        } else {
          return false;
        }
      });
      setFilteredData(filteredData);
    } else if (!search) {
      setFilteredData(dataList[active]);
    }
  }, [search, active]);

  // const handleClick = () => {
  //   setIsActive((current) => !current);
  // };

  return (
    <section className="post-section">
      <div>
        <div className="container">
          <div className="row cards-container">
            {filteredData.length > 0 ? (
              <LatestPost data={filteredData} isClickable />
            ) : (
              <h1
                className="h3head"
                style={{ textAlign: "center", fontFamily: "Rakkas" }}
              >
                No results found.
              </h1>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PostList;
