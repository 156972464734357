import React from "react";
import { useMemo } from "react";
import "./style.css";

const Button = ({
  text,
  variant,
  extraClasses,
  icon,
  disabled,
  isLoading,
  ...rest
}) => {
  const getVariantClasses = useMemo(() => {
    switch (variant) {
      case "primary-contained":
        return "d-bg-primary-contained";

      case "secondary-contained":
        return "d-bg-secondary-contained";
      default:
        return "";
    }
  }, [variant]);

  return (
    <button
      className={`${getVariantClasses} ${extraClasses ? extraClasses : ""} ${
        disabled ? "opacity-donw-disable" : ""
      }${isLoading ? "isloading-button" : ""} d-btn`}
      {...rest}
      disabled={disabled}
    >
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <>
          {icon && <img src={icon} alt="icon" />}
          {variant === "secondary-contained" ? (
            <div className="d-gradient-text">{text}</div>
          ) : (
            text
          )}
        </>
      )}
    </button>
  );
};

export default Button;
