import { createSlice } from "@reduxjs/toolkit";
import { dailyTaskApi } from "../../services/dailyTask";

const initialState = {
  dailyTask: [],
};

const dailyTaskSlice = createSlice({
  name: "dailyTask",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      dailyTaskApi.endpoints.getDailyTask.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        state.dailyTask = data;
      }
    );
  },
});

export default dailyTaskSlice;
