import React, { useEffect, useState } from "react";
import "./style.css";
// import "../Login/style.css";
import Loader from "../../../Components/Loader";
// import { addPlayerAirdropStep } from "../../../utils/helper";

const SecureSpot = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch user data
  const fetchUserData = () => {
    window.PlayFab.ClientApi.GetUserData({}, (res, error) => {
      if (res) {
        setUserData(res.data.Data);
      } else {
        console.log("Error fetching user data:", error);
      }
      setLoading(false);
    });
  };

  // Update user data
  const updateUserData = (data) => {
    window.PlayFab.ClientApi.UpdateUserData(data, (res, error) => {
      if (res) {
        fetchUserData();
      } else {
        console.log("Error updating data:", error);
      }
    });
  };

  const handleBtn = () => {
    var updateUserDataRequest = {
      Data: {
        spot_secured: true,
      },
    };
    updateUserData(updateUserDataRequest);
    // addPlayerAirdropStep("Spot Secured");
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div id="mainwrapper" className="secure-spot">
      {loading ? (
        <Loader />
      ) : (
        <div className="login-form">
          <div className="body">
            <h2 className="h2head">SECURE YOUR SPOT</h2>
            <h5>
              Thank you for your interest in joining the Guild of Heroes closed
              beta.
            </h5>
            <p className="para">
              You're just one step away from securing your spot among the
              heroes. Confirm your registration to ensure you don't miss out on
              the opportunity to be among the first to explore and shape the
              world of Guild of Heroes.
            </p>
            <button
              disabled={userData?.spot_secured?.Value === "true"}
              className={`btn ${
                userData?.spot_secured?.Value === "true" && "btn-disabled"
              }`}
              onClick={handleBtn}
            >
              {userData?.spot_secured?.Value === "true"
                ? "Spot Secured"
                : "confirm your spot"}
            </button>
            <p className="sub-para">
              By confirming, you agree to receive updates and important
              information about the closed beta via email. We're excited to have
              you embark on this journey with us and look forward to seeing you
              in the game!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SecureSpot;
