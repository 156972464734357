import Button from "../../../Components/Dbutton";

const StepIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/step-four.png`;

const StepsFour = ({ onNext }) => {
  return (
    <>
      <div className="d-model-icon">
        <img src={StepIcon} alt="" />
      </div>
      <div className="d-model-text">
        <h3>Highscores</h3>
        <p>
        The more tasks you complete, the more GOH Coins you earn. Track your progress and see where you stand by checking the highscores. Here, you can view your rank and the total amount of GOH Coins you've accumulated. Engage more to climb higher and earn greater rewards
        </p>
        <Button
          variant="secondary-contained"
          text="Next"
          extraClasses="full-contain"
          onClick={onNext}
        />
      </div>
    </>
  );
};

export default StepsFour;
