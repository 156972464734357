import React from "react";
import { Link } from "react-router-dom";

function LatestPost({ data, isClickable }) {
  return (
    <>
      {data.map((item, index) => {
        return (
          <>
            {isClickable ? (
              <Link to={`/news/${item.id}`} className="post-item" key={index}>
                <div className="post-img">
                  <img src={item?.PostImg} alt="Post" />
                </div>
                <div className="post-content">
                  <span className="post-dates">{item?.date}</span>
                  <h3 className="tabhead">{item?.title}</h3>
                  <p>{item?.desc}</p>
                </div>
              </Link>
            ) : (
              <div className="post-item" key={index}>
                <div className="post-img">
                  <img src={item?.PostImg} alt="Post" />
                </div>
                <div className="post-content">
                  <span className="post-dates">{item?.date}</span>
                  <h3 className="tabhead">{item?.title}</h3>
                  <p>{item?.desc}</p>
                </div>
              </div>
            )}
          </>
        );
      })}
    </>
  );
}

export default LatestPost;
