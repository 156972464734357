import { createSlice } from "@reduxjs/toolkit";

const videoSlice = createSlice({
  name: "video",
  initialState: {
    isVideoStop: JSON.parse(localStorage.getItem("isVideoStop")) || false,
  },
  reducers: {
    toggleVideo: (state) => {
      state.isVideoStop = !state.isVideoStop;
      localStorage.setItem("isVideoStop", JSON.stringify(state.isVideoStop));
    },
  },
});

export default videoSlice;
export const { toggleVideo } = videoSlice.actions;
