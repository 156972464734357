import { useEffect, useState } from "react";
import Button from "../../../Components/Dbutton";
import Dmodel from "../../../Components/Dmodel";
import { formatNumberWithCommas, isClaimable } from "../../../utils/helper";

const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;

const ActiveModel = ({ type, isActive, onClose, onCheck, data }) => {
  const [isData, setIsData] = useState();

  useEffect(() => {
    const currentItem = data?.find((item) => item.type === type);
    setIsData(currentItem);
  }, [type, data]);

  const canBeClaimed = isData ? isClaimable(isData?.claimAt) : false;

  return (
    <>
      <Dmodel onClose={onClose} isActive={isActive}>
        <div className="d-model-text">
          <h3>{isData?.title}</h3>
          {!canBeClaimed ? (
            <p>{isData?.desc}</p>
          ) : (
            <p>
             <span>Moderation complete!</span> <br/>Your prize is now ready to be claimed.
            </p>
          )}
          <div
            className="d-icon"
            style={{
              background: isData?.color,
              boxShadow: `0 0 50px ${isData?.color}`,
            }}
          >
            <img src={isData?.icon} alt="YouTube" />
          </div>
          <div className="icon-point">
            <h4>
              {"+"}
              {isData?.reward
                ? formatNumberWithCommas(Number(isData?.reward))
                : isData?.count}
            </h4>
            <img src={LogoIcon} alt="Logo-icon" />
          </div>
          <Button
            variant="secondary-contained"
            text={
              type === "Watch-the-youtube-video"
                ? "Watch the video"
                : "Check it out"
            }
            extraClasses="full-contain mb2"
            onClick={() => {
              window.open(isData?.btnLink, "_blank");
            }}
          />
          <Button
            variant="primary-contained"
            text="claim"
            extraClasses="w-full"
            disabled={!canBeClaimed}
            onClick={() => onCheck(isData)}
          />
        </div>
      </Dmodel>
    </>
  );
};

export default ActiveModel;
