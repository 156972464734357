const map = `${process.env.REACT_APP_S3_BUCKET}/Images/create-account-map.png`;
const wallet = `${process.env.REACT_APP_S3_BUCKET}/Images/connect-wallet-img.png`;
const followbg = `${process.env.REACT_APP_S3_BUCKET}/Images/follow-account-img.png`;
const retweetbg = `${process.env.REACT_APP_S3_BUCKET}/Images/retweet-like-img.png`;
const telegrambg = `${process.env.REACT_APP_S3_BUCKET}/Images/telegram-img.png`;
const discordbg = `${process.env.REACT_APP_S3_BUCKET}/Images/discord-img.png`;
const discordLogo = `${process.env.REACT_APP_S3_BUCKET}/Images/discord-logo.svg`;
// const securebg = `${process.env.REACT_APP_S3_BUCKET}/Images/secure-spot-img.png`;

const addPlus = `${process.env.REACT_APP_S3_BUCKET}/Images/add-plus-icon.svg`;
const connect = `${process.env.REACT_APP_S3_BUCKET}/Images/connect-icon.svg`;
const follow = `${process.env.REACT_APP_S3_BUCKET}/Images/twitter-icon.svg`;
const retweet = `${process.env.REACT_APP_S3_BUCKET}/Images/retweet-icon.svg`;
const like = `${process.env.REACT_APP_S3_BUCKET}/Images/like-icon.svg`;
// const lock = `${process.env.REACT_APP_S3_BUCKET}/Images/lock-icon.svg`;
const joinTelegram = `${process.env.REACT_APP_S3_BUCKET}/Images/join-icon.svg`;
const joinDiscord = `${process.env.REACT_APP_S3_BUCKET}/Images/join-discord-icon.svg`;

export const stepsConfig = {
  1: {
    img: map,
    subtext: "Create your Guild of Heroes account to start your journey.",
    icon: addPlus,
    btnText: "Register",
    progressKey: "registeration",
  },
  2: {
    img: wallet,
    subtext:
      "Secure your loot! Connect your wallet to ensure you're fully equipped to receive airdrop rewards.",
    icon: connect,
    btnText: "CONNECT",
    progressKey: "wallet",
  },
  // 3: {
  //   img: securebg,
  //   subtext:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed facilisis nisi diam, sed varius nulla posuere sed. ",
  //   icon: lock,
  //   btnText: "secure your spot",
  //   progressKey: "secureSpot",
  // },
  3: {
    img: followbg,
    subtext:
      "Boost your rank! Click below to follow us on Twitter and immerse yourself in the latest Guild of Heroes updates.",
    icon: follow,
    btnText: "Connect your Twitter",
    progressKey: "connectTwitter",
  },
  4: {
    img: retweetbg,
    subtext:
      "Secure your loot! Connect your wallet to ensure you're fully equipped to receive airdrop rewards.",
    icon: retweet,
    icon2: like,
    btnText: "RETWEET",
    btnText2: "Like",
    progressKey: "likeRetweet",
  },
  5: {
    img: telegrambg,
    subtext:
      "Secure your loot! Connect your wallet to ensure you're fully equipped to receive airdrop rewards.",
    icon: joinTelegram,
    btnText: "join now",
    progressKey: "joinTelegram",
  },
  6: {
    img: discordLogo,
    subtext:
      "Be part of our guild! Dive into Discord for deep dives, strategy talks, and a chance to meet fellow heroes.",
    icon: joinDiscord,
    btnText: "join now",
    progressKey: "joinDiscord",
  },
  8: {
    img: discordbg,
    subtext:
      "Be part of our guild! Dive into Discord for deep dives, strategy talks, and a chance to meet fellow heroes.",
    icon: joinDiscord,
    btnText: "join now",
    progressKey: "complete",
  },
};
