import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import Button from "../../../Components/Dbutton";
import { WeeklyTaskList } from "../../../Constants/list";
import DtaskCard from "../../../Components/DtaskCard";
import ActiveModel from "./activeModel";
import DloginModal from "../../../Components/DloginModal";
import { useDispatch, useSelector } from "react-redux";
import {
  useClaimUserTaskMutation,
  useGetUserTasksMutation,
} from "../../../services/tasks";
import { toast } from "react-toastify";
import Dtoast from "../../../Components/Dtoast";
import { setUserCoins } from "../../../store/slices/authSlice";
import { createEvent } from "../../../utils/event";

const DactiveTask = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [coins, setCoins] = useState(0);
  const [isDataId, setIsDataId] = useState();
  const { user } = useSelector((state) => state.auth);
  const { tasks } = useSelector((state) => state.task);
  const dispatch = useDispatch();

  const [getAllTasks, { isLoading }] = useGetUserTasksMutation();
  const [claimUserTask] = useClaimUserTaskMutation();

  const fetchUserTask = useCallback(
    async (id) => {
      try {
        await getAllTasks(id).unwrap();
      } catch (err) {
        toast.error(err?.message);
      }
    },
    [getAllTasks]
  );

  const toPascalCase = (text) => {
    return text
      .split("-") 
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
      .join(""); 
  };
  const updateUserTask = async (taskId) => {
    const body = {
      userId: user?._id,
      taskId: taskId,
    };
    try {
      const result = await claimUserTask(body).unwrap();
      if (result.data) {
        setShowToast(true);
        setCoins(result.data?.reward);
        createEvent(toPascalCase(result.data?.type), result.data?.reward);
        dispatch(setUserCoins(result.data?.reward));
        setIsModelOpen(false);
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserTask(user?._id);
    }
  }, [user, fetchUserTask]);

  const filteredTasks =
    tasks?.filter((task) => task.isActive && !task.isCompleted) || [];

  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <h2>active tasks</h2>
          <Button variant="secondary-contained" text="how it works" />
        </div>

        {filteredTasks.length === 0 && isLoading && (
          <div className="load-full">
            <div className="loader"></div>
          </div>
        )}
        {filteredTasks.length === 0 && !isLoading && (
          <div>You don't have any active tasks yet</div>
        )}

        <div className="d-task-list">
          {(user ? filteredTasks : WeeklyTaskList)?.map((item, index) => {
            return (
              <DtaskCard
                data={item}
                key={index}
                onClick={() => {
                  setIsModelOpen(true);
                  setIsDataId(item?.type);
                }}
                isStatusShow
              />
            );
          })}
        </div>
      </div>
      {user ? (
        <ActiveModel
          onClose={() => {
            setIsModelOpen(false);
            setIsDataId();
          }}
          isActive={isModelOpen}
          data={tasks}
          type={isDataId}
          onCheck={(task) => updateUserTask(task?._id)}
        />
      ) : (
        <DloginModal
          onClose={() => {
            setIsModelOpen(false);
          }}
          isActive={isModelOpen}
        />
      )}

      {showToast && (
        <Dtoast
          setIsSuccess={setShowToast}
          isSuccess={showToast}
          message={`You recieved ${coins} GOH Gold! Keep collecting to climb the ranks!`}
        />
      )}
    </>
  );
};

export default DactiveTask;
