import { CookiePolicys } from "../../../Constants/list";

function CookiePolicy() {
  return (
    <div className="privacy-content">
      <div className="container">
        <div className="heading">
          <h2>Cookie Policy for Guild of Heroes</h2>
        </div>
        <div className="privacy-content-list">
          {CookiePolicys?.map((item, index) => {
            return (
              <>
                <h3 key={index}>{item.title}</h3>
                {item?.para?.map((des, indx) => {
                  return (
                    <p key={indx}>
                      {des.text}{" "}
                      {des.linkText && <a href={des.link}>{des.linkText}</a>}
                    </p>
                  );
                })}
                <ul>
                  {item?.listing?.map((des, indx) => {
                    return <li key={indx}>{des}</li>;
                  })}
                </ul>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CookiePolicy;
