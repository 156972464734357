const smiley = `${process.env.REACT_APP_S3_BUCKET}/Images/smiley-icon.svg`;
const details = `${process.env.REACT_APP_S3_BUCKET}/Images/details-light-icon.svg`;
const lock = `${process.env.REACT_APP_S3_BUCKET}/Images/lock-light-icon.svg`;
const twof = `${process.env.REACT_APP_S3_BUCKET}/Images/2f-light-icon.svg`;
const download = `${process.env.REACT_APP_S3_BUCKET}/Images/download-light-icon.svg`;

const smileyLight = `${process.env.REACT_APP_S3_BUCKET}/Images/smiley-light-icon.svg`;
const detailsLight = `${process.env.REACT_APP_S3_BUCKET}/Images/details-icon.svg`;
const lockLight = `${process.env.REACT_APP_S3_BUCKET}/Images/lock-white-icon.svg`;
const twofLight = `${process.env.REACT_APP_S3_BUCKET}/Images/2f-icon.svg`;
const downloadLight = `${process.env.REACT_APP_S3_BUCKET}/Images/download-icon.svg`;

export const settingsMenu = [
  {
    id: "playerid",
    name: "PLAYER ID",
    icon: smiley,
    iconLight: smileyLight,
  },
  {
    id: "profiledetail",
    name: "PROFILE DETAILS",
    icon: details,
    iconLight: detailsLight,
  },
  {
    id: "accountsetting",
    name: "ACCOUNT SIGN-IN",
    icon: lock,
    iconLight: lockLight,
  },
  {
    id: "twofa",
    name: "TWO FACTOR AUTHENTICATION",
    icon: twof,
    iconLight: twofLight,
  },
  // {
  //   id: "sessionsetting",
  //   name: "SESSION SETTINGS",
  //   icon: settings,
  //   iconLight: settingsLight,
  // },
  {
    id: "updatepreferences",
    name: "UPDATE PREFERENCES",
    icon: download,
    iconLight: downloadLight,
  },
];
