import { useState } from "react";
import DcardBoxBox from "../DcardBox";
import OutsideClickHandler from "../OutsideClickHandler";

const NotificationIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/notification-icon.svg`;
const AwardIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/award-icon.svg`;
const CloseIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/close-icon.svg`;

const data = [
  {
    title: "Reward",
    des: "Hero chosen +4,000 GOH coins added!",
    time: "1h ago",
  },
  {
    title: "Reward",
    des: "Hero chosen +4,000 GOH coins added!",
    time: "1h ago",
  },
  {
    title: "Reward",
    des: "Hero chosen +4,000 GOH coins added!",
    time: "1h ago",
  },
  {
    title: "Reward",
    des: "Hero chosen +4,000 GOH coins added!",
    time: "1h ago",
  },
  {
    title: "Reward",
    des: "Hero chosen +4,000 GOH coins added!",
    time: "1h ago",
  },
  {
    title: "Reward",
    des: "Hero chosen +4,000 GOH coins added!",
    time: "1h ago",
  },
  {
    title: "Reward",
    des: "Hero chosen +4,000 GOH coins added!",
    time: "1h ago",
  },
  {
    title: "Reward",
    des: "Hero chosen +4,000 GOH coins added!",
    time: "1h ago",
  },
  {
    title: "Reward",
    des: "Hero chosen +4,000 GOH coins added!",
    time: "1h ago",
  },
  {
    title: "Reward",
    des: "Hero chosen +4,000 GOH coins added!",
    time: "1h ago",
  },
  {
    title: "Reward",
    des: "Hero chosen +4,000 GOH coins added!",
    time: "1h ago",
  },

  {
    title: "Reward",
    des: "Hero chosen +4,000 GOH coins added!",
    time: "1h ago",
  },
];

const Notification = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  return (
    <div className="notfication-col">
      <OutsideClickHandler onOutsideClick={() => setIsNotificationOpen(false)}>
        <div
          className="d-notification-icon"
          onClick={() => setIsNotificationOpen(!isNotificationOpen)}
        >
          <img src={NotificationIcon} alt="Notification-icon" />
        </div>
        <DcardBoxBox
          variant="primary-contained"
          extraClasses={`notification-wrapper ${
            isNotificationOpen ? "noty-active" : ""
          }`}
        >
          <div className="notification-header">
            <h2 className="hrad-text">Notifications</h2>
            <img
              src={CloseIcon}
              alt="close-icon"
              onClick={() => setIsNotificationOpen(false)}
            />
          </div>
          <div className="notification-list">
            <ul>
              {data.map((item, index) => {
                return (
                  <li className="not-item" key={index}>
                    <div className="d-notification-icon">
                      <img src={AwardIcon} alt="award-icon" />
                    </div>
                    <div>
                      <div>
                        <h3 className="not-title">{item.title}</h3>
                        <p>{item.des}</p>
                      </div>
                      <span>{item.time}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </DcardBoxBox>
      </OutsideClickHandler>
    </div>
  );
};

export default Notification;
