import React, { useState } from "react";
import Model from "../../../Components/Model";
import Card from "../../../Components/productCard";
import { ProductTabsList } from "../../../Constants/index";
import { SingleProductCard, Assassuns } from "../../../Constants/index";

function HeroesList() {
  const [active, setActive] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    setIsActive((current) => !current);
  };
  return (
    <section className="">
      <div className="filter-btn">
        <span className={isActive ? "active" : ""} onClick={handleClick}>
          FILTER HEROES
        </span>
      </div>
      <Model
        isActive={isActive}
        active={active}
        setActive={setActive}
        handleClick={handleClick}
        tabs={ProductTabsList}
        data={[ 
          SingleProductCard,
          Assassuns,
          SingleProductCard,
          Assassuns,
          SingleProductCard,
          Assassuns,
          SingleProductCard,
        ]}
      />

      <div className="heroes-tabs-content">
        <div className="container">
          <div className="row">
            {SingleProductCard.length > 0 ? (
              <Card data={SingleProductCard} isClickable/>
            ) : (
              <h1
                className="h3head"
                style={{ textAlign: "center", fontFamily: "Rakkas" }}
              >
                No results found.
              </h1>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroesList;
