export const event = {
  MATCHFINISHED: "Match_Finished",
  TASKCOMPLETE: "task_completed",
};

const eventsPoints = {
  EveryHourPlayed: 125000,
  GameWin: 60000,
  GameLoss: 15000,
  PlayerKill: 2500,
  TowerKill: 10000,
  KillNPC: 25,
  OnTapCharacter: 3,
  ChooseYourHero: 5000,
  WatchYouTubeVideo: 200000,
  FollowUsOnX: 100000,
  JoinTelegram: 50000,
  JoinAnnouncementChannel: 50000,
  LikeAndSharePost: 50000,
  CreateAccount: 10000,
};

export const createEvent = async (eventName, reward) => {
  const taskRequest = {
    EventName: event.TASKCOMPLETE,
    Body: {
      type: eventName,
      reward: reward,
    },
  };

  await window.PlayFab.ClientApi.WritePlayerEvent(taskRequest);
};

export const generateRandomData = (users) => {

  const getRandomEvent = () => {
    const eventTypes = Object.keys(eventsPoints);
    const randomIndex = Math.floor(Math.random() * eventTypes.length);
    return eventTypes[randomIndex];
  };

  const getRandomReward = (eventType) => {
    return eventsPoints[eventType];
  };
  const getRandomUserName = (ind) => {
    return users[ind]?.userName;
  };
  const getRandomAvatar = (ind) => {
    return users[ind]?.avatarUrl;
  };

  const randomDataArray = [];

  for (let i = 0; i < 10; i++) {
    const userName = getRandomUserName(i);
    const playerAvatar = getRandomAvatar(i);
    const eventType = getRandomEvent();
    const rewardEarned = getRandomReward(eventType);

    const randomData = {
      userName,
      playerAvatar,
      eventType,
      rewardEarned,
    };

    randomDataArray.push(randomData);
  }

  return randomDataArray;
};

export const mergeAndSumRewards = (data) => {
  const mergedData = {};

  data.forEach((item) => {
    const key = `${item.playFabId}-${item.eventType}`;

    if (!mergedData[key]) {
      mergedData[key] = {
        playFabId: item.playFabId,
        userName: item.userName,
        eventType: item.eventType,
        rewardEarned: item.rewardEarned,
        count: item.count,
      };
    } else {
      mergedData[key].rewardEarned += item.rewardEarned;
      mergedData[key].count += item.count;
    }
  });

  // Convert the merged data back into an array
  return Object.values(mergedData);
};
