import React from "react";
const aboutAbilties = `${process.env.REACT_APP_S3_BUCKET}/Images/about-abilties.png`;
const FooterImage = `${process.env.REACT_APP_S3_BUCKET}/Images/GuildOfHeroes_Twitter.png`;
// import ScreenVideo from "../../../Components/video";
// import Video from "../../Assets/videos/Ball_Lightning_new.mp4";
// import poster from "../../Assets/Images/about-video-poster.png";

const AvoutAbilities = () => {
  return (
    <>
      <section className="about-abilities">
        <div className="container">
          <h2 className="h2head">Command Center</h2>
          <h3 className="tabhead">Master Your Champion's Arsenal</h3>
          <p>
            Harness the power of your hero's abilities with precision. Monitor
            cooldowns, prepare your attacks, and strategize your next move to
            dominate the battlefield. Your Command Center is the key to
            unleashing potential and securing victory.
          </p>

          <img src={aboutAbilties} alt="GuildOfHeroes" />
        </div>
      </section>
      <div className="footer-img">
        <img src={FooterImage} alt="filter" />
      </div>
      {/* <ScreenVideo autoPlay={true} height="700px" scr={Video} poster={poster} /> */}
    </>
  );
};

export default AvoutAbilities;
