// store/slices/pointsSlice.js

import { createSlice } from "@reduxjs/toolkit";

const pointsSlice = createSlice({
  name: "points",
  initialState: {
    currentPoints: 3000,
    totalPoints: 3000,
  },
  reducers: {
    setCurrentPoints: (state, action) => {
      state.currentPoints = action.payload;
    },
    setTotalPoints: (state, action) => {
      state.totalPoints = action.payload;
    },
    incrementPoints: (state, action) => {
      state.currentPoints = Math.min(
        state.currentPoints + action.payload,
        state.totalPoints
      );
    },
    decrementPoints: (state, action) => {
      state.currentPoints = Math.max(state.currentPoints - action.payload, 0);
    },
  },
});

export default pointsSlice;
export const {
  setCurrentPoints,
  setTotalPoints,
  incrementPoints,
  decrementPoints,
} = pointsSlice.actions;
