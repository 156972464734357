import React, { useState } from "react";
import Button from "../../../Components/Dbutton";
import "./style.css";
import DcardBox from "../../../Components/DcardBox";
import { eraningDataList } from "../../../Constants";
import DailyTaskModels from "../DdeviceModels";
const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;
const LogoHoursIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;
const QuestionCircle = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/question-Circle.svg`;

const DcombatTask = () => {
  const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <h2>combat earnings</h2>
          <Button
            variant="secondary-contained"
            text="how it works"
            onClick={() => setIsDriveModalOpen(true)}
          />
        </div>

        <div className="d-earnings-list">
          {eraningDataList.map((item, index) => {
            return (
              <DcardBox
                variant="secondary-contained"
                extraClasses="earning-card"
                key={index}
              >
                <div>
                  <div className="icon-point">
                    <div>
                      {" "}
                      <h3 className="hrad-text">{item.title}</h3>
                    </div>
                    <div>
                      <span>{item.count}</span>
                      <img
                        src={LogoIcon}
                        alt="Logo-icon"
                        className="tool-icon"
                      />
                      <img
                        src={QuestionCircle}
                        alt="QuestionCircle-icon"
                        className="ques"
                      />
                      <div className="info-tool-tip">
                        <p>
                          Earn {item.count} coins for every hour you spend
                          actively playing the game.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-task-content">
                  <h2 className="d-gradient-text d-tit">
                    <img src={LogoHoursIcon} alt="Logo-icon" />
                    {item.hours}
                  </h2>
                  <p>{item.desc}</p>
                </div>
              </DcardBox>
            );
          })}
        </div>
      </div>

      <DailyTaskModels
        activeModel="stepone"
        isActive={isDriveModalOpen}
        onClose={() => setIsDriveModalOpen(false)}
      />
    </>
  );
};

export default DcombatTask;
