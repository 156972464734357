import "./style.css";
import React from "react";
import DcardBox from "../DcardBox";
import NavLink from "../Dlinks";
import { currencyFormat } from "../../utils/helper";

const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;

const DtaskCard = ({ data, extraClasses, onClick, isStatusShow, ...rest }) => {
  const btnText =
    (data?.isActive && !data?.isCompleted && "Claim Reward") ||
    (data?.isCompleted && "Check it out") ||
    data.linkText;

  return (
    <DcardBox
      variant="secondary-contained"
      extraClasses={`d-task-card ${extraClasses ? extraClasses : ""}`}
      {...rest}
    >
      <div className="icon-point">
        {isStatusShow && data.status && (
          <div
            className={`dot ${data?.isCompleted ? "completed" : "active"}`}
          ></div>
        )}
        {data.count && (
          <div>
            <span>
              {data?.reward ? currencyFormat(Number(data?.reward)) : data.count}
            </span>
            <img src={LogoIcon} alt="Logo-icon" className="tool-icon" />
          </div>
        )}
      </div>
      <div
        className="d-icon"
        style={{
          background: data.color,
          boxShadow: `0 0 50px ${data.color}`,
        }}
      >
        <img src={data.icon} alt="YouTube" />
      </div>
      <div className="d-task-content">
        <h3 className="hrad-text">{data.title}</h3>
        <NavLink
          text={btnText}
          link={data.btnLink}
          rightIcon
          onClick={onClick}
          target={data.target}
        />
      </div>
    </DcardBox>
  );
};

export default DtaskCard;
