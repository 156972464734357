import React from "react";

function TabsPanel({ title, desc, descTwo, subline, active }) {
  return (
    <div className={`tab-content ${active ? "active" : ""}`}>
      {subline ? <span className="subline">{subline}</span> : ""}
      {title ? <h3 className="tabhead">{title}</h3> : ""}
      <p>{desc}</p>
      {descTwo ? <p>{descTwo}</p> : ""}
    </div>
  );
}

export default TabsPanel;
