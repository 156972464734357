export function truncateURL(url, maxLength) {
  if (url.length <= maxLength) {
    return url;
  }

  const partLength = Math.floor((maxLength - 3) / 2);
  const firstPart = url.substring(0, partLength);
  const lastPart = url.substring(url.length - partLength, url.length);

  return `${firstPart}...${lastPart}`;
}
