import React from "react";

function ProductTabs({ Data, active, onClick }) {
  return (
    <ul>
      {Data.map((item, index) => {
        return (
          <li className={`${active === index ? "active" : ""}`} key={index}>
            <a
              href={`#${item}`}
              onClick={() => {
                onClick(index);
              }}
            >
              {item}
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export default ProductTabs;
