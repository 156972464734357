import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

const CountUpAnimation = ({ amount, noDecimal, startCount = 0 }) => {
  const [endCount, setEndCount] = useState(amount);

  useEffect(() => {
    setEndCount(amount);
  }, [amount]);

  return (
    <CountUp
      start={startCount}
      end={endCount}
      duration={3} // Duration of the animation in seconds
      separator=","
      decimals={noDecimal ? 0 : 2} // Display decimals if needed
      useEasing={true}
    />
  );
};

export default CountUpAnimation;
