import { Link } from "react-router-dom";
import "./style.css";

const RightArrow = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/right-arrow.svg`;
const LeftArrow = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/left-arrow.svg`;

const NavLink = ({
  text,
  link,
  onClick,
  target,
  extraClasses = "",
  rightIcon,
  leftIcon,
  ...rest
}) => {
  const commonProps = {
    className: `${extraClasses} d-gradient-text d-nav-link`,
    ...rest,
  };

  const content = (
    <>
      {leftIcon && <img src={LeftArrow} alt="icon" />}
      {text}
      {rightIcon && <img src={RightArrow} alt="icon" />}
    </>
  );

  if (onClick) {
    return (
      <span {...commonProps} onClick={onClick}>
        {content}
      </span>
    );
  }

  return target ? (
    <a href={link} {...commonProps} target="_blank" rel="noreferrer">
      {content}
    </a>
  ) : (
    <Link to={link ? link : "/"} {...commonProps}>
      {content}
    </Link>
  );
};

export default NavLink;
