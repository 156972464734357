import Button from "../../../Components/Dbutton";

const StepIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/step-two.png`;

const StepsTwo = ({ onNext }) => {
  return (
    <>
      <div className="d-model-icon">
        <img src={StepIcon} alt="" />
      </div>
      <div className="d-model-text">
        <h3>Daily Tasks</h3>
        <p>
          Complete a variety of handpicked tasks each day to earn free GOH
          Coins. By simply staying active on the app and logging in daily, you
          can collect coins and enjoy consistent rewards. This is your chance to
          boost your earnings just by engaging regularly
        </p>
        <Button
          variant="secondary-contained"
          text="Next"
          extraClasses="full-contain"
          onClick={onNext}
        />
      </div>
    </>
  );
};

export default StepsTwo;
