import Button from "../../../Components/Dbutton";
import NavLink from "../../../Components/Dlinks";

const StepIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/device-two.png`;

const StepsTwo = ({ onNext }) => {
  return (
    <>
      <div className="d-model-icon">
        <img src={StepIcon} alt="" />
      </div>
      <div className="d-model-text tele-modal">
        <h3>Telegram App Earnings</h3>
        <p>
          Complete similar tasks via the Telegram mini-app. Ideal for earning
          GOH Coins when you're away from your computer, keeping you engaged
          wherever you go
        </p>

        <NavLink
          text="Go to Telegram App"
          link="https://web.telegram.org/k/"
          target={true}
        />

        <Button
          variant="primary-contained"
          text="Next"
          extraClasses="w-full"
          onClick={onNext}
        />
      </div>
    </>
  );
};

export default StepsTwo;
