import React from "react";
import { Link } from "react-router-dom";
const HeroLogo = `${process.env.REACT_APP_S3_BUCKET}/Images/logo_horizontal_dark.svg`;
const HeroInfov = `${process.env.REACT_APP_S3_BUCKET}/Images/HeroInfo.mp4`;

const HeroInfo = () => {
  return (
    <section className="heroInfo">
      <div className="container">
        <img src={HeroLogo} alt="Hero Logo" />
        <h3 className="tabhead">Get the Game</h3>
        <p>
          Ready to start your adventure? Download the Guild of Heroes client now
          and join the action.
        </p>
        <Link to="/beta" className="btn">
          Play Game
        </Link>
      </div>
      <video autoPlay="" loop muted width="100%" playsInline="">
        <source src={HeroInfov} type="video/mp4" />
      </video>
    </section>
  );
};

export default HeroInfo;
