/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { footerMenu } from "../../Constants";
import Swal from "sweetalert2";
const logo = `${process.env.REACT_APP_S3_BUCKET}/Images/horizontal_orange.svg`;
const ChatIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/chat-icon.svg`;

const Footer = () => {
  const [showModal, setShowModal] = useState(() => {
    const storedValue = localStorage.getItem("showModal");
    return storedValue ? JSON.parse(storedValue) : true;
  });
  const { search } = useLocation();
  const [form, setForm] = useState({
    email: "",
  });

  useEffect(() => {
    localStorage.setItem("showModal", JSON.stringify(showModal));
  }, [showModal]);

  useEffect(() => {
    if (search) {
      const element = document.getElementById(search.split("=")[1]);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  }, [search]);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        "form-name": "Subcribe",
        ...form,
      }).toString(),
    })
      .then(() => {
        setForm({
          email: "",
        });
        Swal.fire({
          title: "Subscription Confirmed!",
          text: "Thank you for signing up. Stay tuned for the latest news and updates from us. Welcome to the adventure!",
          confirmButtonColor: "#00b14f",
          background: "#181c1f",
          confirmButtonText: "CLOSE",
          color: "#b3b3b3",
          iconName: "success",
          iconColor: "green",
        });
      })
      .catch((error) =>
        Swal.fire({
          title: "Error",
          text: error.message,
          confirmButtonColor: "#d0a755",
          background: "#17171a",
          color: "#b3b3b3",
          iconName: "error",
        })
      );
  };
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-menu">
            {footerMenu.map((item, index) => {
              return (
                <div className="menu-box" key={index}>
                  <h4>{item.title}</h4>
                  {item.emailList && (
                    <ul>
                      {item.emailList.map((mail, index) => (
                        <li key={index}>
                          <a href={mail.link}>
                            <img src={mail.image} alt="icon" />
                            {mail.text}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                  {item.menuList && (
                    <ul>
                      {item.menuList.map((menu, index) => (
                        <li key={index}>
                          <Link to={menu.link}>{menu.text}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                  {item.legalList && (
                    <ul>
                      {item.legalList.map((menu, index) => (
                        <li key={index}>
                          <a href={menu.link} rel="noreferrer" target="_blank">
                            {menu.text}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                  {item.soicalList && (
                    <ul className="socil">
                      {item.soicalList.map((icon, index) => (
                        <li key={index}>
                          <a href={icon.link} target="_blank" rel="noreferrer">
                            <img src={icon.image} alt="icon" />
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              );
            })}
          </div>
          <div className="foot-sub">
            <div className="sub">
              <h3 className="tabhead">Subscribe to our newsletter </h3>
              <p>Sign up for updates, news, or promotional emails</p>
            </div>
            <div className="sub" id="footer">
              <form
                name="Subscribe"
                method="post"
                data-netlify="true"
                onSubmit={handleSubmit}
                action="/"
                autoComplete="off"
              >
                <input type="hidden" name="form-name" value="Subscribe" />
                <input
                  type="text"
                  placeholder="Your email address"
                  required
                  value={form.email}
                  onChange={(e) => setForm({ email: e.target.value })}
                  autoComplete="off"
                />
                <input type="submit" value="Subscribe" />
              </form>
            </div>
          </div>
          <div className="copyright">
            <div className="lefttext">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
              <p>
                © 2024 NexGen Entertainment. All rights reserved. The game Guild
                of Heroes, its logo, and all related characters, settings, and
                distinctive likenesses are the exclusive property of NexGen
                Entertainment. Reproduction, distribution, or unauthorized use
                of any content, trademarks, or assets of the game without
                express permission is strictly prohibited.
              </p>
            </div>
          </div>
        </div>
      </footer>
      {/* {showModal && (
        <div className={`chat-model ${showModal ? "active" : ""}`}>
          <h2 className="h2head">Join our community</h2>
          <div>
            <img src={ChatIcon} alt="chat icon" />
            <span>
              Stay up to date with GOH news and be part of the game future.
            </span>
          </div>
          <ul>
            <li>
              <button onClick={() => setShowModal(false)}>Maybe Later</button>
            </li>
            <li onClick={() => setShowModal(false)}>
              <Link to="/?sectionId=footer" className="btn">
                JOIN NOW
              </Link>
            </li>
          </ul>
        </div>
      )} */}
    </>
  );
};

export default Footer;
