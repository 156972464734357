import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../Components/Loader";
const bannerlogo = `${process.env.REACT_APP_S3_BUCKET}/Images/bannerlogostoke.png`;

const Banner = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  return (
    <>
      {!videoLoaded && <Loader />}
      <section className="topbanner">
        <div
          className="container"
          style={{ display: videoLoaded ? "flex" : "none" }}
        >
          <img src={bannerlogo} alt="" />
          <Link to="/beta" className="btn">
            PLAY FOR FREE
          </Link>
        </div>
        <video
          autoPlay
          loop
          muted
          width="100%"
          playsInline
          onLoadedMetadata={() => setVideoLoaded(true)}
        >
          <source
            src={`${process.env.REACT_APP_S3_BUCKET}/videos/WebBannerNew_new.mp4`}
            type="video/mp4"
          />
        </video>
      </section>
    </>
  );
};

export default Banner;
