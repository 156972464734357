import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, prepareHeaders } from "./_utils";

export const characterRewardApi = createApi({
  reducerPath: "characterRewardApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getClaimCharacterReward: builder.mutation({
      query: (body) => ({
        url: `/home/tap`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useGetClaimCharacterRewardMutation } = characterRewardApi;
