import Button from "../../../Components/Dbutton";

const StepIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/step-six.png`;

const StepsSix = ({ onNext }) => {
  return (
    <>
      <div className="d-model-icon">
        <img src={StepIcon} alt="" />
      </div>
      <div className="d-model-text">
        <h3>refer friends</h3>
        <p>
          Get started now and maximize your earnings by completing tasks and
          inviting friends to join the adventure!
        </p>
        <Button
          variant="secondary-contained"
          text="Next"
          extraClasses="full-contain"
          onClick={onNext}
        />
      </div>
    </>
  );
};

export default StepsSix;
