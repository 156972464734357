import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import DdynamicTable from "../../../Components/DdynamicTable";
import { rewardFeedColumns } from "../../../Constants";
import { useDispatch, useSelector } from "react-redux";
import { generateRandomData } from "../../../utils/event";
import { setEvents } from "../../../store/slices/eventSlice";
import { useGetHighscroesMutation } from "../../../services/highscroes";

const DrewardFeedTable = () => {
  const [currentPage, setCurrentPage] = useState({
    pageNo: 1,
    pageSize: 13,
  });
  const { events } = useSelector((state) => state.events);
  const { highscroes } = useSelector((state) => state.highscroes);
  const dispatch = useDispatch();

  const [getHighscroes] = useGetHighscroesMutation();

  useEffect(() => {
    if (highscroes.length > 0 && events.length === 0) {
      const randomData = generateRandomData(highscroes);
      dispatch(setEvents(randomData));
    }
  }, [dispatch, events.length, highscroes]);

  useEffect(() => {
    getHighscroes({});
  }, [getHighscroes]);

  return (
    <>
      {useMemo(() => {
        return (
          <DdynamicTable
            data={events}
            columns={rewardFeedColumns}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isBackLink=""
            noData="No events available yet"
          />
        );
      }, [currentPage, events])}
    </>
  );
};

export default DrewardFeedTable;
