import React from "react";

import "./style.css";

const MarketPlace = () => {
  return (
    <div id="mainwrapper" className="marketplace-page">
      <div>
        <h2 className="h2head mb5">marketplace</h2>
        <span>Coming Soon</span>
      </div>
    </div>
  );
};

export default MarketPlace;
