import "./style.css";

const Modal = ({ message, action, handler, btnText }) => {
  return (
    <div className="warning-modal">
      <p>
        {message}
        {action}
      </p>
      {btnText !== "" && (
        <button onClick={handler} className="btn pw-warning">
          {btnText}
        </button>
      )}
    </div>
  );
};

export default Modal;
