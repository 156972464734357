export const baseUrl = process.env.REACT_APP_BASE_URL;
// export const baseUrl = "http://localhost:8080/api/v1";

export const prepareHeaders = (headers, { getState }) => {
  const token = getState().auth.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};
