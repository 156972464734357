import { useEffect, useState } from "react";
import Button from "../../../Components/Dbutton";
import DModel from "../../../Components/Dmodel";
import { airdropData } from "../../../Constants/list";
import TextInput from "../../../Components/Dinput";
const DWallet = `${process.env.REACT_APP_S3_BUCKET}/Images/wallet.svg`;

const DairdropModel = ({ id, isActive, onClose }) => {
  const [isData, setIsData] = useState();
  const [isConfirm, setIsConfirm] = useState(false);
  const [isInput, setIsInput] = useState(false);
  const [isValue, setIsValue] = useState("");

  useEffect(() => {
    const currentItem = airdropData.find((item) => item.id === id);
    setIsData(currentItem);
  }, [id]);

  return (
    <>
      <DModel
        onClose={() => {
          onClose();
          setIsData();
        }}
        isActive={isActive}
        extraClasses="p-0"
      >
        <div
          className="d-icon"
          style={{
            background: "#fff",
            boxShadow: `0 0 50px #fff`,
          }}
        >
          <img src={DWallet} alt={isData?.title} />
        </div>
        <div className="d-model-text">
          {!isConfirm ? (
            <>
              <h3>{isData?.title}</h3>
              <p>
                Connect your cryptocurrency wallet. If you don’t have one, you
                can create an EVM-compatible wallet. Guild of Heroes will be on
                Avalanche, so make sure your wallet supports it.{" "}
              </p>
              <Button
                variant="primary-contained"
                text="Connect your wallet"
                extraClasses="w-full"
                onClick={() => setIsConfirm(true)}
              />
            </>
          ) : (
            <>
              {!isInput ? (
                <>
                  <h3>{isData?.title}</h3>
                  <p>
                    Connect your cryptocurrency wallet. If you don't have one,
                    create one in your Telegram account
                  </p>

                  <Button
                    variant="primary-contained"
                    text="Check it out"
                    extraClasses="w-full"
                    onClick={() => setIsInput(true)}
                  />
                </>
              ) : (
                <>
                  <h3>Wallet Connected</h3>
                  <p>Your cryptocurrency wallet is successfully connected</p>
                  <TextInput
                    type="text"
                    value={isValue}
                    onChange={(e) => setIsValue(e.target.value)}
                    extraClasses="wallet-input"
                  />
                </>
              )}
            </>
          )}
        </div>
      </DModel>
    </>
  );
};

export default DairdropModel;
