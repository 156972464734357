/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import "./style.css";
import TextInput from "../Dinput";
import { debounce } from "../../utils/helper";
const SearchIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/search-icon.svg`;

const DsearchBox = ({ onChange, extraClasses }) => {
  const debouncedOnChange = useCallback(
    debounce((event) => onChange(event), 300),
    [onChange]
  );

  return (
    <div className={`d-search-box ${extraClasses ? extraClasses : ""}`}>
      <TextInput
        type="text"
        placeholder="Search"
        onChange={debouncedOnChange}
      />
      <span className="search-btn">
        <img src={SearchIcon} alt="search-icon" />
      </span>
    </div>
  );
};

export default DsearchBox;
