const Game = `${process.env.REACT_APP_S3_BUCKET}/Images/Game.svg`;
const Medal = `${process.env.REACT_APP_S3_BUCKET}/Images/Medal.svg`;
const Fight = `${process.env.REACT_APP_S3_BUCKET}/Images/Fight.svg`;
const HeroOne = `${process.env.REACT_APP_S3_BUCKET}/Images/FERNIX.png`;
const HeroTwo = `${process.env.REACT_APP_S3_BUCKET}/Images/Heronew.png`;
const HeroThree = `${process.env.REACT_APP_S3_BUCKET}/Images/hero-three.png`;
const HeroFour = `${process.env.REACT_APP_S3_BUCKET}/Images/hero-four.png`;
const ViewMoreIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/ViewMore.png`;
const SkillIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/SkillIcon.svg`;
const AboutHeroOne = `${process.env.REACT_APP_S3_BUCKET}/Images/Hero.png`;
const AboutHeroTwo = `${process.env.REACT_APP_S3_BUCKET}/Images/Hero2.png`;
const AboutHeroThree = `${process.env.REACT_APP_S3_BUCKET}/Images/Hero3.png`;
const AboutHeroFour = `${process.env.REACT_APP_S3_BUCKET}/Images/Hero4.png`;

export const tabIcon = [
  {
    image: Game,
  },
  {
    image: Medal,
  },
  {
    image: Fight,
  },
];

export const HeroImgLIst = [
  {
    image: HeroOne,
  },
  {
    image: HeroTwo,
  },
  {
    image: HeroThree,
  },
  {
    image: HeroFour,
  },
  {
    image: ViewMoreIcon,
  },
];

export const AboutHeroImgLIst = [
  {
    image: AboutHeroOne,
  },
  {
    image: AboutHeroTwo,
  },
  {
    image: AboutHeroThree,
  },
  {
    image: AboutHeroFour,
  },
  {
    image: ViewMoreIcon,
  },
];

export const HeroInnerIcon = [
  {
    image: SkillIcon,
  },
  {
    image: SkillIcon,
  },
  {
    image: SkillIcon,
  },
];

export const ProductTabsList = [
  "ALL",
  "ASSASSINS",
  "FIGHTERS",
  "MAGES",
  "MARKSMEN",
  "SUPPORTS",
  "TANKS",
];

export const PostTabs = ["ALL", "general", "events", "notes"];
