/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
import { Progress } from "antd";
import "./style.css";
import { useEffect, useState } from "react";
import { stepsConfig } from "../../../Constants/stepsConfig";
import { useAuth } from "../../../Context/AuthContext";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import Loader from "../../../Components/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "../../../Components/Modal";
import { toast } from "react-toastify";
import { addPlayerAirdropStep } from "../../../utils/helper";
import { useSelector } from "react-redux";

const addIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/add-icon.svg`;
const connectWallet = `${process.env.REACT_APP_S3_BUCKET}/Images/connectWallet-icon.svg`;
// const secureSpot = `${process.env.REACT_APP_S3_BUCKET}/Images/secureSpot-icon.svg`;
const discord = `${process.env.REACT_APP_S3_BUCKET}/Images/discord-icon.svg`;
const follow = `${process.env.REACT_APP_S3_BUCKET}/Images/follow-icon.svg`;
const repost = `${process.env.REACT_APP_S3_BUCKET}/Images/repost-icon.svg`;
const telegram = `${process.env.REACT_APP_S3_BUCKET}/Images/telegram-icon.svg`;
const stepCheck = `${process.env.REACT_APP_S3_BUCKET}/Images/step-check-icon.svg`;
const closeButton = `${process.env.REACT_APP_S3_BUCKET}/Images/closeIcon.svg`;

// Success Modal options
const option = {
  autoClose: 2000,
  closeButton: closeButton,
  closeOnClick: true,
  type: toast.TYPE.SUCCESS,
  hideProgressBar: true,
  position: "top-center",
  style: { border: "1px solid #d0a755" },
};

const option2 = {
  autoClose: 2000,
  closeButton: closeButton,
  closeOnClick: true,
  type: toast.TYPE.ERROR,
  hideProgressBar: true,
  position: "top-center",
  style: { border: "1px solid #d0a755" },
};
// Air drop body component
const AirdropBody = ({
  steps,
  img,
  subtext,
  icon,
  btnText,
  icon2,
  btnText2,
  setSteps,
  updateUserData,
  stepInProgress,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const { open } = useWeb3Modal();
  const navigate = useNavigate();

  // Handler for the common button in the body
  const handler = (e) => {
    e.preventDefault();

    // Redirect to register page
    if (steps === 1) {
      navigate("/sign-up");
    }

    // Open wallet modal
    if (steps === 2) {
      open();
    }

    if (steps === 3) {
      navigate("/secure-your-spot");
    }

    // Request twitter access token
    if (steps === 4) {
      fetch(
        "https://agvfqq7sf0.execute-api.us-east-1.amazonaws.com/default/requestTwitterToken",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem("twitterContext", JSON.stringify(data));
          window.open(data.url, "_self");
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // Like and Retweet a twitter post
    if (steps === 5) {
      window.open(
        "https://x.com/GuildOfHeroes_/status/1757827628474478809?s=20",
        "_target"
      );
    }

    // Join telegram channel
    if (steps === 6) {
      window.open("https://t.me/GOHofficial", "_target");
    }

    // Join discord channel
    if (steps === 7) {
      window.open("https://discord.com/invite/qr2fUaf2p7", "_target");
    }
  };

  // Handler for the second button in step 4
  const handlerLike = () => {
    if (steps === 5) {
      window.open(
        "https://x.com/GuildOfHeroes_/status/1757827628474478809?s=20",
        "_target"
      );
    }
  };

  // Handler for next
  const handleNext = () => {
    if (!isChecked) {
      toast(
        <Modal
          message="Please select the checkbox!"
          action=""
          handler={toast.dismiss()}
          btnText=""
        />,
        option2
      );
      return;
    }
    if (steps === 5) {
      var updateUserDataRequest = {
        Data: {
          airdrop_guide_likeRetweet: isChecked,
        },
      };
      updateUserData(updateUserDataRequest);
      toast(
        <Modal
          message="Liked & Retweeted!"
          action=""
          handler={toast.dismiss()}
          btnText=""
        />,
        option
      );
      addPlayerAirdropStep("Liked & Retweeted");
      setIsChecked(!isChecked);
    }
    if (steps === 6) {
      var updateUserDataRequest = {
        Data: {
          airdrop_guide_joinTelegram: isChecked,
        },
      };
      updateUserData(updateUserDataRequest);
      toast(
        <Modal
          message="Joined Telegram!"
          action=""
          handler={toast.dismiss()}
          btnText=""
        />,
        option
      );
      addPlayerAirdropStep("Joined Telegram");
      setIsChecked(!isChecked);
    }
    if (steps === 7) {
      var updateUserDataRequest = {
        Data: {
          airdrop_guide_joinDiscord: isChecked,
        },
      };
      updateUserData(updateUserDataRequest);
      toast(
        <Modal
          message="Joined Discord!"
          action=""
          handler={toast.dismiss()}
          btnText=""
        />,
        option
      );
      addPlayerAirdropStep("Joined Discord");
      setIsChecked(!isChecked);
    }
    setSteps((prev) => prev + 1);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <img src={img} alt="" className="stepBg" />
      <p>{subtext}</p>
      <button
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          gap: 5,
          paddingLeft: 130,
          paddingRight: 130,
          margin: 0,
          textAlign: "center",
          maxWidth: 200,
          textWrap: "nowrap",
        }}
        className={`btn registerBtn steps-btn next ${
          stepInProgress && "disabled"
        } `}
        onClick={handler}
        disabled={stepInProgress}
      >
        <img width={16} height={16} src={icon} alt="button" />
        {btnText}
      </button>
      {steps === 5 && (
        <button
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            gap: 5,
            paddingLeft: 130,
            paddingRight: 130,
            margin: 0,
            textAlign: "center",
            maxWidth: 200,
          }}
          className={`btn registerBtn steps-btn ${
            stepInProgress && "disabled"
          } `}
          onClick={handlerLike}
          disabled={stepInProgress}
        >
          <img width={16} height={16} src={icon2} alt="button" />
          {btnText2}
        </button>
      )}

      {(steps === 5 || steps === 6 || steps === 7) && (
        <>
          <div className="check-box">
            <input
              type="checkbox"
              id="Stay"
              name="news"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              disabled={stepInProgress}
            />
            <label style={{ fontSize: 16 }} htmlFor="Stay">
              {" "}
              {steps === 5
                ? "I have successfully retweeted and liked the required post."
                : steps === 6
                ? "I confirm that I have joined the Telegram group."
                : "I confirm that I have joined the Discord channel."}
            </label>
          </div>
          <p style={{ color: "#d0a755", margin: 0, textAlign: "start" }}>
            {steps === 5
              ? "* By checking this box, I acknowledge that my actions will be manually verified. If I have not completed this step, I understand that I will be disqualified from receiving any rewards at the end of the campaign."
              : steps === 6
              ? "* Continual membership will be checked when rewards are distributed. To receive rewards, you must be present in the Telegram group at that time."
              : "* Membership will be verified at the time of reward distribution. You must remain a member of the Discord channel to qualify for rewards."}
          </p>

          <button
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              gap: 5,
              paddingLeft: 130,
              paddingRight: 130,
              margin: 0,
              textAlign: "center",
              maxWidth: 200,
            }}
            className={`btn registerBtn steps-btn next ${
              stepInProgress && "disabled"
            } `}
            onClick={handleNext}
            disabled={stepInProgress}
          >
            Next
          </button>
        </>
      )}

      {steps === 7 && stepInProgress && (
        <button
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            gap: 5,
            paddingLeft: 130,
            paddingRight: 130,
            margin: 0,
            textAlign: "center",
            maxWidth: 200,
          }}
          className={`btn registerBtn steps-btn`}
          onClick={() => setSteps(8)}
        >
          Status
        </button>
      )}
    </>
  );
};

const CompleteBody = ({ userData }) => {
  return (
    <div className="complete-container">
      {JSON.parse(userData?.airdrop_stepsCompleted?.Value).map(
        (step, index) => (
          <div className="line" key={index}>
            <p>{step}</p>
            <span style={{ fontWeight: 700 }}>20 pts.</span>
          </div>
        )
      )}
      <div
        className="line"
        style={{ borderTop: "1px solid #6C6D6E", paddingTop: 10 }}
      >
        <p>Total</p>
        <span style={{ fontWeight: 700 }}>
          {JSON.parse(userData?.airdrop_stepsCompleted?.Value).length * 20} pts.
        </span>
      </div>
    </div>
  );
};

const AirdropGuide = () => {
  const [steps, setSteps] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [twitterToken, setTwitterToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [progress, setProgress] = useState({
    registeration: false,
    wallet: false,
    // secureSpot: false,
    connectTwitter: false,
    likeRetweet: false,
    joinTelegram: false,
    joinDiscord: false,
    complete: false,
  });

  const { walletAddress } = useAuth();
  const { user } = useSelector((state) => state.auth);
  const currentStep = stepsConfig[steps];
  const [searchParam] = useSearchParams();

  // render the body of the steps
  const renderAirdropBody = () => {
    const stepInProgress = progress[currentStep.progressKey];
    if (steps !== 8) {
      return (
        <AirdropBody
          img={currentStep.img}
          subtext={currentStep.subtext}
          icon={currentStep.icon}
          btnText={currentStep.btnText}
          icon2={currentStep?.icon2}
          btnText2={currentStep?.btnText2}
          setSteps={setSteps}
          steps={steps}
          updateUserData={updateUserData}
          stepInProgress={stepInProgress}
        />
      );
    }
    if (steps < 8) {
      setSteps((prevSteps) => prevSteps + 1);
    }
    if (steps === 8) {
      return !loading && <CompleteBody userData={userData} />;
    }
  };

  // Fetch user data
  const fetchUserData = () => {
    window.PlayFab.ClientApi.GetUserData({}, (res, error) => {
      if (res) {
        setUserData(res?.data?.Data);
      } else {
        console.log("Error fetching user data:", error);
      }
      setLoading(false);
    });
  };

  // Update user data
  const updateUserData = (data) => {
    window.PlayFab.ClientApi.UpdateUserData(data, (res, error) => {
      if (res) {
        fetchUserData();
      } else {
        console.log("Error completeting step:", error);
      }
    });
  };

  // this function is used to link the twitter account with the playfab account
  const linkTwitter = (id) => {
    var linkUserDataRequest = {
      CustomId: id,
      ForceLink: false,
      CreateAccount: false,
      CustomTags: null,
    };

    window.PlayFab.ClientApi.LinkCustomID(linkUserDataRequest, (res, error) => {
      if (res) {
        setLoading(false);
        setTwitterToken(id);
        toast(
          <Modal
            message="Twitter Connected!"
            action=""
            handler={toast.dismiss()}
            btnText=""
          />,
          option
        );
        addPlayerAirdropStep("Twitter Connected");
      } else {
        console.log("Error connecting twitter:", error);
        setLoading(false);
      }
    });
  };

  // Check if the code exist in params and handle token verification. If nothing in params then getUserData to check whether the twitter is linked or not
  useEffect(() => {
    setLoading(true);

    const state = searchParam.get("state");
    const code = searchParam.get("code");
    const twitterContext = JSON.parse(localStorage.getItem("twitterContext"));
    if (state && code) {
      fetch(
        `https://xk9uj3f40b.execute-api.us-east-1.amazonaws.com/default/twitterAuth?state=${state}&code=${code}&codeVerifier=${twitterContext.codeVerifier}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.accessToken) {
            localStorage.removeItem("twitterContext");
            localStorage.setItem(
              "accessToken",
              JSON.stringify(data.accessToken)
            );
            linkTwitter(data.accessToken);
          } else {
            console.log(data.error);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      try {
        window.PlayFab.ClientApi.GetPlayerProfile(
          {
            ProfileConstraints: {
              ShowLinkedAccounts: true,
            },
          },
          (res, error) => {
            const linkedAccounts = res.data.PlayerProfile.LinkedAccounts;
            const hasCustomLinkedAccount = linkedAccounts.some((account) => {
              if (account.Platform === "Custom") {
                setTwitterToken(account.PlatformUserId);
                return true;
              }
              return false;
            });
            if (hasCustomLinkedAccount) {
              setLoading(false);
            } else {
              console.log("Twitter not linked:", error);
              setLoading(false);
            }
          }
        );
        fetchUserData();
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  // Fetch user data on load and whenever the user data changes
  useEffect(() => {
    setLoading(true);
    fetchUserData();
  }, [userData]);

  // Check whether the steps are completed or not.
  useEffect(() => {
    setLoading(true);
    if (user) {
      setProgress((prev) => ({ ...prev, registeration: true }));
    }
    if (userData?.airdrop_guide_joinDiscord?.Value) {
      setProgress((prev) => ({ ...prev, joinDiscord: true }));
    }
    if (!userData?.airdrop_guide_joinDiscord?.Value) {
      setProgress((prev) => ({ ...prev, joinDiscord: false }));
      // setSteps(7);
    }
    if (userData?.airdrop_guide_joinTelegram?.Value) {
      setProgress((prev) => ({ ...prev, joinTelegram: true }));
    }
    if (!userData?.airdrop_guide_joinTelegram?.Value) {
      setProgress((prev) => ({ ...prev, joinTelegram: false }));
      // setSteps(6);
    }
    if (userData?.airdrop_guide_likeRetweet?.Value) {
      setProgress((prev) => ({ ...prev, likeRetweet: true }));
    }
    if (!userData?.airdrop_guide_likeRetweet?.Value) {
      setProgress((prev) => ({ ...prev, likeRetweet: false }));
      // setSteps(5);
    }
    if (twitterToken) {
      setProgress((prev) => ({ ...prev, connectTwitter: true }));
    }
    if (!twitterToken) {
      setProgress((prev) => ({ ...prev, connectTwitter: false }));
      // setSteps(4);
    }
    // if (userData?.spot_secured?.Value) {
    //   setProgress((prev) => ({ ...prev, secureSpot: true }));
    // }

    // if (!userData?.spot_secured?.Value) {
    //   setProgress((prev) => ({ ...prev, secureSpot: false }));
    //   // setSteps(3);
    // }
    if (walletAddress) {
      setProgress((prev) => ({ ...prev, wallet: true }));
    }
    if (!walletAddress) {
      setProgress((prev) => ({ ...prev, wallet: false }));
      setSteps(2);
    }

    setLoading(false);
  }, [user, walletAddress, twitterToken, userData]);

  // Maintain the progress of air drop guide
  useEffect(() => {
    setLoading(true);
    const {
      registeration,
      wallet,
      // secureSpot,
      connectTwitter,
      likeRetweet,
      joinTelegram,
      joinDiscord,
    } = progress;
    const totalTasks = Object.keys({
      registeration,
      wallet,
      // secureSpot,
      connectTwitter,
      likeRetweet,
      joinTelegram,
      joinDiscord,
    }).length;
    const completedTasks = Object.values({
      registeration,
      wallet,
      // secureSpot,
      connectTwitter,
      likeRetweet,
      joinTelegram,
      joinDiscord,
    }).filter((task) => task).length;
    const percentageDone = (completedTasks / totalTasks) * 100;

    setPercentage(Math.floor(percentageDone));
    setLoading(false);
  }, [progress]);

  return (
    <div id="mainwrapper" className="airdropGuide-page">
      {loading ? (
        <Loader />
      ) : (
        <div className="airdropGuide-container">
          <Progress
            type="circle"
            percent={percentage}
            size={80}
            strokeWidth={12}
            trailColor="#2C2517"
            success={{ strokeColor: "#2C2517" }}
          />
          <h2 className="h2head text-center">Airdrop Progress</h2>
          <p>
            Follow the steps outlined below to complete the airdrop process. Be
            sure to carefully follow each instruction provided. Keep track of
            your progress and completion percentage to ensure a smooth
            experience throughout the process.
          </p>
          <div className="airdrop-box">
            <h2 className="h2head" style={{ fontSize: 25 }}>
              {steps === 1
                ? "create account"
                : steps === 2
                ? "CONNECT WALLET"
                : // : steps === 3
                // ? "SECURE your SPOT"
                steps === 3
                ? "Follow Us on Twitter"
                : steps === 4
                ? "Retweet and Like Our Post"
                : steps === 5
                ? "Join Our Telegram"
                : steps === 6
                ? "Join Our Discord"
                : "Completed"}
            </h2>
            <div className="steps-container">
              <div className="lines first"></div>
              <img
                src={progress.registeration ? stepCheck : addIcon}
                style={{
                  opacity: progress.registeration && 1,
                  cursor: "pointer",
                }}
                className={steps === 1 ? "icons focus" : "icons"}
                onClick={() => setSteps(1)}
                alt="line"
              />
              <div className="lines"></div>
              <img
                src={progress.wallet ? stepCheck : connectWallet}
                style={{ opacity: progress.wallet && 1, cursor: "pointer" }}
                className={steps === 2 ? "icons focus" : "icons"}
                onClick={() => setSteps(2)}
                alt="line"
              />
              <div className="lines"></div>
              {/* <img
                src={progress.secureSpot ? stepCheck : secureSpot}
                style={{ opacity: progress.wallet && 1, cursor: "pointer" }}
                className={steps === 3 ? "icons focus" : "icons"}
                onClick={() => setSteps(3)}
                alt="line"
              /> */}
              {/* <div className="lines"></div> */}
              <img
                src={progress.connectTwitter ? stepCheck : follow}
                style={{
                  opacity: progress.connectTwitter && 1,
                  cursor: "pointer",
                }}
                className={steps === 3 ? "icons focus" : "icons"}
                onClick={() => setSteps(3)}
                alt="line"
              />
              <div className="lines"></div>
              <img
                src={progress.likeRetweet ? stepCheck : repost}
                style={{
                  opacity: progress.likeRetweet && 1,
                  cursor: "pointer",
                }}
                className={steps === 4 ? "icons focus" : "icons"}
                onClick={() => setSteps(4)}
                alt="line"
              />
              <div className="lines"></div>
              <img
                src={progress.joinTelegram ? stepCheck : telegram}
                style={{
                  opacity: progress.joinTelegram && 1,
                  cursor: "pointer",
                }}
                className={steps === 5 ? "icons focus" : "icons"}
                onClick={() => setSteps(5)}
                alt="line"
              />
              <div className="lines"></div>
              <img
                src={progress.joinDiscord ? stepCheck : discord}
                style={{
                  opacity: progress.joinDiscord && 1,
                  cursor: "pointer",
                }}
                className={steps === 6 ? "icons focus" : "icons"}
                onClick={() => setSteps(6)}
                alt="line"
              />
              <div className="lines last"></div>
            </div>
            <div className="account-body">{renderAirdropBody()}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AirdropGuide;
