import { useEffect, useState } from "react";
import Button from "../../../Components/Dbutton";
import Dmodel from "../../../Components/Dmodel";
import { formatNumberWithCommas } from "../../../utils/helper";

const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;

const CompleteModel = ({ type, isActive, onClose,onCheck, data }) => {
  const [isData, setIsData] = useState();
  useEffect(() => {
    const currentItem = data?.find((item) => item.type === type);

    setIsData(currentItem);
  }, [type, data]);

  return (
    <>
      <Dmodel onClose={onClose} isActive={isActive}>
        <div className="d-model-text">
          <h3>{isData?.title}</h3>

          <div
            className="d-icon"
            style={{
              background: isData?.color,
              boxShadow: `0 0 50px ${isData?.color}`,
            }}
          >
            <img src={isData?.icon} alt="YouTube" />
          </div>
          <div className="icon-point">
            <h4>
              {"+"}
              {isData?.reward
                ? formatNumberWithCommas(Number(isData?.reward))
                : isData?.count}
            </h4>
            <img src={LogoIcon} alt="Logo-icon" />
          </div>
          <Button
            variant="secondary-contained"
            text={type === "Watch-the-youtube-video" ? "Watch the video" : "Check it out"}
            extraClasses="full-contain mb2"
            onClick={()=>onCheck(isData)}
          />
          {/* <Button
            variant="primary-contained"
            text="check"
            extraClasses="w-full light-btn"
          /> */}
        </div>
      </Dmodel>
    </>
  );
};

export default CompleteModel;
