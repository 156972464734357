/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./style.css";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import Modal from "../Modal";
import { useSelector } from "react-redux";
const closeButton = `${process.env.REACT_APP_S3_BUCKET}/Images/closeIcon.svg`;

const option = {
  autoClose: 2000,
  closeButton: closeButton,
  closeOnClick: true,
  type: toast.TYPE.SUCCESS,
  hideProgressBar: true,
  position: "top-center",
  style: { border: "1px solid #d0a755" },
};

const TwoFA = ({ userData, setTwofaProgress }) => {
  const { playFabInfo } = useSelector((state) => state.auth);
  const [checked, setChecked] = useState(
    userData.twofa ? userData.twofa.Value === "true" : false
  );
  const [error, setError] = useState();

  useEffect(() => {
    if (checked) {
      setTwofaProgress(checked);
    }
  }, []);

  const toggleTwoFa = (checked) => {
    setChecked(checked);
    var updateUserDataRequest = {
      Data: {
        twofa: checked,
      },
    };

    window.PlayFab.ClientApi.UpdateUserData(
      updateUserDataRequest,
      (res, error) => {
        if (res) {
          setTwofaProgress(checked);
          toast(
            <Modal
              message={`Two Factor Authentication ${
                checked ? "enabled" : "disabled"
              }`}
              action=""
              handler={toast.dismiss()}
              btnText=""
            />,
            option
          );
        } else {
          console.log("Error updating twofa:", error);
          setError(error);
        }
      }
    );
  };

  return (
    <div className="tbbox twofa" id="twofa">
      <h5>Two Factor Authentication</h5>
      <p>
        If enabled, you will receive an email at the registered email address
        upon login with a One-Time Password (OTP). You will be required to use
        this OTP to log in to your Guild of Heroes account.
      </p>
      <div className="switch-cont">
        <span className="twofa-switch">Enable</span>
        <label htmlFor="material-switch">
          <ReactSwitch
            checked={checked}
            onChange={toggleTwoFa}
            onColor="#e3ca99"
            onHandleColor="#d0a755"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            disabled={
              playFabInfo?.AccountInfo.TitleInfo.Origination === "Google" ||
              playFabInfo?.AccountInfo.TitleInfo.Origination === "Facebook"
                ? true
                : false
            }
            width={48}
            className="react-switch"
            id="material-switch"
          />
        </label>
      </div>
      {error && (
        <p style={{ color: "red" }}>
          Error toggling Two Factor Authentication. Please try again.
        </p>
      )}
    </div>
  );
};

export default TwoFA;
