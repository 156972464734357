import { useEffect } from "react";
import "./style.css";
const CloseIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/close-icon.svg`;

const Dmodel = ({
  children,
  steps,
  isActive,
  isCurrentStep,
  onClose,
  extraClasses,
}) => {
  useEffect(() => {
    if (isActive) {
      document.body.classList.add("d-wraper-modal-open");
    } else if (!isActive) {
      document.body.classList.remove("d-wraper-modal-open");
    }

    return () => {
      document.body.classList.remove("d-wraper-modal-open");
    };
  }, [isActive]);

  return (
    <div
      className={`d-model ${isActive ? "active-model" : ""} ${
        extraClasses ? extraClasses : ""
      }`}
    >
      <div className="d-model-overlay" onClick={onClose} />
      <div className="d-model-content">
        <div className="d-model-header">
          <ul className="d-model-steps">
            {steps && (
              <>
                {Array.from({ length: steps }).map((_, index) => {
                  return (
                    <li
                      key={index}
                      className={isCurrentStep === index ? "active-step" : ""}
                    ></li>
                  );
                })}
              </>
            )}
          </ul>
          <img src={CloseIcon} alt="close-icon" onClick={onClose} />
        </div>
        <div className="d-model-main">{children}</div>
      </div>
    </div>
  );
};

export default Dmodel;
