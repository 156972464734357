import React from "react";
import Banner from "./Banner";
import ChooseHeor from "./ChooseHeor";
import HowToPlay from "./HowToPlay";
// import FightGround from "./FightGround";
import LatestNews from "./LatestNews";
import PlayReverse from "./Playreverse";
import OurAlliances from "./OurAlliances";
import PlayWin from "./Playwin";
import RoadMap from "./RoadMap";
import HeroInfo from "./HeroInfo";
import "./style.css";
// import Faq from "./faq";
import Trailer from "./Trailer";

const Home = () => {
  return (
    <div id="mainwrapper" className="homepage ">
      <Banner />
      <LatestNews />
      <Trailer />
      <HowToPlay />
      <PlayWin />
      <ChooseHeor />
      <PlayReverse />
      <OurAlliances />
      <RoadMap />
      <HeroInfo />
      {/* <Faq /> */}
      {/* <FightGround /> */}
    </div>
  );
};

export default Home;
