import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../Components/Dbutton";
import "./style.css";
import DTaskCard from "../../../Components/DtaskCard";
import { WeeklyTaskList } from "../../../Constants/list";
import TaskModels from "./taskModels";
import DloginModal from "../../../Components/DloginModal";
import { useSelector } from "react-redux";
import {
  useGetUserTasksMutation,
  useUpdateUserTaskMutation,
} from "../../../services/tasks";
import { toast } from "react-toastify";
import DailyTaskModels from "../dailyTaskModels";

const DtaskScreen = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isStepsModal, setIsStepsModal] = useState(false);
  const [isCurrentModelName, setIsCurrentModelName] = useState("");
  const [isDataId, setIsDataId] = useState();
  const { user } = useSelector((state) => state.auth);
  const { tasks } = useSelector((state) => state.task);

  const [getAllTasks, { isLoading }] = useGetUserTasksMutation();
  const [patchUserTask] = useUpdateUserTaskMutation();

  const fetchUserTask = useCallback(
    async (id) => {
      try {
        await getAllTasks(id).unwrap();
      } catch (err) {
        // console.log(err);
        toast.error(err?.message);
      }
    },
    [getAllTasks]
  );

  const updateUserTask = async (taskId) => {
    const body = {
      userId: user?._id,
      taskId: taskId,
    };
    try {
      await patchUserTask(body).unwrap();
    } catch (err) {
      toast.error(err?.message);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserTask(user?._id);
    }
  }, [user, fetchUserTask]);

  const onJoin = (selectedItem) => {
    updateUserTask(selectedItem?._id);
    setIsModelOpen(false);
    window.open(selectedItem?.btnLink, "_blank");
  };
  const onCheck = (selectedItem) => {
    console.log("selectedItem", selectedItem);
  };
  const onChooseHero = (selectedItem) => {
    console.log("selectedItem", selectedItem);
    setIsModelOpen(false);
  };

  const filteredTasks = tasks?.filter((task) => !task.isActive) || [];

  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <h2>tasks</h2>
          <Button
            variant="secondary-contained"
            text="how it works"
            onClick={() => setIsStepsModal(true)}
          />
        </div>

        {filteredTasks.length === 0 && isLoading && (
          <div className="load-full">
            <div className="loader"></div>
          </div>
        )}
        {filteredTasks.length === 0 && !isLoading && (
          <div>You don't have any task yet</div>
        )}

        {user ? (
          <div className="d-task-list">
            {filteredTasks?.map((item, index) => {
              return (
                <DTaskCard
                  data={item}
                  key={index}
                  onClick={() => {
                    if (item.isHero) {
                      setIsModelOpen(true);
                      setIsCurrentModelName("steptwo");
                      setIsDataId(item.type);
                    } else {
                      console.log("mode 2 is open");
                      setIsCurrentModelName("stepone");
                      setIsModelOpen(true);
                      setIsDataId(item.type);
                    }
                  }}
                />
              );
            })}
          </div>
        ) : (
          <div className="d-task-list">
            {WeeklyTaskList.map((item, index) => {
              return (
                <DTaskCard
                  data={item}
                  key={index}
                  onClick={() => {
                    if (item.isHero) {
                      setIsModelOpen(true);
                      setIsCurrentModelName("steptwo");
                    } else {
                      setIsCurrentModelName("stepone");
                      setIsModelOpen(true);
                      setIsDataId(item.type);
                    }
                  }}
                />
              );
            })}
          </div>
        )}
      </div>

      <DailyTaskModels
        isActive={isStepsModal}
        onClose={() => setIsStepsModal(false)}
        activeModel="stepone"
      />

      {user ? (
        <TaskModels
          onClose={() => {
            setIsModelOpen(false);
            setIsDataId();
          }}
          isActive={isModelOpen}
          activeModelName={isCurrentModelName}
          type={isDataId}
          onJoin={onJoin}
          onCheck={onCheck}
          onConfirm={onChooseHero}
        />
      ) : (
        <DloginModal
          onClose={() => {
            setIsModelOpen(false);
          }}
          isActive={isModelOpen}
        />
      )}
    </>
  );
};

export default DtaskScreen;
