import React, { useState } from "react";
import "./style.css";
import SideMenu from "../../../Components/SideMenu";
const dimg = `${process.env.REACT_APP_S3_BUCKET}/Images/dimg.svg`;

const menuList = [
  {
    id: "notfound",
    name: "assets",
    icon: dimg,
    iconLight: dimg,
  },
];

const Profile = () => {
  const [activeMenu, setActiveMenu] = useState(menuList[0].name);
  return (
    <div className="profile settingsmain">
      <div className="row">
        <div className="container">
          <div className="col2">
            <h3 className="tabhead">PROFILE</h3>
            <SideMenu
              menuList={menuList}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
            />
          </div>
          <div className="col4">
            {/* assets Components here  */}
            <div className="tbbox" id="notfound">
              <h5>No results found.</h5>
              <p>
                You do not own an asset yet. Your purchased skin or NFT will be
                displayed here.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
