import { baseUrl, prepareHeaders } from "./_utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const dailyTaskApi = createApi({
  reducerPath: "dailyTaskApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getDailyTask: builder.mutation({
      query: (id) => ({
        url: `/daily-tasks/${id}`,
        method: "GET",
      }),
    }),
    getClaimDailyTask: builder.mutation({
      query: (body) => ({
        url: `/daily-tasks/claim-daily-task`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useGetDailyTaskMutation, useGetClaimDailyTaskMutation } =
  dailyTaskApi;
