import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import Button from "../../../Components/Dbutton";
import { WeeklyTaskList } from "../../../Constants/list";
import DtaskCard from "../../../Components/DtaskCard";
import CompleteModel from "./completeModel";
import DloginModal from "../../../Components/DloginModal";
import { useSelector } from "react-redux";
import { useGetUserTasksMutation } from "../../../services/tasks";
import { toast } from "react-toastify";

const DcompleteTask = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);

  const [isDataId, setIsDataId] = useState();
  const { user } = useSelector((state) => state.auth);
  const { tasks } = useSelector((state) => state.task);

  const [getAllTasks,{isLoading}] = useGetUserTasksMutation();

  const fetchUserTask = useCallback(
    async (id) => {
      try {
        await getAllTasks(id).unwrap();
      } catch (err) {
        toast.error(err?.message);
      }
    },
    [getAllTasks]
  );

  useEffect(() => {
    if (user) {
      fetchUserTask(user?._id);
    }
  }, [user, fetchUserTask]);

  const onCheck=(selectedItem)=>{
    setIsModelOpen(false);
    window.open(selectedItem?.btnLink, "_blank");
  }

  const filteredTasks =
    tasks?.filter((task) => task.isClaim && task.isCompleted) || [];

  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <h2>Complete tasks</h2>
          <Button variant="secondary-contained" text="how it works" />
        </div>

        {filteredTasks.length === 0 && isLoading && (
          <div className="load-full">
            <div className="loader"></div>
          </div>
        )}
        {filteredTasks.length === 0 && !isLoading && (
          <div>You don't have any completed tasks yet</div>
        )}

        <div className="d-task-list">
          {(user ? filteredTasks : WeeklyTaskList)?.map((item, index) => {
            return (
              <DtaskCard
                data={item}
                key={index}
                onClick={() => {
                  setIsModelOpen(true);
                  setIsDataId(item.type);
                }}
                isStatusShow
              />
            );
          })}
        </div>
      </div>
      {user ? (
        <CompleteModel
          onClose={() => {
            setIsModelOpen(false);
            setIsDataId();
          }}
          isActive={isModelOpen}
          data={tasks}
          type={isDataId}
          onCheck={onCheck}
        />
      ) : (
        <DloginModal
          onClose={() => {
            setIsModelOpen(false);
          }}
          isActive={isModelOpen}
        />
      )}
    </>
  );
};

export default DcompleteTask;
