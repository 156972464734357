import React from "react";

function IconList({ data }) {
  return (
    <ul className="socil">
      {data.map((icon, index) => (
        <li key={index}>
          <a href={icon.link} target="_blank" rel="noreferrer">
            <img src={icon.image} alt="icon" />
          </a>
        </li>
      ))}
    </ul>
  );
}

export default IconList;
