import React, { useEffect, useRef, useState } from "react";
const PlayIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/playicon.svg`;
const PauseIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/Pauseicon.svg`;

function ScreenVideo({ height, scr, poster, autoPlay, buttonText, onClick }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleVideo = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  useEffect(() => {
    if (autoPlay) {
      setIsPlaying(true);
    }
  }, [autoPlay]);
  return (
    <section className="screen-video" style={{ height: height }}>
      <video
        poster={autoPlay ? "" : poster}
        ref={videoRef}
        autoPlay
        loop
        muted
        controls={false}
        playsInline
        height="100%"
      >
        <source src={scr} type="video/mp4" />
      </video>
      {autoPlay ? (
        " "
      ) : (
        <div className="playIcon">
          <img
            src={isPlaying ? PauseIcon : PlayIcon}
            alt="PLay Icon"
            onClick={toggleVideo}
          />
        </div>
      )}
    </section>
  );
}

export default ScreenVideo;
