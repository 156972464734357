import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { General } from "../../../Constants";

const LatestNews = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    centerPadding: "60px",
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="latestnews">
      <div className="container">
        <div className="latesttop">
          <h3 className="h3head">Latest News</h3>
          <Link to="/news" className="bbtn">
            All
          </Link>
        </div>
        <div className="newsmain">
          <Slider {...settings}>
            {General.map((item, index) => {
              return (
                <div className="newsbox" key={index}>
                  <Link to={`/news/${item.id}`}>
                    <img src={item?.PostImg} alt={item?.altText} />
                    <div className="newsde">
                      <p>{item?.createdAt}</p>
                      {item?.title}
                    </div>
                  </Link>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default LatestNews;
