import React, { useEffect, useState } from "react";
import "./style.css";
import ScoreTable from "../../../Components/ScoreTable/ScoreTable";
import Loader from "../../../Components/Loader";
const searchIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/searchIcon.svg`;

const AirDropHighscores = () => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);

  useEffect(() => {
    // Fetches leader board data and join steps completed to it to render
    const fetchLeaderboardData = async () => {
      try {
        const leaderboardResult = await window.PlayFab.ClientApi.GetLeaderboard(
          {
            StatisticName: "Airdrop High Scores",
            StartPosition: (currentPage - 1) * pageSize,
            // MaxResultsCount: pageSize,
            ProfileConstraints: {
              ShowDisplayName: true,
            },
          }
        );

        // const updatedLeaderboardData = await Promise.all(
        //   leaderboardResult.data.Leaderboard.map(async (entry) => {
        //     const stepsCompleted = await fetchStepsCompleted(entry.PlayFabId);
        //     return { ...entry, stepsCompleted };
        //   })
        // );

        setLeaderboardData(leaderboardResult.data.Leaderboard);
        setSearchResult(leaderboardResult.data.Leaderboard);
        setLoading(false);
      } catch (error) {
        console.error("Error retrieving leaderboard data:", error);
        setLoading(false);
      }
    };

    fetchLeaderboardData();
  }, [currentPage, pageSize]);

  // Iterate over all the users in the leaderboard and fetches their user data to get all the steps they have completed.
  // const fetchStepsCompleted = async (userId) => {
  //   try {
  //     const userData = await window.PlayFab.ClientApi.GetUserData({
  //       PlayFabId: userId,
  //     });
  //     console.log(userData);
  //     if (userData) {
  //       // Extract steps completed from user data
  //       const stepsCompleted = JSON.parse(
  //         userData.data.Data.airdrop_stepsCompleted.Value
  //       );

  //       // Return steps completed
  //       return stepsCompleted ? stepsCompleted : "Data Not Available";
  //     }
  //   } catch (error) {
  //     console.error("Error retrieving user data:", error);
  //     return "Error";
  //   }
  // };

  const handleSearch = (e) => {
    e.preventDefault();
    // If search query is empty, return the original unfiltered data
    if (!search.trim()) {
      setSearchResult(leaderboardData);
      return;
    }

    fetch(
      "https://00zoa32f9c.execute-api.us-east-1.amazonaws.com/default/leaderBoardSearch",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: search }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setSearchResult(data.data.Leaderboard);
        } else {
          setSearchResult(leaderboardData);
        }
      })
      .catch((error) => {
        console.log(error);
        setSearchResult([]);
      });

    // Filter leaderboard data based on the search query
    // const filteredData = searchResult.filter(
    //   (entry) =>
    //     entry.DisplayName &&
    //     entry.DisplayName.toLowerCase().includes(search.toLowerCase())
    // );
    // setSearchResult(filteredData);
  };

  console.log("current page", currentPage);

  return (
    <div id="mainwrapper" className="highscores-page">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="highscores-form">
            <h2 className="h2head text-center">
              Airdrop <br />
              Highscores{" "}
            </h2>
            <form autoComplete="off">
              <label
                className={search ? "has-value search-label" : "search-label"}
              >
                <span>Search username</span>
                <input
                  type="search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  autoComplete="off"
                />
                <button
                  className="search-btn"
                  style={{ cursor: "pointer" }}
                  onClick={handleSearch}
                >
                  <img src={searchIcon} alt="link-icon" />
                </button>
              </label>
            </form>
          </div>
          <div className="table-container">
            <ScoreTable
              data={searchResult}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AirDropHighscores;
