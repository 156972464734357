/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { featuredHighLights } from "../../../Constants";
// import VideoFrame from "../../../Components/VideoFrame";
const videoThumbnail = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dashboard-pic-new.svg`;

function HowToPlay() {
  const navigate = useNavigate();
  return (
    <section className="heros-featured">
      <h2 className="h2head mb5 text-center">How to Play on Desktop</h2>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="play-image dash-bod-pic">
              <img src={videoThumbnail} alt="dashboard-img" />
              <button
                onClick={() => {
                  navigate("/dashboard");
                }}
                className="btn mt-4"
              >
                GO TO DASHBOARD
              </button>
            </div>
          </div>
          <div className="col-12">
            <ul className="high-lights">
              {featuredHighLights.map((item, index) => {
                return (
                  <li key={index}>
                    <div className="image">
                      <img src={item.image} alt={item.title} width={70} />
                    </div>
                    <div className="content">
                      <h3 className="tabhead">{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowToPlay;
