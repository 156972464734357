import React, { useState } from "react";
import Swal from "sweetalert2";
import "./style.css";
import { contactInfo } from "../../../Constants";

const Mail = `${process.env.REACT_APP_S3_BUCKET}/Images/mail.svg`;
const Telegram = `${process.env.REACT_APP_S3_BUCKET}/Images/telegram-icon.svg`;

function ContactUs() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    subject: "",
    message: "",
  });
  // const [token, setToken] = useState();
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  // const onVerify = useCallback((token) => {
  //   setToken(token);
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        "form-name": "contact",
        ...formData,
      }).toString(),
    })
      .then(() => {
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          company: "",
          message: "",
        });

        Swal.fire({
          title: "Thank you!",
          text: " Your message has been sent successfully. We will be in touch soon.",
          confirmButtonText: "CLOSE",
          confirmButtonColor: "#00b14f",
          background: "#181c1f",
          color: "#b3b3b3",
          iconName: "success",
          iconColor: "green",
        });
      })
      .catch((error) =>
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonColor: "#d0a755",
          background: "#17171a",
          color: "#b3b3b3",
          iconName: "error",
        }),
      );
  };
  return (
    <section className="contact-us">
      <div className="container">
        <h2 className="h2head mb5">Contact</h2>
        <div className="row">
          <div className="col-5">
            <div className="contact-info">
              <h3 className="tabhead">Contact Support</h3>
              <p>
                Whether you have questions, feedback, business inquiries, or
                investment interests, we're here to listen. Reach out to us
                through the appropriate channels below.
              </p>

              {contactInfo.map((item, index) => {
                return (
                  <div className="contact-detail-info" key={index}>
                    <span>{item.title}</span>
                    <p>{item.description} </p>
                    {item.text ? (
                      <div className="icon-box">
                        <img src={Telegram} alt="Telegram icon" />
                        <span>{item.text}</span>
                      </div>
                    ) : (
                      <div className="icon-box">
                        <img src={Mail} alt="Mail icon" />
                        <a href={`mailto:${item.email}`}>{item.email}</a>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-7">
            <div className="form">
              <form
                name="contact"
                method="post"
                data-netlify="true"
                onSubmit={handleSubmit}
                action="/"
                autoComplete="off"
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="contact-input">
                  <label>
                    <input
                      name="firstName"
                      type="text"
                      value={formData.firstName}
                      placeholder="First Name"
                      required
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div className="contact-input">
                  <label>
                    <input
                      name="lastName"
                      type="text"
                      value={formData.lastName}
                      placeholder="Last Name"
                      required
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <label>
                  <input
                    name="email"
                    type="email"
                    value={formData.email}
                    placeholder="Email"
                    required
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </label>
                <div className="contact-input">
                  <label>
                    <input
                      name="company"
                      type="text"
                      value={formData.company}
                      placeholder="Company (optional)"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </label>
                </div>
                <div className="contact-input">
                  <label>
                    <select
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                    >
                      <option value="Why are you reaching out?">
                        Why are you reaching out?
                      </option>
                      <option value="Investment Inquiries">
                        Investment Inquiries
                      </option>
                      <option value="Beta Sign-Up">Beta Sign-Up</option>
                      <option value="Partnerships">Partnerships</option>
                      <option value="Feedback & Suggestions">
                        Feedback & Suggestions
                      </option>
                      <option value="Technical Support">
                        Technical Support
                      </option>
                      <option value="Media & Press">Media & Press</option>
                      <option value="Other">Other</option>
                    </select>
                  </label>
                </div>
                <label>
                  <textarea
                    name="message"
                    placeholder="Your message"
                    value={formData.message}
                    onChange={handleChange}
                    autoComplete="off"
                  ></textarea>
                </label>
                <input type="submit" value="Submit" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
