import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  onlineUsersCount: 0,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setOnlineUsersCount: (state, action) => {
      state.onlineUsersCount = action.payload;
    },
  },
});

export default userSlice;

export const { setOnlineUsersCount } = userSlice.actions;
