import React from "react";
import "./style.css";
import Banner from "./banner";
// import JoinHeroDemo from "./join-hero-demo";
// import SingUp from "./sing-up";
// import HerosRewards from "./reward";
import WaysToPlay from "./ways-to-play";
import Metrices from "./metrices";
import HowToPlay from "./how-to-play";
import Telegram from "./telegram";

const JoinHeros = () => {
  return (
    <div id="mainwrapper" className="join-heros">
      <Banner />
      <WaysToPlay />
      <Metrices />
      {/* <JoinHeroDemo /> */}
      <HowToPlay />
      {/* <SingUp /> */}
      {/* <HerosRewards /> */}
      <Telegram />
    </div>
  );
};

export default JoinHeros;
