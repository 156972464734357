import React from "react";
import "./style.css";
import Banner from "./banner";
import AvoutVideo from "./about-video";
import AboutMap from "./about-map";
import AboutHeroDemo from "./about-hero-demo";
import AvoutAbilities from "./abilities";

const AboutUs = () => {
  return (
    <div id="mainwrapper" className="aboutus">
      <Banner />
      <AvoutVideo />
      <AboutMap />
      <AboutHeroDemo />
      {/* <AboutChooseHero /> */}
      <AvoutAbilities />
    </div>
  );
};

export default AboutUs;
