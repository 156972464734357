import React, { useState } from "react";
import IconTabsList from "../../../Components/IconTabs/IconTabs";
import { HeroPanel } from "../../../Constants/index";
import { HeroImgLIst } from "../../../Constants/index";

import ChoosePanel from "../../../Components/ChooseTabs/ChoosePanel";

function ChooseHeor() {
  const [active, setActive] = useState(0);
  return (
    <section className="ChooseHeor">
      <div className="container">
        <div className="hero-tabs">
          <h2 className="h2head center">Choose your HERO</h2>
          <div className="hero-tabs-list">
            <IconTabsList
              active={active}
              onClick={setActive}
              Data={HeroImgLIst}
              isLastLink
              // isTimer
              skiplast
            />
          </div>

          <ChoosePanel {...HeroPanel[active]} extraclass='custom-row-style'  />
        </div>
      </div>
    </section>
  );
}

export default ChooseHeor;
