import React, { useState } from "react";
import IconTabsList from "../IconTabs/IconTabs";
import TabsPanel from "../IconTabs/TabsPanel";
import VideoFrame from "../VideoFrame";

function ChoosePanel(item) {
  const [active, setActive] = useState(0);

  return (
    <div className={`${item?.className ? item?.className : ""}`}>
      <div className={`row ${item.extraclass}`}>
        <div className="col-6">
          {item?.image ? (
            <div className="hero-tab-img">
              <img src={item?.image} alt={item?.altText} />
            </div>
          ) : item?.imageinner ? (
            <div className="play-video img-sig">
              <img src={item?.imageinner} alt={item?.altText} />
            </div>
          ) : item.video ? (
            <VideoFrame src={item.video} isloader />
          ) : (
            ""
          )}
        </div>
        <div className="col-6">
          <div className="hero-tabs-content">
            {<h2 className="h2head">{item?.maintitle}</h2>}
            {item?.subline ? (
              <span className="subline">{item?.subline}</span>
            ) : (
              ""
            )}
            <h3 className="tabhead">{item?.tagline}</h3>
            <p>{item?.para}</p>
            {item?.descTwo ? <p>{item?.descTwo}</p> : ""}
            <h3 className="tabhead">{item?.headLine}</h3>
            <div className="tab-list">
              {item?.HeroInnerIcon ? (
                <>
                  <IconTabsList
                    active={active}
                    onClick={setActive}
                    Data={item?.HeroInnerIcon}
                  />
                </>
              ) : (
                ""
              )}

              {item?.HeroInnerPanel ? (
                <>
                  <TabsPanel {...item?.HeroInnerPanel[active]} />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChoosePanel;
