import React from "react";
import "./style.css";
const ShowCaseImg = `${process.env.REACT_APP_S3_BUCKET}/Images/showcase-img.png`;

function Innbanner({ title, desc, noBannerImage = false }) {
  return (
    <section className="inner-header">
      <div className="container">
        <h2 className="h2head center">{title}</h2>
        {desc ? <p className="center">{desc}</p> : ""}
        {!noBannerImage && (
          <div className="banner">
            <div className="detail">
              <h4>Updates, Patches & Hero Tips</h4>
              <p>
                Stay informed on the latest updates, patches, and strategies to
                enhance your gameplay. Explore in-depth guides, hero tips, and
                news directly from the development team to lead your heroes to
                victory.
              </p>
            </div>
            <div className="banner-img">
              <img src={ShowCaseImg} alt="img" />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Innbanner;
