import React from "react";
import "./style.css";
const checkSettings = `${process.env.REACT_APP_S3_BUCKET}/Images/checkSettings.svg`;
const checkSettingsLight = `${process.env.REACT_APP_S3_BUCKET}/Images/checkSettingsLight.svg`;

const SideMenu = ({
  playerIDProgress,
  profileProgress,
  walletProgress,
  twofaProgress,
  preferencesProgress,
  accountProgress,
  menuList,
  activeMenu,
}) => {
  const handleSection = (name, id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const shouldShowCheckIcon = (id) => {
    switch (id) {
      case "playerid":
        return playerIDProgress && walletProgress;
      case "profiledetail":
        return profileProgress;
      case "twofa":
        return twofaProgress;
      case "updatepreferences":
        return preferencesProgress;
      case "accountsetting":
        return accountProgress;
      default:
        return false;
    }
  };

  return (
    <div className="iconlist">
      {menuList.map((item, index) => {
        const isActive = item.id === activeMenu;
        return (
          <div
            key={index}
            className={`tabl ${isActive ? "active" : ""}`}
            onClick={() => handleSection(item?.name, item?.id)}
          >
            <div className="menu">
              <img
                src={isActive ? item.icon : item.iconLight}
                alt="check-icon"
              />
              {item.name}
            </div>
            {item.id !== "sessionsetting" && (
              <img
                className="check-icon"
                src={
                  shouldShowCheckIcon(item.id)
                    ? checkSettings
                    : checkSettingsLight
                }
                alt="check-icon"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SideMenu;
