import { useEffect } from "react";
import "./style.css";

const CloseIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/close-icon.svg`;
const CheckIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/tick-circle.svg`;

const Dtoast = ({ setIsSuccess, isSuccess, message }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [setIsSuccess, isSuccess]);

  if (!isSuccess) return null;

  return (
    <div className="reward-modal">
      <div>
        <img src={CheckIcon} alt="check-icon" className="reward-check-icon" />
        <p>{message}</p>
      </div>
      <img
        src={CloseIcon}
        alt="close-icon"
        className="reward-close-icon"
        onClick={() => setIsSuccess(false)}
      />
    </div>
  );
};

export default Dtoast;
