/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "../Modal";
const closeButton = `${process.env.REACT_APP_S3_BUCKET}/Images/closeIcon.svg`;

const option = {
  autoClose: 2000,
  closeButton: closeButton,
  closeOnClick: true,
  type: toast.TYPE.SUCCESS,
  hideProgressBar: true,
  position: "top-center",
  style: { border: "1px solid #d0a755" },
};

const UpdatePreferences = ({ userData, setPreferencesProgress }) => {
  const [checked, setChecked] = useState(
    userData.subscribe ? userData.subscribe.Value === "true" : false
  );
  const [error, setError] = useState();

  useEffect(() => {
    if (checked) {
      setPreferencesProgress(checked);
    }
  }, []);

  const handleSubscriptionModal = () => {
    toast(
      <Modal
        message="Guild of Heroes subscription updated successfully."
        action=""
        handler={toast.dismiss()}
        btnText=""
      />,
      option
    );
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    var updateUserDataRequest = {
      Data: {
        subscribe: checked,
      },
    };

    window.PlayFab.ClientApi.UpdateUserData(
      updateUserDataRequest,
      (res, error) => {
        if (res) {
          setPreferencesProgress(checked);
          handleSubscriptionModal();
        } else {
          console.log("Error subscribing:", error);
          setError(error);
        }
      }
    );
  };

  return (
    <div className="tbbox updatepreferences" id="updatepreferences">
      <h5>Update Preferences</h5>
      <form className="input-container" autoComplete="off">
        <div className="check-box">
          <input
            type="checkbox"
            id="Stay"
            name="Stay"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            autoComplete="off"
          />
          <label htmlFor="Stay">
            {" "}
            Opt into receiving the latest news and special announcements,
            including exclusive offers and event invitations from the game.
          </label>
        </div>
        {error && (
          <p style={{ color: "red" }}>
            Error toggling Two Factor Authentication. Please try again.
          </p>
        )}
        <button
          style={{ marginBottom: "20px" }}
          className="btn update-btn"
          onClick={handleSubscribe}
        >
          update
        </button>
      </form>
    </div>
  );
};

export default UpdatePreferences;
