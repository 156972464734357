import Joi from "joi";

export const formSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "Invalid email format.",
      "string.empty": "Email is required.",
      "any.required": "Email is required.",
    }),
  dob: Joi.date().max("now").messages({
    "date.base": "Invalid date format.",
    "date.max": "Date of birth cannot be in the future.",
  }),
  username: Joi.string().alphanum().min(3).max(30).required().messages({
    "string.alphanum": "Username must contain only alphanumeric characters.",
    "string.empty": "Username is required.",
    "string.min": "Username must be at least 3 characters long.",
    "string.max": "Username must be at most 30 characters long.",
    "any.required": "Username is required.",
  }),
  password: Joi.string().required().messages({
    "string.empty": "Password cannot be empty.",
    "any.required": "Password is required.",
  }),
  confirmPass: Joi.any().valid(Joi.ref("password")).required().messages({
    "any.only": "Confirm password must match the password.",
    "any.required": "Confirm password is required.",
  }),
});

export const forgotUsernameformSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "Invalid email format.",
      "string.empty": "Email is required.",
      "any.required": "Email is required.",
    }),
});
export const forgotPasswordformSchema = Joi.object({
  username: Joi.string().alphanum().min(3).max(30).required().messages({
    "string.empty": "Username is required.",
    "any.required": "Username is required.",
  }),
});

export const playerIdformSchema = Joi.object({
  gameName: Joi.string().alphanum().min(3).max(30).required().messages({
    "string.empty": "Game name is required.",
    "any.required": "Game name is required.",
    "string.min": "Game name must be at least 3 characters long.",
    "string.max": "Game name must be at most 30 characters long.",
  }),
  // tagline: Joi.string(),
});

export const emailValidation = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "Invalid email format.",
      "string.empty": "Email is required.",
      "any.required": "Email is required.",
    }),
});

export const profileDetailformSchema = Joi.object({
  country: Joi.string(),
  dob: Joi.date().max("now").messages({
    "date.base": "Invalid date format.",
    "date.max": "Date of birth cannot be in the future.",
  }),
});
