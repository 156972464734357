import { useEffect, useState } from "react";
import DModel from "../../../Components/Dmodel";
import { useGetUserStatsMutation } from "../../../services/userStats";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { formatText } from "../../../utils/format";

const DtableModel = ({ player, isActive, onClose }) => {
  const { userStats } = useSelector((state) => state.userStats);
  const [getUserStats, { isLoading }] = useGetUserStatsMutation();
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const kills = userStats?.find(
    (item) => item?.eventType === "TotalPlayerKills"
  );
  const deaths = userStats?.find((item) => item?.eventType === "Deaths");
  const gamesWon = userStats?.find(
    (item) => item?.eventType === "NumOfGamesWon"
  );
  const gamesLost = userStats?.find(
    (item) => item?.eventType === "NumOfGamesLost"
  );
  const towerDestroyed = userStats?.find(
    (item) => item?.eventType === "NumOfTowersDestroyed"
  );
  const npcKills = userStats?.find(
    (item) => item?.eventType === "TotalNpcKills"
  );

  const mock = [
    {
      eventType: "Rank",
      count: player?.rank || "0",
    },
    {
      eventType: "Kills",
      count: kills?.count || "0",
    },
    {
      eventType: "Deaths",
      count: deaths?.count || "0",
    },
    {
      eventType: "Games Won",
      count: gamesWon?.count || "0",
    },
    {
      eventType: "Games Lost",
      count: gamesLost?.count || "0",
    },
    {
      eventType: "Towers Destroyed",
      count: towerDestroyed?.count || "0",
    },
    {
      eventType: "NPC Kills",
      count: npcKills?.count || "0",
    },
    {
      eventType: "Total Gold",
      count: "0",
    },

    {
      eventType: "Referrals",
      count: "0",
    },
  ];

  console.log("player", player);
  useEffect(() => {
    if (player?.playFabId) {
      try {
        getUserStats(player.playFabId).unwrap();
        setSelectedPlayer(player);
      } catch (err) {
        toast.error(err?.message);
      }
    }
  }, [player, getUserStats]);


  return (
    <>
      <DModel
        onClose={() => {
          onClose();
        }}
        isActive={isActive}
        extraClasses="high-modal"
      >
        <div className="d-model-text ">
          <div className="d-modal-img-container">
            <img src={selectedPlayer?.avatarUrl} className="d-modal-img" alt="img" />
            <h3>{selectedPlayer?.displayName || selectedPlayer?.userName}</h3>
          </div>
          <div className="table-list">
            <ul>
              {isLoading && (
                <div
                  className="load-full"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div className="loader"></div>
                </div>
              )}
              {!isLoading &&
                mock.map((item, ind) => {
                  return (
                    <li key={ind}>
                      <span>{formatText(item.eventType)}</span>
                      <span>{item.count}</span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </DModel>
    </>
  );
};

export default DtableModel;
