import React, { useState, useRef, useEffect } from "react";
import Maps from "../Maps/Maps";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const PlayIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/playicon.svg`;
const PauseIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/Pauseicon.svg`;

function Explore({
  title,
  tagline,
  desc,
  video,
  image,
  poster,
  altText,
  className,
  desctwo,
  backgroundImg,
  autoPlay,
  buttonText,
  onClick,
}) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const toggleVideo = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  useEffect(() => {
    if (autoPlay) {
      setIsPlaying(true);
    }
  }, [autoPlay]);

  return (
    <section
      className={`playwin ${className ? className : ""}`}
      style={{ backgroundImage: backgroundImg }}
    >
      <div className="container">
        <div className="row">
          <div className="col-7">
            {video ? (
              <div className="play-video">
                <video
                  poster={autoPlay ? "" : poster}
                  ref={videoRef}
                  autoPlay
                  loop
                  muted
                  controls={false}
                  playsInline
                >
                  <source src={video} type="video/mp4" />
                </video>
                {autoPlay ? (
                  " "
                ) : (
                  <div className="playIcon">
                    <img
                      src={isPlaying ? PauseIcon : PlayIcon}
                      alt="PLay Icon"
                      onClick={toggleVideo}
                    />
                  </div>
                )}
              </div>
            ) : title === "Strategy Overview" ? (
              <div className="play-image">
                <Maps />
              </div>
            ) : (
              <div className="play-image">
                <img src={image} alt="" />
              </div>
            )}
          </div>
          <div className="col-5">
            <div className="play-content">
              <h2 className="h2head mb5">{title}</h2>
              <h3 className="tabhead">{tagline}</h3>
              <p>{desc}</p>
              {desctwo ? <p>{desctwo}</p> : ""}
              {buttonText && (
                <Link
                  className="btn"
                  to={`${!user ? "/about-us" : "/about-us"}`}
                >
                  {buttonText}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Explore;
