import React from "react";
import { useNavigate } from "react-router-dom";
const GohBg = `${process.env.REACT_APP_S3_BUCKET}/Images/explore-bg.png`;
const windowIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/window-icon.png`;
const dashboardIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/dahboard-icon.png`;
const telegramIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/telegram-icon.png`;

const WaysToPlay = () => {
  const navigate = useNavigate();

  const data = [
    {
      icon: windowIcon,
      title: "Windows Pc",
      description:
        "Play the game on your Windows computer, level up your account, earn as much GOH COINS as possible, and invite new players to earn referral rewards.",
      buttonText: "Play on pc",
      onClick: () => navigate("/download"),
    },
    {
      icon: dashboardIcon,
      title: "Task Dashboard",
      description:
        "Access the game from your Task Dashboard to complete tasks seamlessly and track your progress. This option syncs with the Telegram mini app, allowing you to perform and monitor tasks on both platforms.",
      buttonText: "Enter dashboard",
      onClick: () => navigate("/dashboard"),
    },
    {
      icon: telegramIcon,
      title: "Telegram Mini App",
      description:
        "Play the game on your Windows computer, level up your account, earn as much When you’re away from your computer or if you don’t have a Windows PC, complete tasks on our Telegram mini app to keep earning GOH COINS.",
      buttonText: "Play now on telegram",
      onClick: () => {},
    },
  ];
  return (
    <div className="bg-img" style={{ backgroundImage: `url(${GohBg})` }}>
      <div className="container">
        <h2 className="h2head text-center">Ways to Play</h2>
        <div className="ways-wrapper">
          {data.map((item, index) => (
            <div className="ways-Item w-30" key={index}>
              <img src={item.icon} width={60} alt="img-icon" />
              <h4>{item.title}</h4>
              <p>{item.description}</p>
              <button className="btn" onClick={item.onClick}>
                {item.buttonText}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WaysToPlay;
