import React from "react";
import "./style.css";

const Download = () => {
  return (
    <div id="mainwrapper" className="download-page">
      <h2 style={{ fontSize: 25 }} className="h2head mb5 top-heading">
        Prepare for Adventure 
      </h2>
      <h2 className="h2head mb5">Coming Soon!</h2>
      <p>
        Guild of Heroes is almost ready to welcome you into the enchanting realm
        of Arcanterra. Our epic world of magic and valor is currently in the
        final stages of polish to ensure the best experience for our heroes.{" "}
        <br />
        <br />
        The downloads for Windows and Mac will be available shortly. Stay tuned
        for the official release and get ready to forge your legacy alongside
        thousands of other heroes.
      </p>

      {/* <h2 className="h2head mb5">
        play <br />
        guild of heroes
      </h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam nunc
        felis, pellentesque non euismod ut, ornare quis ante.
      </p>
      <div className="btn-cont">
        <Link to={"#"} className="btn">
          <img alt="windows-icon" src={windowIcon} />
          Download for windows
        </Link>
        <Link to={"#"} className="btn">
          <img alt="apple-icon" src={appleIcon} />
          Download for mac
        </Link>
      </div> */}
    </div>
  );
};

export default Download;
