import React from "react";

const Trailer = () => {
  return (
    <section className="trailer">
      <div className="container trailer-container">
        <div className="row">
          <div className="col-12">
            <h2 className="h2head">Trailer</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <iframe
              width="100%"
              height="550px"
              src="https://player.vimeo.com/video/1000477344?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              style={{ border: 0 }}
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Trailer;
