/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import countryList from "react-select-country-list";
import { toast } from "react-toastify";
import Modal from "../Modal";
import { profileDetailformSchema } from "../../utils/validationSchema";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const closeButton = `${process.env.REACT_APP_S3_BUCKET}/Images/closeIcon.svg`;

const option = {
  autoClose: false,
  closeButton: closeButton,
  closeOnClick: false,
  type: toast.TYPE.WARNING,
  hideProgressBar: true,
  position: "top-center",
  style: { border: "1px solid #d0a755" },
};

const options2 = {
  autoClose: 2000,
  closeButton: closeButton,
  closeOnClick: true,
  type: toast.TYPE.SUCCESS,
  hideProgressBar: true,
  position: "top-center",
  style: { border: "1px solid #d0a755" },
};

const ProfileDetail = ({ userData, setProfileProgress }) => {
  const { playFabInfo } = useSelector((state) => state.auth);
  const [email] = useState(
    playFabInfo?.AccountInfo?.GoogleInfo
      ? playFabInfo?.AccountInfo?.GoogleInfo?.GoogleEmail
      : playFabInfo?.AccountInfo?.FacebookInfo
      ? playFabInfo?.AccountInfo?.FacebookInfo?.FacebookId
      : playFabInfo?.AccountInfo?.PrivateInfo?.Email
  );
  const [form, setForm] = useState({
    country: userData?.country?.Value,
    dob: userData?.birthday?.Value,
  });
  const [error, setError] = useState({});
  const [isFocused, setIsFocused] = useState(false);
  const options = useMemo(() => countryList().getData(), []);

  const handleFocus = () => {
    setIsFocused(true);
  };

  // THis function is used to mask the email to it appears as ***df***@u***
  const maskedEmail = (email) => {
    if (!email.includes("@")) {
      return email;
    }
    const [localPart, domainPart] = email.split("@");

    const maskedLocalPart =
      localPart.slice(0, 3) +
      "*".repeat(Math.max(0, localPart.length - 3)) +
      "@"; // Include "@" symbol in the masked part

    return maskedLocalPart + domainPart;
  };

  const handleOthers = (e) => {
    e.preventDefault();
    const err = fieldValidations();
    if (!err) {
      toast(
        <Modal
          message="You are about to change your country and/or date of birth."
          action="Are you sure you want to proceed?"
          handler={handler_country}
          btnText="Proceed"
        />,
        option
      );
    }
  };

  const handler_country = () => {
    var updateUserDataRequest = {
      Data: {
        country: form.country,
        birthday: form.dob,
      },
    };

    if (error.country || error.dob) {
      toast.dismiss();
      return;
    }

    window.PlayFab.ClientApi.UpdateUserData(
      updateUserDataRequest,
      (res, error) => {
        if (res) {
          setProfileProgress(true);
          toast.dismiss();
          toast(
            <Modal
              message={"Country and Date of birth updated!"}
              action=""
              handler={toast.dismiss()}
              btnText=""
            />,
            options2
          );
        } else {
          console.log("Error registering user's birhtday:", error);
        }
      }
    );
  };

  const fieldValidations = () => {
    const { error } = profileDetailformSchema.validate(form, {
      abortEarly: false,
    });

    if (error) {
      const newErrors = error.details.reduce((acc, currentError) => {
        acc[currentError.path[0]] = currentError.message;
        return acc;
      }, {});

      setError(newErrors);
      return newErrors;
    } else {
      setError({});
    }
  };

  // handle change in date
  const handleDateChange = (date) => {
    setForm({
      ...form,
      dob: date ? date.format("YYYY-MM-DD") : "",
    });
  };

  // Validate date picker
  const calculateMaxDate = () => {
    return dayjs().subtract(13, "year");
  };

  useEffect(() => {
    if (form.country && form.dob) {
      setProfileProgress(true);
    }
  }, []);

  return (
    <div className="tbbox profiledetail" id="profiledetail">
      <h5>Profile Details</h5>
      <p>
        Your personal information is kept private and never shared outside the
        gaming environment.
      </p>
      <form className="input-container" autoComplete="off">
        <label className={email ? `has-value disabled` : `disabled`}>
          <span>Email Address</span>
          <input
            disabled={true}
            type="text"
            value={maskedEmail(email)}
            // onChange={(e) => setEmail(e.target.value)}
            autoComplete="off"
            className={error.email ? "input-error" : ""}
          />
        </label>
        <div className="two-inputs">
          <label
            className={form.country ? `has-value select-label` : `select-label`}
          >
            <span>Country / Region</span>
            <select
              name="country"
              value={form.country}
              className="country-select"
              onChange={(e) =>
                setForm({
                  ...form,
                  country: e.target.value,
                })
              }
            >
              <option value="Country/Region"></option>
              {options.map((country, index) => {
                return (
                  <option key={index} value={country.label}>
                    {country.label}
                  </option>
                );
              })}
            </select>
          </label>
          <div className="date-input-container" onFocus={handleFocus}>
            {!isFocused && !form.dob ? (
              <label className={form.dob ? "has-value" : ""}>
                <span>Birthday</span>
                <input
                  type="text"
                  autoComplete="off"
                  className={error.dob ? "input-error" : ""}
                />
              </label>
            ) : (
              <div className="dateicker">
                <label className={"date-input has-value"}>
                  {isFocused ? (
                    <span>Date of birth</span>
                  ) : (
                    <span>Date of birth</span>
                  )}
                </label>
                <Space direction="vertical" className="w-full">
                  <DatePicker
                    size="large"
                    className="w-full"
                    value={form.dob ? dayjs(form.dob, "YYYY-MM-DD") : ""}
                    onChange={(e) => handleDateChange(e)}
                    maxDate={calculateMaxDate()}
                    variant="borderless"
                    suffixIcon={null}
                    style={{ paddingTop: 8, color: "#ede5ce", width: "100%" }}
                    allowClear={false}
                  />
                </Space>
                {error?.dob && <div className="error-message">{error.dob}</div>}
              </div>
            )}
          </div>
          {/* <div className="date-input-container" onFocus={handleFocus}>
            {!isFocused && !form.dob ? (
              <label className={form.dob ? "has-value" : ""}>
                <span>Birthday</span>
                <input
                  type="text"
                  autoComplete="off"
                  className={error.dob ? "input-error" : ""}
                />
              </label>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <label className={"date-input has-value"}>
                  {isFocused ? (
                    <span>Date of birth</span>
                  ) : (
                    <span>Date of birth</span>
                  )}
                  <input
                    type="date"
                    name="dob"
                    id="dob"
                    value={form.dob}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        dob: e.target.value,
                      })
                    }
                    autoComplete="off"
                    className={error.dob ? "input-error" : ""}
                  />
                </label>
                {error?.dob && <div className="error-message">{error.dob}</div>}
              </div>
            )}
          </div> */}
        </div>
      </form>
      <button
        style={{ marginBottom: "20px" }}
        className="btn update-btn"
        onClick={handleOthers}
      >
        Update
      </button>
    </div>
  );
};

export default ProfileDetail;
