import React, { useEffect, useRef } from "react";
import NavLink from "../../Components/Dlinks";
import Button from "../../Components/Dbutton";
import Notification from "../../Components/DnotificationIcon";
import DProfile from "../../Components/DprofileCard";
import CountUpAnimation from "../../Components/CountUpAnimation";
import { useDispatch, useSelector } from "react-redux";
import {
  connectSocket,
  disconnectSocket,
  subscribeToEvens,
  subscribeToNotifications,
  subscribeToOnlineUsers,
} from "../../services/socket";
import { setOnlineUsersCount } from "../../store/slices/userSlice";
import { setEvents } from "../../store/slices/eventSlice";
const PlayIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/play-icon.svg`;
// const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;
// const Logo = `${process.env.REACT_APP_S3_BUCKET}/Images/iconlogo-Dark.svg`;
const AvtorImag = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/user-avtor.svg`;
const ToggleIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/toggle.svg`;
const ProfileIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/profile-icon.svg`;

const Dheader = ({ onOpenSiderbar, active }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { onlineUsersCount } = useSelector((state) => state.users);
  const { events } = useSelector((state) => state.events);
  const socketRef = useRef(null);

  useEffect(() => {
    if (user) {
      if (!socketRef.current) {
        socketRef.current = connectSocket(user?.playFabId);
        subscribeToOnlineUsers((users) => {
          dispatch(setOnlineUsersCount(users));
        });

        subscribeToEvens((newEvent) => {
          console.log("newEvent===>", newEvent);
          dispatch(setEvents([newEvent, ...events]));
        });

        subscribeToNotifications((notification) => {
          console.log("notification===>", notification);
        });
      }
    }

    // Clean up socket connection on unmount
    return () => {
      if (socketRef.current) {
        disconnectSocket();
        socketRef.current = null;
      }
    };
  }, [dispatch, events, user]);

  const Gamedata = {
    image: AvtorImag,
    status: "In game",
    count: onlineUsersCount,
  };

  return (
    <div className="d-header">
      <div className="flex">
        <div className="d-toggle-menu">
          <img
            src={ToggleIcon}
            className={active ? "active" : ""}
            onClick={onOpenSiderbar}
            alt="Toggle Icon"
          />
        </div>
        <NavLink text="Back to site" link="/" leftIcon />
      </div>
      <div className="flex-head">
        <div class="rakec">
          <img src={ProfileIcon} alt="profile-icon" />
          <div class="lerak">
            <p>GOH COINS EARNED</p>
            <span>
              <CountUpAnimation
                startCount={user?.gohCoins}
                amount={user?.gohCoins || "--"}
              />{" "}
            </span>
          </div>
        </div>
        <Notification />
        <DProfile data={Gamedata} extraClasses="d-fit-w" />
        <Button
          variant="primary-contained"
          text="Play game"
          icon={PlayIcon}
          disabled={!user ? true : false}
        />
      </div>
    </div>
  );
};

export default Dheader;
