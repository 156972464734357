import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
// const ErrorImg = `${process.env.REACT_APP_S3_BUCKET}/Images/error.png`;
const logo = `${process.env.REACT_APP_S3_BUCKET}/Images/logo_horizontal_dark.svg`;
const logoIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/iconlogo-Dark.svg`;

function Error() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }, [isSmallScreen]);

  return (
    <>
      <header
        className="head"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="leftdiv">
          <Link to="/">
            <img src={isSmallScreen ? logoIcon : logo} alt="logo" />
          </Link>
        </div>
      </header>

      {/* sds */}
      <section className="error-page">
        <div className="error-detail">
          <div className="error-content">
            <h2 className="h2head">Page not found</h2>
            <h3>Error 404</h3>
            <p>
              The page that you are trying to access is either outdated,
              inaccurate, or doesn’t exist.
            </p>
            <Link to="/">BACK TO HOMEPAGE</Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default Error;
