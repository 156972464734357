import io from "socket.io-client";

let socket;

export const connectSocket = (userId) => {
  socket = io(`https://api.guildofheroes.com`, {
    transports: ["websocket"],
  });

  socket.emit("userJoined", userId);

  return socket;
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

export const subscribeToOnlineUsers = (callback) => {
  socket.on("onlineUsers", callback);
};

export const subscribeToEvens = (callback) => {
  socket.on("events", callback);
};

export const subscribeToNotifications = (callback) => {
  socket.on("notifications", callback);
};