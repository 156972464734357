import { baseUrl, prepareHeaders } from "./_utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const taskApi = createApi({
  reducerPath: "taskApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getUserTasks: builder.mutation({
      query: (id) => ({
        url: `/tasks/${id}`,
        method: "GET",
      }),
    }),
    claimUserTask: builder.mutation({
      query: (body) => ({
        url: `/tasks/claim-task`,
        method: "PATCH",
        body: body,
      }),
    }),
    updateUserTask: builder.mutation({
      query: (body) => ({
        url: `/tasks/update-task`,
        method: "PATCH",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetUserTasksMutation,
  useClaimUserTaskMutation,
  useUpdateUserTaskMutation,
} = taskApi;
