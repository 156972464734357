import { createSlice } from "@reduxjs/toolkit";
import { highscroesApi } from "../../services/highscroes";

const initialState = {
  highscroes: [],
  totalCount: 0,
};

const highscroesSlice = createSlice({
  name: "highscroes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      highscroesApi.endpoints.getHighscroes.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        const currentPage = data?.meta?.page;
        const pageSize = data?.meta?.limit;
        const startFrom = (currentPage - 1) * pageSize + 1;
        state.highscroes = data.users?.map((user, index) => ({
          ...user,
          rank: startFrom + index,
        }));
        state.totalCount = data.totalUsers;
      }
    );
  },
});

export default highscroesSlice;
