import React, { useState } from "react";
import IconTabsList from "../../../Components/IconTabs/IconTabs";
import { tabIcon } from "../../../Constants/index";
import { Emenrge } from "../../../Constants/index";
import TabsPanel from "../../../Components/IconTabs/TabsPanel";

function HowToPlay() {
  const [active, setActive] = useState(0);
  return (
    <section className="emerge">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="emerg-content">
              <h2 className="h2head">How to Play</h2>
              <div className="tab-list">
                <IconTabsList
                  active={active}
                  onClick={setActive}
                  Data={tabIcon}
                />
                <TabsPanel {...Emenrge[active]} />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="emerg-image">
              {/* <img className="emerg-main-img" src={EmergMainImg} alt="Laptop" /> */}
              {/* <img className="emerg-foot-img" src={EmergMobileImg} alt="Mobile" /> */}

              <iframe
                width="100%"
                height="400px"
                src="https://player.vimeo.com/video/1000471676?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&muted=1&loop=1"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{ border: 0 }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowToPlay;
