import { baseUrl, prepareHeaders } from "./_utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const highscroesApi = createApi({
  reducerPath: "highscroesApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getHighscroes: builder.mutation({
      query: (params) => {
        return {
          url: `/users`,
          method: "GET",
          params: params,
        };
      },
    }),
  }),
});

export const { useGetHighscroesMutation } = highscroesApi;
