import React from "react";
import { Routes as BrowserRoutes, Route } from "react-router-dom";
import LandingLayout from "../Layout/Landing";
import Home from "../screens/Landing/Home";
import Heroes from "../screens/Landing/Heroes";
import AboutUs from "../screens/Landing/about-us";
import PostList from "../screens/Landing/Posts";
import SinglePost from "../screens/Landing/SinglePost";
import SingleHero from "../screens/Landing/HeroSingle";
import ContactUs from "../screens/Landing/Contact-us";
import Privacy from "../screens/Landing/privacy";
import Media from "../screens/Landing/Media";
import ClosedBetaDownload from "../screens/Landing/ClosedBetaDownload";
import Profile from "../screens/Landing/Profile";
import ProtectedRoutes from "../utils/ProtectedRoutes";
import Wallet from "../screens/Landing/Wallet";
import Settings from "../screens/Landing/Settings";
import AirDropHighscores from "../screens/Landing/AirDropHighscores";
import AirdropGuide from "../screens/Landing/AirdropGuide";
import MarketPlace from "../screens/Landing/Marketplace";
import CookiePolicy from "../screens/Landing/CookiePolicy";
import JoinHeros from "../screens/Landing/joinHeros";
import SecureSpot from "../screens/Landing/SecureSpot";
import Error from "../screens/Landing/404Error/error";
import TermsOfServices from "../screens/Landing/termsOfServices";
import Download from "../screens/Landing/Download";
import DashboardLayout from "../Layout/Dashboard";
import DailyTask from "../screens/Dashboard/dailyTask";
import DHome from "../screens/Dashboard/home";
import DTaskScreen from "../screens/Dashboard/task";
import DactiveTask from "../screens/Dashboard/activeTask";
import DcompleteTask from "../screens/Dashboard/completeTask";
import DCombatTask from "../screens/Dashboard/CombatTask";
import DairDrop from "../screens/Dashboard/airdrop";
import DHighscores from "../screens/Dashboard/DhighScores";

const Routes = (props) => {
  const { active, handleClick } = props;
  return (
    <BrowserRoutes>
      <Route
        path="/"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <Home />
          </LandingLayout>
        }
      />
      <Route
        path="/heroes"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <Heroes />
          </LandingLayout>
        }
      />
      <Route
        path="/about-us"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <AboutUs />
          </LandingLayout>
        }
      />
      <Route
        path="/news"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <PostList />
          </LandingLayout>
        }
      />
      <Route
        path="/news/:id"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <SinglePost />
          </LandingLayout>
        }
      />
      <Route
        path="/heroes/:hero"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <SingleHero />
          </LandingLayout>
        }
      />
      <Route
        path="/contact-us"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <ContactUs />
          </LandingLayout>
        }
      />
      <Route
        path="/privacy"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <Privacy />
          </LandingLayout>
        }
      />
      <Route
        path="/branding"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <Media />
          </LandingLayout>
        }
      />
      <Route
        path="/closed-beta-download"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <ClosedBetaDownload />
          </LandingLayout>
        }
      />
      <Route
        path="/download"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <Download />
          </LandingLayout>
        }
      />
      {/* Protected Routes Here  */}
      <Route element={<ProtectedRoutes />}>
        <Route
          path="/profile"
          element={
            <LandingLayout active={active} handleClick={handleClick}>
              <Profile />
            </LandingLayout>
          }
        />
        <Route
          path="/wallet"
          element={
            <LandingLayout active={active} handleClick={handleClick}>
              <Wallet />
            </LandingLayout>
          }
        />

        <Route
          path="/settings"
          element={
            <LandingLayout active={active} handleClick={handleClick}>
              <Settings />
            </LandingLayout>
          }
        />
        <Route
          path="/airdrop-highscores"
          element={
            <LandingLayout active={active} handleClick={handleClick}>
              <AirDropHighscores />
            </LandingLayout>
          }
        />
        <Route
          path="/airdrop-guide"
          element={
            <LandingLayout active={active} handleClick={handleClick}>
              <AirdropGuide />
            </LandingLayout>
          }
        />
      </Route>
      <Route
        path="/market-place"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <MarketPlace />
          </LandingLayout>
        }
      />
      <Route
        path="/termsOfServices"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <TermsOfServices />
          </LandingLayout>
        }
      />
      <Route
        path="/cookiepolicy"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <CookiePolicy />
          </LandingLayout>
        }
      />
      <Route
        path="/beta"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <JoinHeros />
          </LandingLayout>
        }
      />
      <Route
        path="/secure-your-spot"
        element={
          <LandingLayout active={active} handleClick={handleClick}>
            <SecureSpot />
          </LandingLayout>
        }
      />
      <Route
        path="/dashboard"
        element={
          <DashboardLayout>
            <DHome />
          </DashboardLayout>
        }
      />
      <Route
        path="/daily-task"
        element={
          <DashboardLayout>
            <DailyTask />
          </DashboardLayout>
        }
      />
      <Route
        path="/tasks"
        element={
          <DashboardLayout>
            <DTaskScreen />
          </DashboardLayout>
        }
      />
      <Route
        path="/active-task"
        element={
          <DashboardLayout>
            <DactiveTask />
          </DashboardLayout>
        }
      />
      <Route
        path="/completed-tasks"
        element={
          <DashboardLayout>
            <DcompleteTask />
          </DashboardLayout>
        }
      />
      <Route
        path="/combat-earnings"
        element={
          <DashboardLayout>
            <DCombatTask />
          </DashboardLayout>
        }
      />
      <Route
        path="/highscores"
        element={
          <DashboardLayout>
            <DHighscores />
          </DashboardLayout>
        }
      />
      <Route
        path="/airdrop"
        element={
          <DashboardLayout>
            <DairDrop />
          </DashboardLayout>
        }
      />
      <Route path="/*" element={<Error />} />
    </BrowserRoutes>
  );
};

export default Routes;
