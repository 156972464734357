/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { SingleProductCard } from "../../../Constants";
import "./style.css";

import IconTabsList from "../../../Components/IconTabs/IconTabs";
import ChoosePanel from "../../../Components/ChooseTabs/ChoosePanel";
const ArrowLeft = `${process.env.REACT_APP_S3_BUCKET}/Images/arrowl.svg`;
const ArrowRight = `${process.env.REACT_APP_S3_BUCKET}/Images/arrowr.svg`;

function SingleHero() {
  const [active, setActive] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const { pathname } = useLocation();
  const [hero, setHero] = useState({});
  const [activeItem, setActiveItem] = useState(
    hero?.SingleHeroIcons?.[active].content
  );

  const navigate = useNavigate();
  const path = pathname.split("/");
  const heroName = path[path.length - 1];

  useEffect(() => {
    const index = SingleProductCard.findIndex((item) => {
      return item.title.toLowerCase() === heroName;
    });
    const foundItem = index !== -1 ? SingleProductCard[index] : null;
    setActiveIndex(index);
    setHero(foundItem);
  }, []);

  useEffect(() => {
    setActiveItem(hero?.SingleHeroIcons?.[active].content);
  }, [active, hero, pathname]);

  const handlePrev = () => {
    if (activeIndex > 0) {
      setActiveIndex((prev) => prev - 1);
      setHero(SingleProductCard[activeIndex - 1]);
      navigate(
        `/heroes/${SingleProductCard[activeIndex - 1].title.toLowerCase()}`
      );
    }
  };
  const handleNext = () => {
    if (SingleProductCard.length - 1 > activeIndex) {
      setActiveIndex((prev) => prev + 1);
      setHero(SingleProductCard[activeIndex + 1]);
      navigate(
        `/heroes/${SingleProductCard[activeIndex + 1].title.toLowerCase()}`
      );
    }
  };

  if (Object.entries(hero).length === 0) {
    return null;
  }

  return (
    <div id="mainwrapper" className="heroes-single">
      <section
        className="Hero-single"
        style={{
          backgroundImage: `url(${hero?.bgImage}),linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
        }}
      >
        <div className="container">
          <div className="banner-content">
            <div className="links-button show-on-web">
              <div className="backbtn" onClick={handlePrev}>
                <img src={ArrowLeft} alt="Prev-btn" />
              </div>
              <Link to="/heroes" className="backbtn" style={{ flex: 2 }}>
                All
              </Link>
              <div className="backbtn" onClick={handleNext}>
                <img src={ArrowRight} alt="Next-btn" />
              </div>
            </div>
            <div className="single-detail">
              <h2>{hero?.title}</h2>
              <h5>{hero?.subtitle}</h5>
              <p>{hero?.description}</p>
            </div>
          </div>
          <div className="charactor-img">
            <img src={hero?.charactorImage} alt="charactor" />
          </div>
        </div>
      </section>
      <section className="single-tabs">
        <div className="container">
          <h2 className="h4head">{hero?.abilities}</h2>

          <div className="single-tab-list">
            <div className="tab-list">
              <IconTabsList
                active={active}
                onClick={setActive}
                Data={hero?.SingleHeroIcons}
              />
              {hero && activeItem && (
                <div className="single-detail pt-4">
                  <h5>{activeItem.title}</h5>
                  <p>{activeItem.description}</p>
                </div>
              )}
            </div>
            <div style={{ flex: 1 }}>
              {hero && hero?.SingleHeroIcons[active].content && (
                <ChoosePanel {...activeItem} />
              )}
            </div>
            <div className="links-button show-on-mobile">
              <div className="backbtn" onClick={handlePrev}>
                <img src={ArrowLeft} alt="Prev-btn" />
              </div>
              <Link to="/heroes" className="backbtn" style={{ flex: 2 }}>
                All
              </Link>
              <div className="backbtn" onClick={handleNext}>
                <img src={ArrowRight} alt="Next-btn" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SingleHero;
