import React, { useEffect, useRef, useState } from "react";
import "./style.css";

const Maps = () => {
  const canvasRef = useRef(null);
  const animContRef = useRef(null);
  const overlayRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const popupRef = useRef(null);

  const [selectedPlaceDetails, setSelectedPlaceDetails] = useState({
    description: null,
    position: { x: 0, y: 0 },
    visible: false,
  });

  const handleShowPlaceDetails = (details) => {
    setSelectedPlaceDetails(details);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setSelectedPlaceDetails((prevState) => ({
          ...prevState,
          visible: false,
        }));
      }
    }

    if (selectedPlaceDetails.visible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedPlaceDetails.visible]);

  useEffect(() => {
    const handleResize = () => {
      const ratio = canvasRef.current.height / canvasRef.current.width;
      const width = animContRef.current.offsetWidth;
      const height = width * ratio;

      setDimensions({ width, height });

      if (window.stage) {
        window.stage.canvas.width = width;
        window.stage.canvas.height = height;
        window.stage.update();
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.handleShowPlaceDetails = handleShowPlaceDetails;

    function handleFileLoad(evt, comp) {
      const images = comp.getImages();
      if (evt && evt.item.type === "image") {
        images[evt.item.id] = evt.result;
      }
    }

    function handleComplete(evt, comp) {
      const lib = comp.getLibrary();
      const ss = comp.getSpriteSheet();
      const queue = evt.target;
      const ssMetadata = lib.ssMetadata;
      for (let i = 0; i < ssMetadata.length; i++) {
        ss[ssMetadata[i].name] = new window.createjs.SpriteSheet({
          images: [queue.getResult(ssMetadata[i].name)],
          frames: ssMetadata[i].frames,
        });
      }
      const exportRoot = new lib.mainmap();
      window.stage = new window.createjs.Stage(canvasRef.current); // set stage globally
      window.stage.enableMouseOver();

      //Registers the "tick" event listener.
      function fnStartAnimation() {
        window.stage.addChild(exportRoot);
        window.createjs.Ticker.framerate = lib.properties.fps;
        window.createjs.Ticker.addEventListener("tick", window.stage);
      }

      //Code to support hidpi screens and responsive scaling.
      window.AdobeAn.makeResponsive(true, "both", false, 1, [
        canvasRef.current,
        animContRef.current,
        overlayRef.current,
      ]);
      window.AdobeAn.compositionLoaded(lib.properties.id);
      fnStartAnimation();
    }

    function init() {
      const comp = window.AdobeAn.getComposition(
        "54DB7EFFCFE0F8489E9F1CCB7970A574"
      );
      const lib = comp.getLibrary();
      const loader = new window.createjs.LoadQueue(false);
      loader.addEventListener("fileload", (evt) => handleFileLoad(evt, comp));
      loader.addEventListener("complete", (evt) => handleComplete(evt, comp));
      loader.loadManifest(lib.properties.manifest);
    }

    init();
  }, []);

  return (
    <div ref={animContRef} id="animation_container">
      {selectedPlaceDetails.visible && (
        <div
          className="popup"
          ref={popupRef}
          style={{
            top: `${selectedPlaceDetails.position.y}px`,
          }}
        >
          <p>{selectedPlaceDetails.description}</p>
        </div>
      )}
      <canvas
        width={dimensions.width}
        height={dimensions.height}
        ref={canvasRef}
        id="canvas"
      ></canvas>
      <div ref={overlayRef} id="dom_overlay_container"></div>
    </div>
  );
};

export default Maps;
