import React, { useState, useEffect } from "react";

import Search from "../Search";
import Card from "../productCard";
import ProductTabs from "../ProductTabs";
import LatestPost from "../LatestPost";
const CloseIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/closeIcon.svg`;

function Model({
  handleClick,
  isActive,
  active,
  setActive,
  data,
  tabs,
  isNews,
}) {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(data[0]);

  useEffect(() => {
    if (search) {
      const filteredData = data[active].filter(({ title }) => {
        if (title.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
          return true;
        } else {
          return false;
        }
      });
      setFilteredData(filteredData);
    } else if (!search) {
      setFilteredData(data[active]);
    }
  }, [search, active, data]);

  return (
    <>
      <div className={`popup-model ${isActive ? "Open-model" : ""}`}>
        <img
          className="close-btn"
          onClick={handleClick}
          src={CloseIcon}
          alt="Close Icon"
        />
        <Search onSearch={setSearch} />
        <div className="heroes-tabs">
          {!isNews ? (
            <ProductTabs active={active} onClick={setActive} Data={tabs} />
          ) : (
            <ProductTabs active={active} onClick={setActive} Data={tabs} />
          )}
        </div>
        <div className="heroes-tabs-content">
          <div className="container">
            <div className="row">
              {!isNews ? (
                <>
                  {filteredData.length > 0 ? (
                    <Card data={filteredData} isClickable />
                  ) : (
                    <h1
                      className="h3head"
                      style={{ textAlign: "center", fontFamily: "Rakkas" }}
                    >
                      No results found.
                    </h1>
                  )}
                </>
              ) : (
                <>
                  {filteredData.length > 0 ? (
                    <LatestPost data={filteredData} isClickable />
                  ) : (
                    <h1
                      className="h3head"
                      style={{ textAlign: "center", fontFamily: "Rakkas" }}
                    >
                      No results found.
                    </h1>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Model;
