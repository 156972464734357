import "./style.css";
import { Link } from "react-router-dom";

const MenuItem = ({
  text,
  extraClasses = "",
  LeftIcon,
  link,
  icon,
  taskCount,
  isActive,
  disabled,
  ...rest
}) => {
  return (
    <Link
      to={link}
      className={`${extraClasses && extraClasses} d-menu-link ${
        isActive ? "active" : ""
      } ${link !== "/dashboard" && disabled ? "opacity-donw-disable" : ""}`}
      disabled={disabled}
      {...rest}
    >
      {LeftIcon && <img src={icon} alt="icon" />}
      <div>
        {text} {taskCount > 0 ? <span>{taskCount}</span> : ""}
      </div>
    </Link>
  );
};

export default MenuItem;
