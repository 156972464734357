import Explore from "../../../Components/Explore";
const sideMap = `${process.env.REACT_APP_S3_BUCKET}/Images/side-map.png`;
const bgImg = `${process.env.REACT_APP_S3_BUCKET}/Images/about-map-bg.png`;

const PlayList = {
  image: sideMap,
  altText: "Strategy Overview",
  title: "Strategy Overview",
  tagline: "Essential Guide to Tactical Positions",
  desc: "Navigate crucial battlegrounds within our mystical realm. Each click reveals insights into key areas: from the Shop where gear is forged, to the Nexus - your base's lifeline. Understand the lanes: Mid for fierce duels, Top for the tenacious, and the enemy Nexus, your ultimate conquest. Prepare for strategic dominance!",

  backgroundImg: `url(${bgImg})`,
  className: "PlayReverse about-map-info",
};

function AboutMap() {
  return (
    <section className="about-map-info">
      <Explore {...PlayList} />
      <div className="abt-info-content">
        <h2 className="h2head mb5">Master Game Basics</h2>
        <p>
          Below, we unravel the core gameplay elements to equip you with the
          knowledge for mastering the fundamentals of the game.
        </p>
      </div>
    </section>
  );
}

export default AboutMap;
