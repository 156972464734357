import React from "react";
import "./style.css";
// import Inbanner from './Inbanner'
import HeroesList from "./HeroesList";
import Innbanner from "../../../Components/InBanner";
import { HerosBannerContent } from "../../../Constants/index";

function Heroes() {
  return (
    <div id="mainwrapper" className="Heros-page bg-heros">
      <Innbanner {...HerosBannerContent} noBannerImage />
      <HeroesList />
    </div>
  );
}

export default Heroes;
