import React from "react";
import "./style.css";
import PostList from "./postListing";
import Innbanner from "../../../Components/InBanner";
import { PostBanner } from "../../../Constants/index";

function PostSection() {
  return (
    <div id="mainwrapper" className="Posts-page bg-news">
      <Innbanner {...PostBanner} />
      <PostList />
    </div>
  );
}

export default PostSection;
