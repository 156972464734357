import { createSlice } from "@reduxjs/toolkit";
import { userStatsApi } from "../../services/userStats";
import { mergeAndSumRewards } from "../../utils/event";

const initialState = {
  userStats: [],
};

const userStatsSlice = createSlice({
  name: "userStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      userStatsApi.endpoints.getUserStats.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        state.userStats = mergeAndSumRewards(data);
      }
    );
  },
});

export default userStatsSlice;
