// src/store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import authSlice from "./slices/authSlice";
import dailyTaskSlice from "./slices/dailyTaskSlice";
import highscroesSlice from "./slices/highscroesSlice";

import { userApi } from "../services/user";
import { dailyTaskApi } from "../services/dailyTask";
import { characterRewardApi } from "../services/characterReward";
import { highscroesApi } from "../services/highscroes";
import taskSlice from "./slices/taskSlice";
import { taskApi } from "../services/tasks";
import videoSlice from "./slices/videoSlice";
import pointsSlice from "./slices/pointSlice";
import eventSlice from "./slices/eventSlice";
import userStatsSlice from "./slices/userStatsSlice";
import { userStatsApi } from "../services/userStats";
// Here you would import your reducers

const rootReducer = combineReducers({
  [userSlice.name]: userSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [dailyTaskSlice.name]: dailyTaskSlice.reducer,
  [taskSlice.name]: taskSlice.reducer,
  [highscroesSlice.name]: highscroesSlice.reducer,
  [videoSlice.name]: videoSlice.reducer,
  [pointsSlice.name]: pointsSlice.reducer,
  [eventSlice.name]: eventSlice.reducer,
  [userStatsSlice.name]: userStatsSlice.reducer,
  // API reducers (use reducerPath)
  [userApi.reducerPath]: userApi.reducer,
  [dailyTaskApi.reducerPath]: dailyTaskApi.reducer,
  [taskApi.reducerPath]: taskApi.reducer,
  [characterRewardApi.reducerPath]: characterRewardApi.reducer,
  [highscroesApi.reducerPath]: highscroesApi.reducer,
  [userStatsApi.reducerPath]: userStatsApi.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getdefualtmiddleware) => {
    return getdefualtmiddleware().concat([
      userApi.middleware,
      dailyTaskApi.middleware,
      taskApi.middleware,
      characterRewardApi.middleware,
      highscroesApi.middleware,
      userStatsApi.middleware,
    ]);
  },
});

export default store;
