import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/parallax";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";
import Button from "../../../../Components/Dbutton";
import DcardBox from "../../../../Components/DcardBox";
import { formatNumberWithCommas } from "../../../../utils/helper";

const DrysiaClient = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/Drysia_client.jpg`;
const FernixClient = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/Fernix_client.jpg`;
const KaliClient = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/Kali_client.jpg`;
const NobleClient = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/noble_client.jpg`;

export const herosData = [
  {
    image: NobleClient,
    title: "Noble",
  },
  {
    image: KaliClient,
    title: "Kali",
  },
  {
    image: FernixClient,
    title: "Fernix",
  },
  {
    image: DrysiaClient,
    title: "Drysia",
  },
];

const StepsTwo = ({ data, onConfirm }) => {
  const [heroName, setHeroName] = useState("");

  const handleSlideChange = (swiper) => {
    const currentIndex = swiper.realIndex;
    setHeroName(herosData[currentIndex].title);
  };

  return (
    <div className="d-model-text">
      <h3>choose your hero</h3>
      <h4>
        {data?.reward
          ? formatNumberWithCommas(Number(data?.reward))
          : data?.count}
      </h4>
      <p>
        By selecting <span>{heroName}</span> upgraded skin. You will enter the lottery for a chance to win this as an NFT after the airdrop.
      </p>
      <div className="heros-image-slider">
        <Swiper
          effect="creative"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={3}
          spaceBetween={0}
          parallax={true}
          pagination={{ clickable: true }}
          loop={true}
          modules={[Pagination]}
          onSlideChange={handleSlideChange} // Add the slide change handler here
        >
          {herosData.map((item, index) => (
            <SwiperSlide key={index}>
              <DcardBox
                variant="secondary-contained"
                extraClasses="d-image-slider-card"
                key={index}
              >
                <img src={item.image} alt="Hero Img" />
              </DcardBox>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Button
        variant="primary-contained"
        text="confirm"
        extraClasses="w-full"
        onClick={() => onConfirm(data)}
      />
    </div>
  );
};

export default StepsTwo;
