import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Dsidebar from "./dSidebar";
import Dheader from "./dHeader";
import "./style.css";

const HeroVideo = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dashborad-video.mp4`;

const DashboardLayout = ({ children }) => {
  const { isVideoStop } = useSelector((state) => state.video);
  const [isSiderbarActive, setIsSiderbarActive] = useState(false);
  const [isSiderOpen, setIsSiderOpen] = useState(false);

  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (isVideoStop) {
        videoElement.pause();
      } else {
        videoElement.play().catch((error) => {
          console.error("Error trying to play the video:", error);
        });
      }
    }
  }, [isVideoStop]);

  return (
    <div
    className={`dashboard-layout ${
      isSiderbarActive ? "active-siderbar" : ""
    }`}
  >
      <video
        ref={videoRef}
        loop
        muted
        controls={false}
        playsInline
        className="dash-bg-video"
        autoPlay
      >
        <source src={HeroVideo} type="video/mp4" />
      </video>

      <div
        className={`dawer-sidebar ${isSiderOpen ? "d-active-siderbard" : ""}`}
      >
        <Dsidebar
          onOpen={() => setIsSiderbarActive(!isSiderbarActive)}
          isClose={isSiderbarActive}
          onCloseSiderbar={() => setIsSiderOpen(!isSiderOpen)}
        />
      </div>
      <div className={`d-dash-content`}>
        <Dheader
          onOpenSiderbar={() => setIsSiderOpen(!isSiderOpen)}
          active={isSiderOpen}
        />
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
