import React from "react";
import "./style.css";

const DSwitch = ({ active, animating, onClick }) => {
  return (
    <div className="switch">
      <button
        type="button"
        className={`switch-btn ${active ? "active" : ""} ${
          animating ? "animating" : ""
        }`}
        onClick={onClick}
      >
        <span>
          <b></b>
          <svg viewBox="-5.5 -5.5 71 71" id="circle">
            <circle
              cx="30"
              cy="30"
              r="30"
              stroke="white"
              strokeWidth="11"
              fill="transparent"
            ></circle>
          </svg>
        </span>
      </button>
    </div>
  );
};

export default DSwitch;
