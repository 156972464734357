import React from "react";
import Explore from "../../../Components/Explore";
// const Video = `${process.env.REACT_APP_S3_BUCKET}/videos/Ball_Lightning_new.mp4`;
const mapVideo = `${process.env.REACT_APP_S3_BUCKET}/videos/map-mastery.mp4`;
const RedPoster = `${process.env.REACT_APP_S3_BUCKET}/Images/Blinke-Strike-poster.png`;
// const Poster = `${process.env.REACT_APP_S3_BUCKET}/Images/ball-lightning-poster-new.png`;
// const BlueTeamImg = `${process.env.REACT_APP_S3_BUCKET}/Images/blue-team.jpg`;
const RedTeamImg = `${process.env.REACT_APP_S3_BUCKET}/Images/red-team.jpg`;

// const BlueTeam = {
//   video: Video,
//   poster: Poster,
//   title: "Your Hero Awaits",
//   tagline: "Team Up for 5v5 Action",
//   desc: "Dive into the exhilarating world of Guild of Heroes, where every match is an opportunity to strategize and outplay the opposition in 5v5 battles. Experience an accessible MOBA on the Avalanche blockchain, where every hero is a unique NFT. With upgraded skins and full ownership control, your path to glory is in your hands. Engage in fast-paced combat and make every move count in Guild of Heroes.",
//   backgroundImg: `url(${BlueTeamImg})`,
//   autoPlay: true,
//   className: "rewamp-space",
// };

const RedTeam = {
  video: mapVideo,
  poster: RedPoster,
  title: "Game Objective",
  tagline: "Seize Control",
  desc: "In the strategic world of Guild of Heroes, the ultimate goal is to lead your team to victory by outsmarting the opposition. Command a lineup of diverse heroes, each with unique abilities, to engage in tactical battles. The key to success lies in demolishing the enemy's defenses and ultimately their nexus, while defending your own. Master resource control, dominate the battlefield, and become the champion of Arcanterra.",
  backgroundImg: `url(${RedTeamImg})`,
  autoPlay: true,
  className: "rewamp-space PlayReverse",
};

function PlayWin() {
  return (
    <>
      {/* <Explore {...BlueTeam} /> */}
      <Explore {...RedTeam} />
    </>
  );
}

export default PlayWin;
