import { baseUrl, prepareHeaders } from "./_utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userStatsApi = createApi({
  reducerPath: "userStatsApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getUserStats: builder.mutation({
      query: (id) => ({
        url: `/user-stats/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetUserStatsMutation } = userStatsApi;
