import { WeeklyTaskList } from "../Constants/list";

export const addPlayerAirdropStep = async (step) => {
  // Initialize an empty array to store completed steps
  const stepsCompleted = [];

  try {
    // Fetch user data to get the steps already completed
    const userDataResponse = await window.PlayFab.ClientApi.GetUserData({});

    // Check if step data exists:
    if (userDataResponse.data.Data.airdrop_stepsCompleted) {
      stepsCompleted.push(
        ...JSON.parse(userDataResponse.data.Data.airdrop_stepsCompleted.Value)
      );
    }

    // Add the new completed step to the array, if the data already existed so the new step would be appended to it. If the steps data is not there so the empty arra will be pushed with the new step (happens usually on the first step). Also check if the step already included in the array. Especially in the case of connect wallet since we can toggle wallet connection anytime and from anywhere.
    if (!stepsCompleted.includes(step)) {
      stepsCompleted.push(step);
    }

    // Update player statistics. Score dynamically calculated based on the number of steps completed
    const updatePlayerStatisticsRequest = {
      Statistics: [
        {
          StatisticName: "Airdrop High Scores",
          Value: stepsCompleted.length * 20,
        },
      ],
    };

    const updateStatsResponse =
      await window.PlayFab.ClientApi.UpdatePlayerStatistics(
        updatePlayerStatisticsRequest
      );

    if (updateStatsResponse) {
      // Update user data of steps including the new step which was completed
      const updateUserDataRequest = {
        Data: {
          airdrop_stepsCompleted: JSON.stringify(stepsCompleted),
        },
      };

      const updateUserDataResponse =
        await window.PlayFab.ClientApi.UpdateUserData(updateUserDataRequest);

      if (updateUserDataResponse) {
      } else {
        console.log(
          "steps added to user data FAILED",
          updateUserDataResponse.error
        );
      }
    } else {
      console.log(
        "Error setting player statistics:",
        updateStatsResponse.error
      );
    }
  } catch (error) {}
};

export const removePlayerAirdropStep = async (step) => {
  try {
    // Fetch user data to get the steps already completed
    const userDataResponse = await window.PlayFab.ClientApi.GetUserData({});

    // Check if step data exists:
    if (userDataResponse.data.Data.airdrop_stepsCompleted) {
      const stepsCompleted = JSON.parse(
        userDataResponse.data.Data.airdrop_stepsCompleted.Value
      );

      // Remove the step from the stepsCompleted array. e.g. Wallet Connected
      const updatedSteps = stepsCompleted.filter(
        (completedStep) => completedStep !== step
      );

      // Calculate updated score after removing the step (decrease by 20)
      const updatedScore = updatedSteps.length * 20;

      // Update player statistics by the new score
      const updatePlayerStatisticsRequest = {
        Statistics: [
          {
            StatisticName: "Airdrop High Scores",
            Value: updatedScore,
          },
        ],
      };

      const updateStatsResponse =
        await window.PlayFab.ClientApi.UpdatePlayerStatistics(
          updatePlayerStatisticsRequest
        );

      if (updateStatsResponse) {
        // Update user data with revised steps
        const updateUserDataRequest = {
          Data: {
            airdrop_stepsCompleted: JSON.stringify(updatedSteps),
          },
        };

        const updateUserDataResponse =
          await window.PlayFab.ClientApi.UpdateUserData(updateUserDataRequest);

        if (updateUserDataResponse) {
          console.log("steps updated in user data");
        } else {
          console.log(
            "steps update in user data FAILED",
            updateUserDataResponse.error
          );
        }
      } else {
        console.log(
          "Error setting player statistics:",
          updateStatsResponse.error
        );
      }
    } else {
      console.log("No airdrop_stepsCompleted data found in user data");
    }
  } catch (error) {
    console.error("Error handling wallet disconnect:", error);
  }
};

export function debounce(func, delay) {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

export const getErrorMessage = (error) => {
  // If the error is a string, return it directly
  if (typeof error === "string") {
    return error;
  }

  // If the error is an object, return the first key's value
  if (typeof error === "object" && error !== null) {
    const firstKey = Object.keys(error)[0];
    return error[firstKey] || "An error occurred";
  }

  // Default fallback
  return "An unknown error occurred";
};

export function mergeData(apiData) {
  return apiData.map((apiItem) => {
    // Find the corresponding mock item by matching 'type'
    const mockItem = WeeklyTaskList.find((task) => task.type === apiItem.type);

    // If mock item found, merge properties; otherwise, return API item as is
    if (mockItem) {
      return { ...apiItem, ...mockItem };
    } else {
      return apiItem;
    }
  });
}

export function formatNumberWithCommas(num) {
  if (isNaN(num)) {
    throw new Error("Invalid number");
  }

  // Convert the number to an integer (remove any decimal part)
  const integerPart = Math.floor(num).toString();

  // Add commas to the integer part
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return integerWithCommas;
}

export function formatNumberWithCommasForHero(num) {
  if (isNaN(num)) {
    throw new Error("Invalid number");
  }

  const fixedNum = Number(num).toFixed(2);
  const [integerPart, decimalPart] = fixedNum.split(".");
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${integerWithCommas}.${decimalPart}`;
}

export function isClaimable(claimAt) {
  const claimTime = new Date(claimAt);
  const currentTime = new Date();
  return currentTime.getTime() >= claimTime.getTime();
}

export function currencyFormat(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  } else {
    return num.toString();
  }
}
