import React from "react";

const Banner = () => {
  return (
    <section className="about-page">
      <div className="container">
        <h2 className="h2head">ABOUT THE GAME</h2>
        <p className="paraStyle">
          Dive into a world of strategic combat and heroic clashes. Guild of
          Heroes offers a battleground for those who dare to prove their mettle
          in the arena.
        </p>
      </div>
    </section>
  );
};

export default Banner;
