import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import SideMenu from "../../../Components/SideMenu";

import Setting from "../../../Components/Setting";
import { settingsMenu } from "../../../Constants/settingMenu";
import Loader from "../../../Components/Loader";
import { useSelector } from "react-redux";
import { useGetUserByIdMutation } from "../../../services/user";
import { toast } from "react-toastify";

const Settings = () => {
  const [activeMenu, setActiveMenu] = useState(settingsMenu[0].id);
  const [progress, setProgress] = useState(0);
  const [playerIDProgress, setPlayerIDProgress] = useState(false);
  const [profileProgress, setProfileProgress] = useState(false);
  const [walletProgress, setWalletProgress] = useState(false);
  const [twofaProgress, setTwofaProgress] = useState(false);
  const [preferencesProgress, setPreferencesProgress] = useState(false);
  const [accountProgress, setAccountProgress] = useState(false);
  // const [userData, setUserData] = useState(null);
  const { user } = useSelector((state) => state.auth);

  const [getUserById, { isLoading }] = useGetUserByIdMutation();

  const sections = useRef([]);

  const handleScroll = () => {
    const pageYOffset = window.scrollY || window.pageYOffset;
    let newActiveSection = null;

    sections.current.forEach((section) => {
      const sectionOffsetTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (
        pageYOffset + 200 > sectionOffsetTop &&
        pageYOffset + 200 < sectionOffsetTop + sectionHeight
      ) {
        newActiveSection = section.id;
      }
    });
    setActiveMenu(newActiveSection);
  };

  useEffect(() => {
    sections.current = document.querySelectorAll(".tbbox");
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [user, isLoading]);

  useEffect(() => {
    const totalCompleted = [
      playerIDProgress,
      profileProgress,
      walletProgress,
      twofaProgress,
      preferencesProgress,
      accountProgress,
    ].reduce((acc, current) => acc + (current ? 1 : 0), 0);

    const totalProgressPercentage = (totalCompleted / 6) * 100;

    setProgress(Math.floor(totalProgressPercentage));
  }, [
    playerIDProgress,
    profileProgress,
    walletProgress,
    twofaProgress,
    preferencesProgress,
    accountProgress,
  ]);

  const fetchUserData = async () => {
    try {
      await getUserById.unwrap();
    } catch (err) {
      toast.error(err?.message);
    }
  };

  return (
    <div className="settings settingsmain">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="container">
            <div className="col2">
              <h3 className="sideBar-head">
                ACCOUNT <br /> MANAGEMENT
              </h3>
              {/* progress bar here */}
              <div className="profile-progress">
                <div className="progress-details">
                  <h3 className="progress-heading">Profile Completeness</h3>
                  <h3 className="progress-percentage">{progress}%</h3>
                </div>
                <div className="progress-container">
                  <div
                    className="progress"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <span>Complete your profile below</span>
              </div>
              {/* side menu here  */}
              <SideMenu
                playerIDProgress={playerIDProgress}
                profileProgress={profileProgress}
                walletProgress={walletProgress}
                twofaProgress={twofaProgress}
                preferencesProgress={preferencesProgress}
                accountProgress={accountProgress}
                menuList={settingsMenu}
                activeMenu={activeMenu}
              />
            </div>
            <div className="col4">
              {/* setting Components here  */}
              <Setting
                userData={user}
                fetchUserData={fetchUserData}
                playerIDProgress={playerIDProgress}
                setPlayerIDProgress={setPlayerIDProgress}
                setProfileProgress={setProfileProgress}
                setWalletProgress={setWalletProgress}
                setTwofaProgress={setTwofaProgress}
                setPreferencesProgress={setPreferencesProgress}
                setAccountProgress={setAccountProgress}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
