import { useEffect, useState } from "react";
import "./style.css";
import { useSelector } from "react-redux";

const ScoreTable = ({ data, currentPage, setCurrentPage }) => {
  const [playerStats, setPlayerStats] = useState(null);
  const { user } = useSelector((state) => state.auth);

  const getPlayerStats = async () => {
    const stats = await window.PlayFab.ClientApi.GetLeaderboardAroundPlayer({
      StatisticName: "Airdrop High Scores",
      MaxResultsCount: 1,
      ProfileConstraints: {
        ShowDisplayName: true,
      },
    });

    setPlayerStats(stats.data.Leaderboard[0]);
  };

  useEffect(() => {
    getPlayerStats();
  }, []);

  // Logged-in user's data
  const loggedInUserData =
    user.data &&
    data.find((row) => row.PlayFabId === user?.playFabId);

  return (
    <div className="table-container">
      <div className="btn-container">
        <button
          className={`btn-prev ${currentPage === 1 && "disabled"}`}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        ></button>
        <button
          className={`btn-next ${data.length === 0 && "disabled"}`}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={data.length === 0}
        ></button>
      </div>
      <div className="table-main">
        <table className="table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Username</th>
              {/* <th>Wallet Connected</th>
            <th>Twitter Follow</th> */}
              <th className="text-center">GOH COINS EARNED</th>
            </tr>
          </thead>
          <tbody>
            {/* display the logged in user at the top of the table  */}
            <tr className="highlight">
              <td>{playerStats?.Position + 1}</td>
              <td>
                {playerStats?.DisplayName
                  ? playerStats?.DisplayName
                  : playerStats?.PlayFabId}
                <span> YOU</span>
              </td>
              <td className="text-center">{playerStats?.StatValue}</td>
            </tr>

            {/* display all the other users  */}
            {data.length > 0 ? (
              data
                .filter((row) => row !== loggedInUserData)
                .map((row, index) => (
                  <tr key={index}>
                    <td>{row?.Position + 1}</td>
                    <td>
                      {row?.DisplayName ? row?.DisplayName : row?.PlayFabId}
                      {/* {row?.DisplayName} */}
                    </td>
                    {/* {row.stepsCompleted
                  .filter(
                    (step) =>
                      step === "Wallet Connected" ||
                      step === "Twitter Connected"
                  )
                  .map((steps, index) => (
                    <td key={index}>{steps && "Yes"}</td>
                  ))} */}

                    {/* <td>{row.walletConnected}</td>
              <td>{row.betaPlaytime}</td> */}
                    <td className="text-center">{row?.StatValue}</td>
                  </tr>
                ))
            ) : (
              <tr className="norecord">
                <td>{"No data found."}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScoreTable;
