import { baseUrl, prepareHeaders } from "./_utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getUserById: builder.mutation({
      query: (id) => ({
        url: `/users/get-user-by-playFabId/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useGetUserByIdMutation } = userApi;
